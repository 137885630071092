import React, { useEffect, useState } from "react";
import { Box, Avatar, AvatarBadge, Flex, Badge, Button } from "@chakra-ui/react";
import { SearchInput } from "../../../SearchInput";
import style from "../style.module.css";
import { bell, shoppingCart, heart, compare } from "../../../../assets/images";
import { Link } from "react-router-dom";
import store, { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getTotalCartLength,
  updateCartTotal,
} from "../../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";
import { getWishlistAsync } from "../../../../redux/reducerSlices/Wishlist/wishlistSlice";
import Notification from "../../../Notification";
import { useTranslation } from "react-i18next";

const SearchHeader = () => {
  const dispatch = useAppDispatch();
  const compareListItems = useAppSelector(
    (state) => state?.compareList?.productIds
  );


  const [token, setToken] = useState(store.getState().auth.token);
  const authData = useAppSelector((state) => state?.auth?.authData);
  const user = useAppSelector((state) => state?.auth?.authInfo);

  const cartLength = useAppSelector((state) => state?.TotalCartLength);
  const lengthOfCompareList = useAppSelector(
    (state) => state?.compareList?.productIds?.length
  );
  const lengthOfWishlist = useAppSelector((state) => state?.wishlist?.length);



  useEffect(() => {
    dispatch(getTotalCartLength());
    dispatch(getWishlistAsync());
  }, [dispatch, token]);

  return (
    <Flex
      className={style.searchHeader}
      gap={7}
      wrap={"wrap"}
      backgroundColor={"#F5F5F5"}
    >
      <Box w={{base:"90%" , md:'50%'}}>
        <SearchInput />
      </Box>

      {authData &&authData?._id!=="guest"  && user?.isPhoneVerified && user?.isEmailVerified && (
        <Flex gap={5} align={"center"}>
          <Notification />

          <Box borderWidth={1} borderColor={"#000"} height={"45%"}></Box>

          <Link to={"/cart"}>
            <Avatar size="xs" src={shoppingCart}>
              <AvatarBadge
                top={-5}
                padding={2}
                boxSize="1.5em"
                alignItems={"center"}
                bg="#625051"
              >
                {cartLength}
              </AvatarBadge>
            </Avatar>
          </Link>

          <Box borderWidth={1} borderColor={"#000"} height={"45%"}></Box>
          <Link to={"/wishlist"}>
            <Avatar size="xs" src={heart}>
              <AvatarBadge
                top={-5}
                padding={2}
                boxSize="1.5em"
                alignItems={"center"}
                bg="#625051"
              >
                {lengthOfWishlist}
              </AvatarBadge>
            </Avatar>
          </Link>
          <Box borderWidth={1} borderColor={"#000"} height={"45%"}></Box>

          <Link to={`/compareList/${compareListItems[0]}/${compareListItems[1]}`}
          
          >
            <Avatar size="xs" src={compare}>
              <AvatarBadge
                top={-5}
                padding={2}
                boxSize="1.5em"
                alignItems={"center"}
                bg="#625051"
              >
                {lengthOfCompareList}
              </AvatarBadge>
            </Avatar>
          </Link>
        </Flex>
      )}

   
    </Flex>
  );
};

export default SearchHeader;
