enum ApiRoutes {
  listShops='shops/list',
  listServices='services/list',
  listProducts='products/list',
  listVariations='variations/list',
  listCategories='categories/list',
  listReviews='reviews/list',
  listShopsBanners='shops/banners',
  listSonnaBanners='banners/list',
  listFeaturedShops='shops/featured',

  listTags="tags/list",
  getShopById="shops/get",
  getProductById="products/get",
  getVariationById="variations/get",
  getServiceById="services/get",
  getOrderById="orders/get",
  addToCart='carts/item',
  addToBasket='baskets/item',

  requestService="requests/create",
  getCart="carts/get",
  getBasket="baskets/get",
  removeFromCart="/carts/item",
  removeFromBasket="/baskets/item",
  updateReview="reviews/update",
  flushCart="carts/flush",
  flushBasket="baskets/flush",
  deleteReview="reviews/remove",
  cartOrder="orders/cart",
  basketOrder="orders/basket",
  getWishlist="wishlists/get",
  addToWishlist="wishlists/item",
  removeFromWishlist="wishlists/item",
  listServicesRequested="requests/list",
  listOrders="orders/list",
  uploadImage="image",
  register='register',
  login= 'login',
  addReview="reviews/create",
  updateProfile="update",
  updateAddress="/update",
  applyCouponOnBasket='coupons/applyOnBasket',
  applyCoupon="coupons/applyOnCart",
  cancelCoupon="coupons/cancelFromCart",
  cancelFromBasket="coupons/cancelFromBasket",
  // calculateTotal="carts/shipping"
  calculateTotal="orders/total",
  calculateRequstTotal="requests/shipping",
  servicePurchase='requests/purchase'
  
 }
export default ApiRoutes;
