import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import Login from "./Login/Login";

import Register from "./Register/Register";
import { useTranslation } from "react-i18next";
 

const AuthTabs: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const {t} = useTranslation()
  const switchToSignUp = () => {
    setTabIndex(1);
  };
  const switchToLogin = () => {
    setTabIndex(0)
  }
  return (
    <Flex   justify={"center"} align="center" my={{base:5, md:45}} w="100%">
      <Box
        w={{base:'95%' , md:'60%'}}
        border={"2px solid #ddd"}
        borderRadius={"30px"}
        px={8}
        pt={8}
        pb={24}
        bgColor="white"
      >
        <Tabs
          variant="soft-rounded"
         colorScheme="#000"
          align="center"
          w={"100%"}
          index={tabIndex}
          onChange={index => setTabIndex(index)}
          
        >
          <TabList mb="1em">
            <Tab
              //  w="10%"
              _selected={{
                color: "#881619",
                borderBottom: "2.7px solid",
                borderImage: "linear-gradient(90deg,#fff, #881619, #fff) 2",
              }}
            >
              {t('Sign In')}
            </Tab>
            <Tab
                // w="10%"
                _selected={{
                  color: "#881619",
                  borderBottom: "2.7px solid",
                  borderImage: "linear-gradient(90deg,#fff, #881619, #fff) 2",
                }}
            >
             {t('Sign Up')}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Login switchToSignUp={switchToSignUp}/>
            </TabPanel>
            <TabPanel>
              <Register switchToLogin={switchToLogin} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default AuthTabs;
