import { Box, Flex, Img, Text } from "@chakra-ui/react";
import React from "react";
import { backImg, emptyBag } from "../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NoDataFound({data}:{data?:string}) {
  const { t } = useTranslation();

  return (
    <Flex display={{base:'block' , md:'flex'}}  justify="center">
    <Text  mt={2} color={"primary"} fontSize={25}>  {data&&t(data)}</Text>
      <Flex  flex={1} direction="column"    justify="center" my="5rem" >
        
        <Box margin={'auto'} justifyContent={'center'}  height={'100%'} width={'100%'} >
          <Img  margin={'auto'} src={emptyBag} alt="empty bag" />
        </Box>

        <Flex align="center" gap="3rem" justify="center" mt="3rem">
          <Text fontWeight="bold" color="primary">
            {t(`No ${data||"Data"} Found !`)}{" "}
          </Text>
          {/* <Link to={link||"#"}>
            <Img src={backImg} alt="back" w="70px" />
          </Link> */}
        </Flex>
      </Flex>
    </Flex>
  );
}
