import { Flex, Button, Text, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { IoIosArrowDown } from "react-icons/io";

type Props = {
  count?: any;
  data: any;
  loadMoreData?: () => void;
  loading: any;
  title?: string;
  small?: any;
};
export default function ShowMore({
  data,
  count,
  loadMoreData,
  loading,
  title,
  small,
}: Props) {
  const totalCount=count+1
 
  return (
    <>
      {small ? (
        <Flex flex={1} justifyContent={"center"}>
          {data && data.length !== count && !loading && (
            <Text
              color={"black"}
              fontWeight={"500"}
              cursor={"pointer"}
              w={"100%"}
              px={10}
              display={"flex"}
              placeItems={"center"}
              _hover={{
                color: "gray",
              }}
              onClick={loadMoreData}
            >
              {t(title || "Load More")} <IoIosArrowDown />{" "}
            </Text>
          )}
          {loading && <Spinner />}
        </Flex>
      ) : (
        <Flex my={"20px"} flex={1} justifyContent={"center"}>
          { data&&data?.result?.length !== totalCount && !loading && (
            <Button
              variant={"outline"}
              color={"black"}
              fontWeight={"500"}
              mx={3}
              px={10}
              _hover={{
                color: "gray",
                bgColor: "#EAC07F",
              }}
              isLoading={loading}
              onClick={loadMoreData}
            >
              {t(title || "Load More")}
            </Button>
          )}
          {loading && <Spinner />}

        </Flex>
      )}
    </>
  );
}
