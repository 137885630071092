import { Box } from "@chakra-ui/react";
import Menu from "./components/Menu";
import { Text, Tooltip } from "@chakra-ui/react";
import { PiChatText } from "react-icons/pi";
import style from "./style.module.css";

import { useDispatch, useSelector } from "react-redux";
 
import { RootState } from "../../redux/store";

import RipplesButton from "../RipplesButton";
import { setChatMenuData } from "../../redux/reducerSlices/ChatMenu/chatMenu";
import { useTranslation } from "react-i18next";
export default function ChatSpace() {
  const {t} = useTranslation()
  const { active } = useSelector<RootState, any | undefined>(
    (state) => state?.chatMenu.chatMenuData
  );
  const { _id } = useSelector<RootState, any | undefined>(
    (state) => state?.auth?.authData
  );
  const dispatch = useDispatch();
 

  return (
    <Box className={style.chat_space}>
      <Tooltip label={t("Chat With US")} placement="left-start">
        <Text>
          <RipplesButton
            text={<PiChatText />}
            onClick={() => {
              dispatch(setChatMenuData({ chatMenuData: { active: !active } }));
            }}
            className={`${style.Floating_Btn} ${
              active ? style.active_btn : style.disactive_btn
            }`}
          />
        </Text>
      </Tooltip>
      <Menu active={active} />
      {active && (
        <Box
      
          onClick={() => {
            dispatch(
              setChatMenuData({
                chatMenuData: { active: false, fastBox: false },
              })
            );
          }}
          className={style.overlay}
        ></Box>
      )}
    </Box>
  );
}
