import React from "react";
import ApiRoutes from "../../api/services/api-routes";
import useGetData from "../../api/useGetData";
import { useAppSelector } from "../../redux/store";
import OrderCard from "../../components/OrderCard";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../components/NoDataFound";
import { RootState } from "../../redux/store";
import ServiceInCart from "../../components/ServiceInCart";
import ShowMore from "../../common/ShowMore";
import EmptyData from "../../components/EmptyData";

const Orders = () => {
  const userData = useAppSelector((state:RootState) => state?.auth?.authData);
  const { t } = useTranslation();

  const { loading, data, getData, loadMoreData, loadingMoreData } = useGetData({
    route: 'requests/listProfile',
    params: { 
      customer: userData?._id,
      "sortByDate": 'issueDate', 
      "sortOrder": -1,
      status:"purchased"
     },
  });

  const services = data?.result;

  if (loading) {
    return (
      <Text  width={'100%'} color={"primary"} fontSize={20}  textAlign={"center"}>
       {t('Loading...')} 
      </Text>
    );
  }
  
  return (
    <Flex  alignItems={'center'} width={{base:'100%' , md:'80%'}} direction={"column"}  >
      {services?.length > 0 ? (
        <Flex direction={"column"}  align={"center"}  width={'95%'} >
          <Flex w={"100%"} direction={"column"}>
            {services?.map((service: any) => {
              // if(!service?.status) {
                return <ServiceInCart  key={service?._id} service={service} />;  
              // }
            })}
          </Flex>

          <ShowMore
            style={{ width: "100%" }}
            data={services}
            count={data?.count}
            loadMoreData={loadMoreData}
            loading={loadingMoreData}
          />
        </Flex>
      ) : (
        <EmptyData  link={'/all-services'}/>
      )}
    
    </Flex>
  );
};

export default Orders;
