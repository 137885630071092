import { extendTheme } from "@chakra-ui/react";
import { defineStyleConfig } from "@chakra-ui/react";

const primaryColor = "#88050D";
const secondaryColor = "#EFEBE3";
const  mainGreen="#127346"
const components = {
  Heading: defineStyleConfig({
    variants: {
      section: () => ({
        fontWeight: "bold",
        fontSize: ["2xl", "2xl", "2xl", "3xl", "3xl", "4xl"],
      }),
      tertiary: () => ({
        fontSize: "xl",
        fontWeight: "semibold",
      }),
    },
  }),
  Container: defineStyleConfig({
    baseStyle: {
      maxWidth: {
        base: "90%",
        // sm: "540px",
        // md: "740px",
        // lg: "960px",
        // xl: "1140px",
        // xxl: "1320px",
      },
      padding: "1% 0%",
    },
  }),
  Image: defineStyleConfig({
    baseStyle: {
      // fallbackSrc: '',
    },
  }),
  Button: defineStyleConfig({
    baseStyle: {
      color: "#FFFFFF",
      fontWeight: "normal",
      bgColor: "#127346",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      _hover: {
        // bgColor: "#a4020c",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    variants: {
      outline: {
        borderWidth: "1.2px",
        borderColor: "#a4020c",
        color: "#000000",
        _hover: {
          color: "white",
          bgColor: "primary",
        },
      },
      white: {
        borderWidth: "1.2px",
        borderColor: "transparent",
        bgColor: "white",
        color: "primary",
        _hover: {
          color: "white",
          bgColor: "primary",
        },
      },
      secondary: {
        color: "#FFFFFF",
        fontWeight: "normal",
        bgColor: "#a4020c",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        _hover: {
          // bgColor: "#a4020c",
        },
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    defaultProps: {
      colorScheme: "primary",
    },
  }),
  Input: defineStyleConfig({
    baseStyle: {
      field: {
        // background: "white",
        fontFamily: "body",
        fontWeight: "normal",
        color: "dark",
        padding: "4",
        // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
        border: "1px solid rgba(0, 0, 0, 0.15) ",
        borderRadius: "lg",
        fontSize: "16",
        _placeholder: {
          color: "gray",
          fontSize: "15",
        },
        _disabled: {
          opacity: ".95",
        },
      },
    },
    defaultProps: {
      // variant:null
    },
  }),
  Select: defineStyleConfig({
    defaultProps: {
      // variant:null
    },
  }),
};

const breakpoints = {
  xs: "320px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const fonts = {
  // body: "Poppins, sans-serif",
  // heading: "Poppins, sans-serif",
  // mono: "Menlo, monospace",
};

const semanticTokens = {
  colors: {
    primary: primaryColor,
    primarydark: "#127346",
    secondary: secondaryColor,
    green:mainGreen,
    // secondarydark: secondaryDarkColor,
    dark: "#565656",
    gray: "#949494",
    darkModeBg: "#2d3748",
    headerBg: "rgba(225, 225, 225, 0.51)",
  },
};

const ChakraTheme = extendTheme({
  // styles,
  fonts,
  semanticTokens,
  breakpoints,
  components,
});

export default ChakraTheme;
