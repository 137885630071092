import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useGetData from "../api/useGetData";
import ApiRoutes from "../api/services/api-routes";
import { Box, Img, ResponsiveValue } from "@chakra-ui/react";
import { items } from "../assets/dummyData";
import AppSkeleton from "../common/AppSkeleton";
import { defaultVariationOfProducts } from "../utils/utilsFunctions";
import SectionTitle from "./SectionTitle";
import NoDataFound from "./NoDataFound";
import { useTranslation } from "react-i18next";

interface AppCarouselProps {
  params?: {};
  route: string;
  renderItem: (item: any) => JSX.Element;
  sectionTitle?: string;
  sectionRoute?: string;
  outerResponsive?: {};
  width?:   ResponsiveValue<number | (string & {})>//string;
  dataType: string;
  sectionSearch?: string | undefined;
  skipId?:string;
  listEmptyComponent?:JSX.Element;
  onSuccess?:(data:any)=>void
}
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
    gap: 20,
  },
  moreThan2000: {
    breakpoint: { max: 3000, min: 2000 },
    items: 6,
    gap: 20,
  },
  moreThan1680: {
    breakpoint: { max: 2000, min: 1680 },
    items: 4,
    gap: 20,
  },
  moreThan1500: {
    breakpoint: { max: 1680, min: 1500 },
    items: 4,
    gap: 20,
  },
  moreThan1400: {
    breakpoint: { max: 1500, min: 1400 },
    items: 4,
    gap: 20,
  },
  moreThan1300: {
    breakpoint: { max: 1400, min: 1370 },
    items: 4,
    gap: 20,
  },
  moreThan1200: {
    breakpoint: { max: 1370, min: 1200 },
    items: 4,
    gap: 20,
  },
  moreThan1100: {
    breakpoint: { max: 1200, min: 1100 },
    items: 3,
    gap: 20,
  },
  moreThan1000: {
    breakpoint: { max: 1100, min: 1000 },
    items: 2,
    gap: 20,
  },
  moreThan900: {
    breakpoint: { max: 1000, min: 900 },
    items: 2,
    gap: 20,
  },
  moreThan800: {
    breakpoint: { max: 900, min: 800 },
    items: 2,
    gap: 20,
  },
  moreThan700: {
    breakpoint: { max: 800, min: 700 },
    items: 1,
    gap: 20,
  },
  moreThan600: {
    breakpoint: { max: 700, min: 600 },
    items: 1,
    gap: 20,
  },
  tablet: {
    breakpoint: { max: 600, min: 464 },
    items: 1,
    gap: 20,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    gap: 10,
  },
};

const AppCarousel: React.FC<AppCarouselProps> = ({
  params,
  route,
  renderItem,
  sectionTitle,
  sectionRoute,
  outerResponsive,
  width,
  dataType,
  sectionSearch,
  skipId,
  listEmptyComponent,
  onSuccess
}) => {
  const { data, loading } = useGetData({
    route,
    params,
    onSuccess:(res , setData)=>{
      if(skipId){
        const updatedData= res?.data?.result?.filter((item:any)=>item?._id !== skipId  )
        console.log({res , updatedData} , 'App Curroslal')
        setData({...res?.data , result :updatedData})
        onSuccess?.(updatedData)
      }
    }
  });

  const cardsData = data?.result;
  const { i18n } = useTranslation()


  
  if (loading) {
    return (
      <>
        <Box zIndex={0} width={width || "100%"}>
          <Carousel rtl={i18n.language === 'en' ? false : true} responsive={outerResponsive || responsive}>
            {items?.map(() => {
              return <AppSkeleton height={"40vh"} width={width || "100%"} />;
            })}
          </Carousel>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box zIndex={0} width={width || "100%"}>
        {cardsData?.length > 0 ? (
          <>
            {(sectionTitle || sectionRoute || sectionSearch) && (
              <SectionTitle
                title={sectionTitle}
                sectionSearch={sectionSearch}
                route={sectionRoute}
              />
            )}

            <Carousel rtl={i18n.language === 'en' ? false : true} responsive={outerResponsive || responsive}>
              {cardsData?.map((item: any) => {
                return renderItem(item);
              })}
            </Carousel>
          </>
        ) : (
         listEmptyComponent || 
          <NoDataFound data={dataType} />
        )}
      </Box>
    </>
  );
};

export default AppCarousel;
