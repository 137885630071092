import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  DeleteService,
  GetService,
  PostService,
} from "../../../api/services/requests-service";
import ApiRoutes from "../../../api/services/api-routes";
 
export const getCartQuantity = createAsyncThunk(
  "cartLength/getCartLengthAsync",
  async (_, { getState }) => {
    const state = getState() as RootState; // Access the RootState
    const customerId = state?.auth?.authData?._id;
    const response = await GetService({
      route: ApiRoutes?.getCart,
      params: { customer: customerId },
    });


    const totalQuantities = response.data?.result?.subCarts?.map(
      (subCart: any) =>
        subCart.items.reduce(
          (total: any, item: any) => total + item?.quantity,
          0
        )
    );
     // Calculate the total sum of quantities in all subCarts
     const totalSum = totalQuantities.reduce((total:any, sum:any) => total + sum, 0);


   

    return totalSum; // Adjust this based on your API response structure
  }
);

export const getBasketQuantity = createAsyncThunk(
  "basketLength/getBasketLengthAsync",
  async (_, { getState }) => {
    const state = getState() as RootState; // Access the RootState
    const customerId = state?.auth?.authData?._id;
    const response = await GetService({
      route: ApiRoutes?.getBasket,
      params: { customer: customerId },
    });
   

    const totalQuantities = response.data?.result?.subCarts?.map(
      (subCart: any) =>
        subCart.items.reduce(
          (total: any, item: any) => total + item?.quantity,
          0
        )
    );
     // Calculate the total sum of quantities in all subCarts
     const totalSum = totalQuantities.reduce((total:any, sum:any) => total + sum, 0);

    return totalSum; // Adjust this based on your API response structure
  }
);

export const getServicesLength = createAsyncThunk(
  "servicesLength/getServicesLengthAsync",
  async (_, { getState }) => {
    const state = getState() as RootState; // Access the RootState
      const customerId = state?.auth?.authData?._id;
    const response = await GetService({
      route: 'requests/listCart',
       params: { customer: customerId },
    });

    console.log(response , 'cart length')
    
    return response.data?.count; // Adjust this based on your API response structure
  }
);

export const getTotalCartLength = createAsyncThunk(
  "totalCartLength/getTotalCartLengthAsync",
  async (_, { dispatch }) => {
    // Dispatch the individual thunks and await their completion
    const lengthOfCart = await dispatch(getCartQuantity());

    const lengthOfBasket = await dispatch(getBasketQuantity());
    const lengthOfServices = await dispatch(getServicesLength());
    // console.log('cart length',  lengthOfCart?.payload +
    //   lengthOfBasket?.payload +
    //   lengthOfServices?.payload , {
    //     lengthOfCart: lengthOfCart?.payload ,
    //     lengthOfBasket:  lengthOfBasket?.payload ,
    //     lengthOfServices:lengthOfServices?.payload
    //   })

    const total =
      lengthOfCart?.payload +
      lengthOfBasket?.payload +
      lengthOfServices?.payload;
    return total? total : 0;
  }
);

// Define the initial state
const initialState=0;

const lengthOfCartSlice = createSlice({
  name: "cartLength",
  initialState,
  reducers: {
    // Add your custom reducers here if needed
    updateCartTotal:(state,action)=>{
      return   !state? action?.payload :state+action?.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalCartLength.fulfilled, (state, action) => {
      return action.payload;
    });

  },
});

export default lengthOfCartSlice.reducer;
export const {updateCartTotal} =lengthOfCartSlice?.actions