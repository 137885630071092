import React, { useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa6"; // Importing the filter icon from Font Awesome 6
import { t } from "i18next";
import useLang from "../hooks/useLang";
import i18n from "../i18n";

const FilterDrawer = ({children}:{children:JSX.Element}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
    const {dir , isAr} = useLang()
 

  return (
    <Box>
        <Box display={{base:'none' , md:'block'}} >{children}</Box>
      <IconButton
        icon={<FaFilter />} 
        aria-label={t("Filter")}
        position="fixed"
        bottom={40}
        right={isAr? 'unset' : 8}
        left={isAr? 8: 'unset' }
        zIndex="tooltip"
        // colorScheme="teal"
        size="lg"
        onClick={onOpen}
        bgColor={'primary'}
      />

      {/* Drawer Component */}
      <Drawer  placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent fontFamily={isAr ? 'ArabicFont' : 'JosefinSansRegular'} dir={dir}>
            <DrawerCloseButton />
            <DrawerHeader >{t('Filter')}</DrawerHeader>
            <DrawerBody>
              {children}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default FilterDrawer;
