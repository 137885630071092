import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./App.css";
import Routes from "./routes/Routes";
import { useAppDispatch, useAppSelector } from "./redux/store";
import { useTranslation } from "react-i18next";
import { SocketProvider } from "./Socket/SocketProvider";
import useLoginAsGuest from "./hooks/useLoginAsGuest";
import { RtlProvider } from "./i18n/rtl-provider";
import "moment/locale/ar"; // Import Arabic locale
import moment from "moment";
import { Global } from "@emotion/react";

function App() {
  const { t, i18n } = useTranslation();
  const { loginAsGuest, loading } = useLoginAsGuest();

  const userId = useAppSelector((state) => state.auth.authData?._id);
  const token = useAppSelector((state) => state.auth.token);
  const location = useLocation();
  const pathname = window.location?.pathname;
  const paymentsRouts = [
    "/payment_succeed",
    "/payment_failed",
    "/mobile_payment_succeed",
    "/mobile_payment_failed",
  ];
  const user = localStorage.getItem("authInfo");

  const handleAuth = async () => {
    if (!user && !paymentsRouts.includes(pathname)) {
      loginAsGuest();
      return;
    }
    if (user) {
      // const userData = await JSON.parse(user)
      // console.log({userData} ,'userData' , location)
      // if(userData?.isPhoneVerified === false){
      //   console.log({userData} ,'userData' ,)
      // }
    }
  };

  useEffect(() => {
    handleAuth();
  }, [user, location?.pathname]);

  useEffect(() => {
    moment.locale(i18n?.language);
  }, [i18n?.language]);

  return (

    <div
    className={i18n.language === "en" ? "en-font" : "ar-font"}
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        // fontFamily:
        //   `${i18n.language === "en" ? "JosefinSansRegular" : "ArabicFont"} !important`,
      }}
    >
      <RtlProvider>
        <SocketProvider
          userId={userId}
          userRole={"customer"}
          userToken={localStorage.getItem("token") || ""}
        >
          <Routes />
        </SocketProvider>
      </RtlProvider>
    </div>
  );
}

export default App;
