// ServiceCard.tsx

import React from "react";
import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Img,
  Center,
  Button,
  ResponsiveValue,
} from "@chakra-ui/react";
import { lang } from "../utils/utilsFunctions";
import Rating from "./Rating";
import expand from "../assets/images/imgs/expand.png";
import favourite from "../assets/images/imgs/favorite.png";
import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { emptyImage } from "../assets/images";
import useLang from "../hooks/useLang";
type Image = {
  Location?: string;
};
interface Service {
  _id?: number;
  productName?: string;
  nameEn?: string;
  nameAr?: string;
  descriptionAr?: string;
  descriptionEn?: string;
  basePrice: number;
  images?: Array<Image>;
  rating?: number;
  shop: { _id: string; nameEn: string; nameAr: string };
  defaultPackage?: { price?: number };
}

interface ServiceProps {
  service: Service;
  width?:  ResponsiveValue<number | (string & {})>
}

const ServiceCard: React.FC<ServiceProps> = ({ service  , width}) => {
  const { t , dir, isAr } = useLang();
  return (
    <Flex
      direction={"column"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      width={width || '90%'}
      height="365px"
      // backgroundColor={"rgba(255, 253, 239, 0.57)"}
      // zIndex={1}
      // justify="center" // Center vertically
      align="center"
      // paddingY={"16px"}
      dir={dir}
    >
      <Box
        width={"100%"}
        height={"60%"}
        backgroundColor="#F9F0E3"
        backgroundImage={service?.images?.[0]?.Location || emptyImage}
        bgRepeat="no-repeat"
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        p={2}
        position={"relative"}
      >
        <Box position={"absolute"} bottom={1} right={2}>
          <Rating rating={service?.rating || 0} />
        </Box>
      </Box>
      <Flex direction={"column"} mt={2} width={"100%"} px={4}>
        <Flex>
          <Text color={"primary"}> {isAr ? service?.nameAr : service?.nameEn}</Text>
        </Flex>
        <Flex>
          <Text mr={1} fontWeight={"bold"}>
            {t('By')} :
          </Text>
          <Link to={`/store-details/${service?.shop?._id}`}>
            <Text color={"primary"}> {service?.shop?.[`name${lang}`]}</Text>
          </Link>
          {/* <Text color={"primary"}> {service?.shop?.[`name${lang}`]}</Text> */}
        </Flex>
        {/* <Heading noOfLines={1} as="h3" size="md" mb="2">
          {service?.productName}
        </Heading> */}
        <Text color={"#886969"} mb="2" noOfLines={1}>
          {service?.[`description${lang}`]}
        </Text>
        <Flex justify="flex-start">
          <Flex>
            <Text color="primary" mr={1}>
              {t('Starts at')} :{" "}
            </Text>
            <Text color={"green"} pr={"10px"}>
              {service?.basePrice} {t("AED")}
            </Text>
          </Flex>
          {/* {service?.minPackage?.originalPrice !==
            service?.minPackage?.price && (
            <Text textDecoration="line-through" color="gray.600">
              {service?.minPackage?.originalPrice?.toString()} {t("AED")}
            </Text>
          )} */}

          {/* Additional information or actions can be added here */}
        </Flex>
        <Button
          variant={"outline"}
          marginTop={"10px"}
          size="sm"
          borderRadius={"20px"}
          // width={"70%"}
          marginX={"auto"}
          // onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          //   e?.preventDefault();
          // }}
       
        >
          <Link to={`/service-details/${service?._id}`}>
            <Flex
               onClick={(e) => {
            //    e.preventDefault(); // Prevent the default behavior of anchor links
                window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
              }}
             justify={"center"}>
              {/* <Image src={eye} mr={1} /> */}
              <Text> {t("Request Service")}</Text>
            </Flex>
          </Link>
        </Button>
      </Flex>
    </Flex>
  );
};

export default ServiceCard;
