// src/components/rtl-provider.js

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import rtl from 'stylis-plugin-rtl'
import i18n from '.'

// NB: A unique `key` is important for it to work!
const options = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' },
}

export function RtlProvider({ children }:any) {
 const dir =   i18n.language  === 'en' ? 'ltr' : 'rtl'
   
  const cache = createCache(options[dir])
  return <CacheProvider value={cache} children={children} />
}