import { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppField from "./../../common/AppField";
import usePutData from "./../../api/usePutData";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Heading, Box, Button, Toast, Flex, InputRightElement, IconButton, InputGroup } from "@chakra-ui/react";
import style from "./style.module.css";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { setAuth } from "../../redux/reducerSlices/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { T } from "../../utils/ValiidationSchemas";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const validationSchema = (t: T) => Yup.object().shape({
  //email: Yup.string().email("Invalid email").required("Email is required"),
  newPassword: Yup.string().required(t("password is required")),
});
interface FormValues {
  email: string;
  newPassword: string;
}
type SetAuthPayload = {
  authInfo: {};
};
export default function Security() {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { loading: loadingPutData, putData } = usePutData();
  const authInfo = useSelector<RootState, any | undefined>(
    (state) => state?.auth?.authData
  );

  const initialValues: FormValues = {
    email: authInfo?.email || "",
    newPassword: "",
  };
  const handleSubmit = async (values: FormValues) => {
    const data = {
      email: authInfo?.email,
      newPassword: values?.newPassword,
    };

    const res = await putData({
      route: `password`,
      data: data,
      successMsg: t("Data Updated Successfully"),
      params: { _id: authInfo?._id },
    });
    if (res) {
      dispatch(setAuth({ token: null, authData: null, authInfo: null }));

      navigate("/auth-tabs");
    }

    // dispatch(setAuthInfo({...authInfo, email:values.email}));
  };
  return (
    <>
      <Flex width={'95%'} justifyContent={'center'}>
        <Box className={style.form} >
          <Formik
            initialValues={{ email: "", newPassword: "" }}
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, dirty }) => (
              <Flex width={{ base: '100%', md: '90%' }}>
                <Form style={{ width: '100%' }}>
                  <Heading
                    textAlign={"center"}
                    fontSize={"20px"}
                    fontWeight={"500"}
                    color={"#88050D"}
                  >
                    {t('Login & Security')}
                  </Heading>
                  <Box className={style.form_line}  >
                    <AppField
                      // disabled={loadingPutData}
                      name="email"
                      title="Email"
                      placeholder={authInfo?.email}
                      disabled={true}
                    />
                  </Box>

                  <Box className={style.form_line}>
                    <AppField
                      disabled={loadingPutData}
                      name="newPassword"
                      title={t("New Password")}
                      type={showPassword ? 'text' : 'password'}
                      
                      icon= {<InputRightElement>
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          icon={!showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={() => setShowPassword(!showPassword)}
                          variant="ghost"
                          _hover={{
                            backgroundColor: 'transparent',
                          }}
                          boxShadow={'none'}
                          top={'5px'}
                        />
                      </InputRightElement>}
                    />
                   
                  </Box>

                  <Button
                    type="submit"
                    isLoading={loadingPutData}
                    isDisabled={isSubmitting || !dirty || loadingPutData}
                    className={style.submit_btn}
                  >
                    {t('Save Changes')}
                  </Button>
                </Form>
              </Flex>
            )}
          </Formik>
        </Box>
      </Flex>
    </>
  );
}
