import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Toast,
  VStack,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import WishlistCard from "../../components/WishlistCard";
import usePostData from "../../api/usePostData";
import { useTranslation } from "react-i18next";
import { removeFromWishlistAsync } from "../../redux/reducerSlices/Wishlist/wishlistSlice";
import { showToastMessage } from "../../utils/utilsFunctions";
import { updateCartTotal } from "../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";
import EmptyData from "../../components/EmptyData";

interface WishlistItem {
  id: number;
  name: string;
  description: string;
}

interface WishlistProps {
  items?: WishlistItem[];
}

const Wishlist: React.FC<WishlistProps> = ({ items }) => {
  const wishlistItems = useAppSelector((state) => state?.wishlist);
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, loading, setData, getData } = useGetData({
    route: ApiRoutes?.getWishlist,
    params: { customer: customerId },
  });
  const wishlist = data?.result?.items;

  const {
    data: postData,
    loading: postCartLoading,
    postData: postCartData,
  } = usePostData();

  const addToCart = async function (
    customerId: string,
    variationId: string,
    isFood: boolean
  ) {
    const response = await postCartData({

      route:isFood? ApiRoutes?.addToBasket :ApiRoutes?.addToCart,
      params: { customer: customerId, item: variationId, quantity: 1 },
      successMsg: t("Added to cart successfully"),
    });

    if (!response?.error) {
      dispatch(updateCartTotal(1));
    }
  };
  const removeFromWishlist = async function (variationId: string) {
    const response = await dispatch(removeFromWishlistAsync(variationId));

    if (response) {
      getData({
        route: ApiRoutes?.getWishlist,
        params: { customer: customerId },
      });
      showToastMessage(t("Removed from wishlist"));
    }
  };

  if (loading) {
    return (
      <Flex direction={"column"} width={"100%"} h={"80%"}>
        <Text
          mt={24}
          mb={16}
          fontSize={25}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
         {t('My Wishlist')}
        </Text>
        <Text color={"primary"} fontSize={20} textAlign={"center"}>
        {t('Loading...')} 
        </Text>
      </Flex>
    );
  }
  if (wishlist?.length === 0) {
    return (
      <Flex justify={"center"}>
        <Flex direction={"column"} width={"75%"}>
          <Text
            mt={24}
            mb={16}
            fontSize={25}
            fontWeight={"600"}
            color={"primary"}
            align="center"
          >
           {t('My Wishlist')}
          </Text>

          <EmptyData link="/all-products" />
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex justify={"center"}>
      <Flex direction={"column"} width={"75%"}>
        <Text
          mt={24}
          mb={16}
          fontSize={25}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
        {t('My Wishlist')}
        </Text>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap={10}
        >
          {wishlist?.map((item: any) => (
            <>
           
              <GridItem>
                <WishlistCard
                  product={item}
                  addToCart={() => addToCart(customerId, item?._id,item?.product?.isFood )}
                  removeFromWishlist={() => removeFromWishlist(item?._id)}
                />
              </GridItem>
            </>
          ))}
        </Grid>

        {/* <Flex wrap={"wrap"} py={8} mb={10}>
          {wishlist && [...wishlist ,...wishlist ,...wishlist , ...wishlist]?.map((item: any) => (
            <>
              <Box key={item.id} px={7} py={4}>
                <WishlistCard
                  product={item}
                  addToCart={() => addToCart(customerId, item?._id)}
                  removeFromWishlist={() => removeFromWishlist(item?._id)}
                />
              </Box>
            </>
          ))}
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default Wishlist;
