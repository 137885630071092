import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";
import { useAppSelector } from "../../../redux/store";
import { Box, Card, Flex, Stack, Text } from "@chakra-ui/react";
import StoreInCart from "../../../components/StoreInCart";
import ProductInCart from "../../../components/ProductInCart";
import { console_log, lang } from "../../../utils/utilsFunctions";
import EmptyData from "../../../components/EmptyData";
import ProductInOrder from "../../../components/ProductInOrder";
import OrderTotalDetails from "./OrderTotalDetails";

const OrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>(); // Access the 'id' parameter from the URL
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state?.auth?.authData);
  const [collapsedShops, setCollapsedShops] = useState<string[]>([]);



  const { data, loading  , onRefresh} = useGetData({
    route: ApiRoutes?.getOrderById,
    params: { customer: userData?._id, _id: orderId },
  });


  const subOrders = data?.result?.subOrders;

  const toggleShopCollapse = (shopId: string) => {
    setCollapsedShops((prevCollapsedShops) => {
      if (prevCollapsedShops.includes(shopId)) {
        return prevCollapsedShops.filter((id) => id !== shopId);
      } else {
        return [...prevCollapsedShops, shopId];
      }
    });
  };
  console_log({subOrders} , '')
  return (
    <Flex direction={"column"} mb={40}>
    {subOrders ? (
      subOrders?.length > 0 ? (
        <>

          <Flex
            direction={"column"}
            // justify={"center"}
            align={"center"}
          >
            <Stack width={"75%"} spacing={4} paddingTop={10} flex={1}>
            {/* {data?.result &&<OrderTotalDetails order={data?.result}  />} */}

              {subOrders?.map((subCart: any) => (
                <Box
                  width={"100%"}
                  // height={300}
                  key={subCart?.shop?._id}
                  // onClick={() => toggleShopCollapse(subCart?.shop?._id)}
                >
                  <StoreInCart
                  usedCashback={subCart?.usedCashback}
                  storeId={subCart?.shop?._id}
                    width={"100%"}
                    collapsedShops={collapsedShops}
                    paymentMethod={data?.result?.paymentMethod}
                    storeName={subCart?.shop?.[`name${lang}`]}
                    storeImage={subCart?.shop?.image?.Location}
                    price={subCart?.shopTotal}
                    onToggleCollapse={() =>
                      toggleShopCollapse(subCart?.shop?._id)
                    }
                    coupon={subCart?.coupon}
                    originalPrice= {subCart?.shopOriginalTotal}
                    onRefresh={onRefresh}
                    showDetails={true}
                    data ={subCart}
                  />
                  {!collapsedShops.includes(subCart?.shop?._id) && (
                    <Stack align={"center"} spacing={4} >
                      {subCart?.items?.map((item: any) => (
                        <ProductInOrder
                        width={"90%"}
                          key={item?.variation?._id}
                          productName={item?.product?.[`name${lang}`]}
                          itemTotal={item?.itemTotal}
                          variationPrice={
                            item?.variation?.minPackage?.originalPrice
                          }
                          variationDesc={
                            item?.variation?.[`description${lang}`]
                          }
                          variationImage={
                            item?.variation?.images?.[0]?.Location
                          }
                          quantity={item?.quantity}
                          fields={item?.variation?.fields}
                         
                     
                        />
                      ))}
                    </Stack>
                  )}
                </Box>
              ))}
            </Stack>
          </Flex>
        </>
      ) : (
        <EmptyData />
      )
    ) : (
      <Text>{t('Loading...')}</Text>
    )}
  </Flex>
  )
};

export default OrderDetails;
