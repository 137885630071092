import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import style from "./Navbar/style.module.css";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/useLang";

const CategoriesDrawer: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
    const  {isAr , dir} = useLang()
  const [placement, setPlacement] = useState<"left" | "right">(isAr? "right":'left');

  const isActive = (pathname: string, search?: string): boolean => {
    return location.pathname === pathname && location.search === search;
  };

  return (
    <Flex
      as="nav"
    //   align="center"
      justify="space-between"
      wrap="wrap"
      padding=".2rem"
      bgColor="#F5F5F5"
      fontSize={17}
      fontWeight={"500"}
    >
        
      <Box  display={{ base: "block", md: "none" }}>
      <IconButton
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          variant="outline"
          colorScheme="whiteAlpha"
          onClick={onOpen}
          mx={2}
        />
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay>
            <DrawerContent dir={dir}>
              <DrawerCloseButton />
              <DrawerHeader>{t('Menu')}</DrawerHeader>
              <DrawerBody >
                <UnorderedList listStyleType="none" paddingLeft={0}>
                  <ListItem
                    className={isActive("/", "") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "/", search: "" }}>
                      <Text>{t('Home')}</Text>
                    </Link>
                  </ListItem>

                  <ListItem
                    className={isActive("/all-products", "") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "all-products", search: "" }}>
                      <Text>{t('Products')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-categories", "") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "all-categories", hash: "all-categories" }}>
                      <Text>{t('Categories')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-services", "") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={"all-services"}>
                      <Text>{t('Services')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-stores", "") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={"all-stores"}>
                      <Text>{t('Shops')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-products", "?discountValue=-1") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "/all-products", search: "?discountValue=-1" }}>
                      <Text>{t('Top Deals')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-products", "?rank=-1") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "/all-products", search: "?rank=-1" }}>
                      <Text>{t('Trending Now')}</Text>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={isActive("/all-products", "?sortOrder=-1&sortByDate=creationDate") ? style.navbar_li_active : style.navbar_li_drawer}
                    mb={3}
                    onClick={onClose}
                  >
                    <Link to={{ pathname: "/all-products", search: "?sortOrder=-1&sortByDate=creationDate" }}>
                      <Text>{t('Recently Added')}</Text>
                    </Link>
                  </ListItem>
                </UnorderedList>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </Flex>
  );
};

export default CategoriesDrawer;
