import React, { useState } from "react";
import { Box, Flex, Image, Text, Button, Input } from "@chakra-ui/react";
import trash from "../assets/images/imgs/trash.svg";
import plus from "../assets/images/imgs/plus.svg";

import minus from "../assets/images/imgs/minus.svg";
import { useTranslation } from "react-i18next";

interface ProductInOrderProps {
  productName: string;
  itemTotal: number;
  variationPrice: number;
  variationDesc: string;
  variationImage?: string;
  quantity: number;
  //onDeleteProduct: () => void;
 
  fields?: [];
 
  width?: number|string;
}

const ProductInOrder: React.FC<ProductInOrderProps> = ({
  productName,
  itemTotal,
  variationPrice,
  variationDesc,
  variationImage,
  quantity = 1,
  // onDeleteProduct,
 
  fields,
 
  width
}) => {
  const {t} = useTranslation()
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // Remove any non-numeric characters from the input value
    const numericValue = e.target.value.replace(/\D/g, "");
    // Update the itemQuantity state with the numeric value
    setItemQuantity(parseInt(numericValue) || 0); // Convert the numeric value to an integer
  };
  return (
    <Box
      borderBottomWidth="1px"
      borderColor={"#886969"}
      // borderRadius="lg"
      overflow="hidden"
      // boxShadow="lg"
    width={width||"100%"}
      m={2}
      p={3}
      height={190}
    >
      
      <Flex height={"100%"}>
        <Box
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
          backgroundImage={variationImage}
          width={"30%"}
          //  boxSize={"163px"}
          height={"100%"}
          paddingTop={"10"}
          // objectPosition={"right"}
          // aspectRatio={"4/3"}
        ></Box>

        {/* <Image src={variationImage} alt={productName} boxSize="80px" /> */}

        <Box width={"40%"} flex="1" ml={4}>
          <Text fontSize={25} fontWeight="bold" mb={2}>
            {productName}
          </Text>
          <Text color={"primary"} fontSize={22} mb={2}>
            {variationDesc}
          </Text>
          {/* <Text fontSize={22} mb={2}>
            Quantity: {quantity}
          </Text> */}
          <Flex justifyContent={"space-between"}>
            <Text color={"primary"} fontSize={18} mb={2}>
              {t('Price')}: {variationPrice} {t('AED')}
            </Text>
            <Text color={"primary"} fontSize={18} mb={2}>
              {t('Item Total')}: {itemTotal} {t('AED')}
            </Text>
          </Flex>
        </Box>
 
      </Flex>
    </Box>
  );
};

export default ProductInOrder;
