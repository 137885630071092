import React from "react";
import { Box, Flex, Text, Button, useToast, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ApplyCoupon from "./ApplyCoupon";
import UseCashback from "./UseCashback";
import { emptyImage } from "../assets/images";

interface StoreInCartProps {
  collapsedShops: string[];
  paymentMethod?: string;
  storeName: string;
  storeImage?: string;
  price: number;
  width: number | string;
  onToggleCollapse: any;
  storeId: string;
  food?: boolean;
  setData?: any;
  data?: any;
  index?: any;
  coupon: any;
  originalPrice: number;
  usedCashback: number;
  onRefresh: () => void;
  showDetails?: boolean;
}

const StoreInCart: React.FC<StoreInCartProps> = ({
  originalPrice,
  coupon,
  collapsedShops,
  paymentMethod,
  storeName,
  storeImage,
  price,
  width,
  onToggleCollapse,
  storeId,
  setData,
  data,
  food,
  index,
  usedCashback,
  showDetails,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDiscount = !(price === originalPrice);

  const navigateToStore = function (storeId: string) {
    navigate(`/store-details/${storeId}`);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      mb={4}
      width={width || 800}
      // height={250}
      onClick={onToggleCollapse}
      px={8}
      bg={"secondary"}
    >
      <Flex height={"100%"}>
        <Box
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
          backgroundImage={storeImage || emptyImage}
          width={"30%"}
          paddingTop={"10"}
          bgColor={"#b4905830"}
        ></Box>
        <Flex px={4} py={3} width={"40%"}>
          <Flex
            direction={"column"}
            p={4}
            height={"100%"}
            justifyContent={"space-around"}
          >
            <Flex direction={"column"}>
              <Text fontSize={32} fontWeight={"600"}>
                {t("Your Order From")}
              </Text>
              <Text fontWeight={"600"} color={"primary"} fontSize={22}>
                {storeName}
              </Text>
            </Flex>
            <Text
              display={"flex"}
              fontWeight={"600"}
              color={"primary"}
              fontSize={22}
            >
              {t("Total")}:
              {isDiscount && (
                <Text
                  fontWeight={"400"}
                  textDecorationLine={"line-through"}
                  color={"gray"}
                  mx={2}
                  fontSize={24}
                >
                  {" "}
                  {originalPrice} {t("AED")}
                </Text>
              )}
              {price} {t("AED")}
            </Text>
          </Flex>
        </Flex>
        {!showDetails && (
          <Flex
            direction={"column"}
            justifyContent={"space-around"}
            width={"30%"}
            px={2}
            py={4}
          >
            <Flex justify={"flex-end"}>
              <Box>
                <Button
                  onClick={() => navigateToStore(storeId)}
                  px={8}
                  h={10}
                  bgColor={"primary"}
                  fontSize={43}
                >
                  +
                </Button>
                {/* <Text fontSize={15} align={"center"}>
                Add Items
              </Text> */}
              </Box>
            </Flex>
            <ApplyCoupon
              couponData={coupon}
              data={data}
              index={index}
              setData={setData}
              storeId={storeId}
              food={food}
              isCopounApplied={data?.result?.coupon}
            />
            <UseCashback
              usedCashback={usedCashback}
              data={data}
              index={index}
              setData={setData}
              storeId={storeId}
              food={food}
              userCashback={data?.result?.customer?.cashback}
              onRefresh={onRefresh}
            />
          </Flex>
        )}
        {showDetails && (
          <Flex
            flexDirection={"column"}
            px={8}
            py={4}
            flex={1}
            alignItems={"end"}
          >
            <Text fontSize={20} fontWeight={"bold"} color={"primary"}>
              {t("Order Details")}
            </Text>
            <Text>
              {t("Payment Method")}: {t(paymentMethod || "")}
            </Text>
            <Text>
              {t("Status")}: {t(data?.status || "")}
            </Text>

            <Text>
              {t("Shipping Fees")}: {data?.shopShippingFees} {t("AED")}
            </Text>
            <Text>
              {t("Taxes")}: {data?.shopTaxes} {t("AED")}
            </Text>
            {!!data?.usedCashback && (
              <Text>
                {t("Used Cashback")}: {data?.usedCashback} {t("AED")}
              </Text>
            )}

            {/* <Text>
              {t("Total")}:
              {data?.shopOriginalTotal !== data?.shopTotal && (
                <span
                  style={{
                    textDecorationLine: "line-through",
                    color: "gray",
                    marginLeft: 4,
                    marginRight: 4,
                  }}
                >
                  {" "}
                  {data?.shopOriginalTotal} {t("AED")}
                </span>
              )}
              {data?.shopTotal} {t("AED")}
            </Text> */}
            <Divider mt={2} width={"50%"} borderColor="#000" />
            <Text mt={1} fontWeight={"bold"}>
              {t("Total")}: {data?.subOrderTotal} {t("AED")}
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default StoreInCart;
