import { Flex, Text, Image, Button, Box } from '@chakra-ui/react';
import { UAEFlag, USAFlag, userIcon } from '../../../../assets/images';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {ChevronDownIcon} from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next';
 

export default function SelectLanguageButton() {
const {t, i18n} = useTranslation()
const handleLang = (value: string): void => {
  i18n.changeLanguage(value);
  localStorage.setItem('lang', value)
  window.location.reload();

};  
const btn_style={
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    color: "black",
    '&:hover': {
      backgroundColor: '#58585826',
      color: 'black',
    },
}
  return (
    <Flex alignItems={'center'}  justifyContent={'center'} >
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton p={0} css={btn_style} isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
              <Flex
                align={"center"}
                width='100%'
              >
                <Image  width={{base:4, md:5}} marginX={2} src={i18n.language === 'en' ? USAFlag : UAEFlag} />
                <Text fontSize={'xs'} >{i18n.language === 'en' ? "English" : "العربية"}</Text>
              </Flex>

            </MenuButton>

            <MenuList zIndex={3}>
              <MenuItem  onClick={() => handleLang("en")}>English</MenuItem>
              <MenuItem onClick={() => handleLang("ar")}>العربية</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  )
}
