import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import RouterWrapper from "./RouterWrapper";
import Register from "../pages/Auth/Register/Register";
import Home from "../pages/Home/Home";
import AllCategories from "../pages/AllCategories/AllCategories";
import Login from "../pages/Auth/Login/Login";
import AllStores from "../pages/AllStores/AllStores";
import AllServices from "../pages/AllServices/AllServcies";
import AllProducts from "../pages/AllProducts/AllProducts";
import StoreDetails from "../pages/AllStores/StoreDetails/StoreDetails";
import ProductDetails from "../pages/AllProducts/ProductDetails/ProductDetails";
import EditProfile from "../pages/EditProfile/EditProfile";
import ServiceDetails from "../pages/AllServices/ServiceDetails/ServiceDetails";
import Wishlist from "../pages/Wishlist/Wishlist";
import Cart from "../pages/Cart/Cart";
import Layout from "../components/Layout/Layout";
import CompareList from "../pages/CompareList/CompareList";
import Profile from "../pages/Profile/Profile";
import ProfileLayout from "../pages/Profile/components/ProfileLayout";
import Security from "../pages/Profile/Security";
import Orders from "../pages/Profile/Orders/Orders";
import Coupons from "../pages/Profile/Coupons";
import OrderDetails from "../pages/Profile/Orders/OrderDetails";
import Payment from "../pages/Profile/Payment/Payment";
import PhoneVerification from "../pages/Auth/Register/PhoneVerification";
import AuthTabs from "../pages/Auth/AuthTabs";
import NotFound from "../components/NotFound";
import CheckoutProcessRoot from "../pages/Cart/CheckOut";
import CheckoutService from "../pages/Cart/ServiceCart/CheckoutService";
import SuccessPaymentScreen from "../pages/SuccessPayment/SuccessPaymentScreen";
import FailurePaymentScreen from "../pages/FailurePayment";
import ServiceRequest from "../pages/Profile/ServiceRequest";
import EmailVerification from "../pages/Auth/Register/EmailVerification";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword";

export default function AllRoutes() {
  return (
    <RouterWrapper>
      <Routes>
      <Route path="/payment_succeed" element={<SuccessPaymentScreen />} />
      <Route path="/payment_failed" element={<FailurePaymentScreen />} />

      <Route path="/mobile_payment_succeed" element={<SuccessPaymentScreen mobile={true} />} />
      <Route path="/mobile_payment_failed" element={<FailurePaymentScreen mobile={true} />} />

        <Route element={<Layout />}>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/auth-tabs" element={<AuthTabs />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/phone-verification" element={<PhoneVerification />} />
          <Route path="/email-verification" element={<EmailVerification />} />

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />

            <Route path="/edit-profile" element={<EditProfile />} />
            <Route
              path="/all-categories"
              element={<AllCategories categories={[]} />}
            />
            <Route path="/all-categories" element={<AllCategories />} />
            <Route path="/all-stores" element={<AllStores />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/all-products" element={<AllProducts />} />
            <Route path="/all-variations" element={<AllProducts />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/compareList/:firstProductId/:secondProductId" element={<CompareList />} />

            <Route path="/all-products/:shopId" element={<AllProducts />} />
            <Route path="/all-services/:shopId" element={<AllServices />} />

            <Route path="store-details/:shopId" element={<StoreDetails />} />
            <Route
              path="product-details/:productId"
              element={<ProductDetails />}
            />
            <Route
              path="service-details/:serviceId"
              element={<ServiceDetails />}
            />
            <Route path="order-details/:orderId" element={<OrderDetails />} />
            <Route path="checkout-order" element={<CheckoutProcessRoot />} />
            <Route path="/service-checkout" element={<CheckoutService />} />

            <Route element={<ProfileLayout />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/security" element={<Security />} />
              <Route path="/profile/orders" element={<Orders />} />
              <Route path="/profile/coupons" element={<Coupons />} />
              <Route path="/profile/payment" element={<Payment />} />
              <Route path="/profile/service-request" element={<ServiceRequest />} />
              {/* <Route  path="/profile/wishlist" element={<Wishlist />} /> */}
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </RouterWrapper>
  );
}
