import { useState } from 'react';
import { Box, Text, useToast } from '@chakra-ui/react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { showMSG } from '../utils/utilsFunctions';
import { useTranslation } from 'react-i18next';

type Location = { lat: number, lng: number }
function MapView({defaultLocation , onChange}:{defaultLocation?:Location , onChange:(value:Location)=>void}) {
  const [position, setPosition] = useState<Location | undefined >(defaultLocation);
  const {t} = useTranslation()
  const toast = useToast()
// const navigator = useNavigate()
  const handleMapClick = (event:any) => {
    const newLat = event?.detail?.latLng?.lat;
    const newLng = event?.detail?.latLng.lng;
    console.log({newLat ,newLng })
    setPosition({ lat: newLat, lng: newLng });
    onChange({ lat: newLat, lng: newLng })
  };

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
            console.log("[longitude, latitude]",[longitude, latitude])
            setPosition({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting location', error);
          showMSG(toast ,'error' , ' Please allow location access.')
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  };

  return (
    <Box h="30vh" w="10wh" bgColor="gray" mb={6} mt={1}>
      <APIProvider apiKey="AIzaSyBte4z8mbVa1Ln_TH-aZWZAh0YneMqkvYg">
        <Map
         key={position? position.lat + ":" + position.lng : ""}
          defaultCenter={position||{lng:55.296249, lat:25.276987}}
          defaultZoom={10}
          onClick={handleMapClick}
        >
          {position && <Marker position={position} />}
        </Map>
      </APIProvider>
      <Text
      onClick={getCurrentLocation}
      cursor={'pointer'} textAlign={'end'} color={'primary'} fontWeight={'bold'} textDecoration={'underline'} >{t('Pick up your current location')}</Text>
    </Box>
  );
}

export default MapView;
