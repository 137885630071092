import { Box } from "@chakra-ui/react";
import React from "react";
import DynamicFiled from "./DynamicFiled";
import { Field, FieldProps } from "formik";

const DynamicForm = ({
  onChange,
}: {
  onChange: (props: any) => void;
}) => {
  return (
    <Field name="form">
      {({ field, form }: FieldProps<any>) => (
        <>
          {form?.values.form?.fields?.map((item: any, index: number) => {
            return (
              <DynamicFiled onChange={onChange} index={index} filed={item} />
            );
          })}
        </>
      )}
    </Field>
  );
};

export default DynamicForm;
