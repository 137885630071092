import { useState } from "react";
import { useTranslation } from "react-i18next";
import useGetData from "../../api/useGetData";

import moment from "moment";
import {
  Box,
  Text,
  Checkbox,
  VStack,
  HStack,
  Badge,
  StackDivider,
  SimpleGrid,
  Button,
  useToast,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";
import { FaCircleArrowLeft } from "react-icons/fa6";

import ShowMore from "../../common/ShowMore";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

interface Coupon {
  id: string;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  code: string;
  quantity: number;
  discountType: string;
  value: number;
  expirationDate: string;
  isActive: boolean;
  percentage: number;
  shop: { _id: string; nameEn: string; nameAr: string };
  usedBy: any[];
}

export default function Coupons() {
  const customerId = useAppSelector((state) => state?.auth?.authInfo?._id);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toast = useToast(); // Initialize toast for feedback

  const { data, loading, loadingMoreData, loadMoreData, getData } = useGetData({
    route: `coupons/list`,
    params: { userType: "customer", limit: 30 },
  });


  const { result: coupons = [] } = data || {};

  const today = moment(); // Use moment for current date

  // Function to handle copying coupon code
  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code).then(() => {
      toast({
        title: t("Code copied"),
        description: `${
          i18n.language === "en"
            ? ` ${t("Coupon code: ")} ${code} `
            : ` ${code} ${t("Coupon code: ")}  `
        }`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  // Filter state
  const [filter, setFilter] = useState("all");
  // Filter coupons based on filter state
  const filteredCoupons = coupons.filter((coupon: Coupon) => {
    if (filter === "withShop") return coupon.shop;
    if (filter === "withoutShop") return !coupon.shop;
    return true; // Show all coupons
  });

  // Check if all filtered coupons are shown
  const allCouponsShown = filteredCoupons.length !== coupons.length;

  return (
    <Box p={5} width={"80%"} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      {/* Filter dropdown */}
      <HStack mb={4} justify="flex-end">
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          width="220px"
          bg="white"
        >
          <option value="all">{t("All Coupons")}</option>
          <option value="withShop">{t("Coupons Type: Shop")}</option>
          <option value="withoutShop">{t("Coupons Type: Sona3")}</option>
        </Select>
      </HStack>
      <VStack
        spacing={4}
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
        p={4}
      >
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 3 }} // Responsive column settings
          spacing={7}
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
        >
          {filteredCoupons.map((coupon: Coupon) => {
            const isExpired = moment(coupon.expirationDate).isBefore(today);
            const isCustomerUsed = coupon?.usedBy?.some(
              (used) => used?.customer?._id === customerId
            );

            return (
              <Box
                key={coupon.id}
                p={5}
                shadow="md"
                borderWidth="1px"
                borderColor={isCustomerUsed ? "GrayText" : "#88050D"}
                borderRadius="md"
                style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
              >
                <HStack justify="space-between" align={"flex-start"}>
                  <Box>
                    <Text
                      fontWeight="bold"
                      fontSize="lg"
                      color={isCustomerUsed ? "GrayText" : "#88050D"}
                      textDecorationLine={isCustomerUsed ? "line-through" : ""}
                    >
                      {coupon.nameEn}
                    </Text>
                    <Text
                      fontWeight="bold"
                      fontSize="lg"
                      color={isCustomerUsed ? "GrayText" : "#88050D"}
                      textDecorationLine={isCustomerUsed ? "line-through" : ""}
                    >
                      {coupon.nameAr}
                    </Text>
                  </Box>
                  {/* {isExpired ? (
                    <Badge colorScheme="red">{t("Expired")}</Badge>
                  ) : (
                    <Badge colorScheme="green">{t("Valid")}</Badge>
                  )} */}
                  {isCustomerUsed ? (
                    <Badge colorScheme="yellow">{t("Coupon Used")}</Badge>
                  ) : (
                    <>
                      {isExpired ? (
                        <Badge colorScheme="red">{t("Expired")}</Badge>
                      ) : (
                        <Badge colorScheme="green">{t("Valid")}</Badge>
                      )}
                    </>
                  )}
                </HStack>
                <Text mt={2}>
                  {t("Discount")} : {t(coupon.discountType)}
                </Text>
                <Text mt={2}>
                  {coupon.discountType === "percentage"
                    ? t("The Percentage")
                    : t("The Value")}{" "}
                  :{" "}
                  {coupon.discountType === "percentage"
                    ? coupon.percentage * 100 + "%"
                    : coupon.value}
                </Text>
                <HStack justify="space-between" mt={2}>
                  <Text textDecoration={isCustomerUsed ? "" : "underline"}
                  >
                    {t("code")} : {coupon.code}
                  </Text>
                  {!isCustomerUsed && (
                    <MdContentCopy
                      style={{ fontSize: "1.3rem", cursor: "pointer" }}
                      onClick={() => handleCopyCode(coupon.code)}
                    />
                  )}
                </HStack>
                <Text mt={2}>
                  {t("quantity")} : {coupon.quantity}
                </Text>
                <Text mt={2}>
                  {t("Expiration Date")} :{" "}
                  {moment(coupon.expirationDate).format("DD/MM/YYYY")}
                </Text>

                {coupon.shop ? (
                  <HStack justify="space-between" align="end">
                    <Text mt={2}>
                      {/* {t("Shop")} :{" "}
                    {i18n.language === "ar"
                      ? coupon.shop.nameAr
                      : coupon.shop.nameEn} */}
                      {t("Coupon Type")} : {t("Shop")}
                    </Text>
                    {/* <Link to={`/store-details/66e00423e8491700543ea300`}> */}
                    <Link to={`/store-details/${coupon?.shop?._id}`}>
                      <FaCircleArrowLeft
                        style={{
                          fontSize: "1.3rem",
                          cursor: "pointer",
                          color: "#88050D",
                          transform:
                            i18n.language === "en"
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                    </Link>
                  </HStack>
                ) : (
                  <Text mt={2}>
                    {t("Coupon Type")} : {t("Sona3")}
                  </Text>
                )}
              </Box>
            );
          })}
        </SimpleGrid>

        {/* Hide ShowMore button if all coupons are shown */}
        {!allCouponsShown && (
          <ShowMore
            style={{ width: "100%", marginTop: 60 }}
            data={filteredCoupons}
            loadMoreData={loadMoreData}
            loading={loadingMoreData}
            count={data?.count}
          />
        )}
      </VStack>
    </Box>
  );
}
