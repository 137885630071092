import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import {
  lang,
  onChangeDynamicFormFieldValueProps,
} from "../utils/utilsFunctions";

type Props = {
  defaultValue?: {};
  options: any[];
  optionLabel: string;
  optionValue: string;
  onEndReached?: () => void;
  loading?: boolean;
  isMulti?: boolean;
  handleBlur?: (v: any) => any;
  name: string;
  placeholder?: string;
  title?: string;
  index: number;
  onChange: (props: onChangeDynamicFormFieldValueProps) => void;
};

export default function DynamicSearchSelectInput({
  name,
  title,
  placeholder,
  loading,
  isMulti,
  defaultValue,
  onChange,
  optionLabel,
  optionValue,
  options,
  index,
}: Props) {
  const handleMenuScroll = (event: any) => {
    const target = event.target;
    // Check if the user has scrolled to the bottom
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      console.log("Reached the end of options!");
    }
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#F9FCF5", // Change the primary color
      primary25: "#F9FCF5", // Change the color when hovering over an option
      primary50: "#F9FCF5", // Change the color when an option is selected
      dangerLight: "#8a747563",
      danger: "hsl(0, 0%, 20%)",
    },
  });

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<any>) => {
        const item = form?.values?.form?.fields?.[index];
        const error = item?.error;

    
        const customStyles = {
          control: (provided: any, state: any) => ({
            ...provided,
            border: error
              ? "1px solid #E53E3E"
              : state.isFocused
            //   ? 
            //   "0px solid #8A7475"
            //   : "1px solid #8A7475",,
            ,
            boxShadow: error
              ? " 0 0 0 1px #E53E3E"
              : state.isFocused
              ? "0 0 0 1px #8A7475"
              : "#8A7475"
              ,
            cursor: "text",
            minHeight: "52px",
            borderRadius: "7px",
            backgroundColor: state.isFocused ? "#F0F0F0" : "#F0F0F0",
            _hover: {
              border: error ? "2px solid #E53E3E" : "2px solid #F9FCF5",
              borderColor: "red",
            },
          }),
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#F9FCF5" : "#F9FCF5",
            color: state.isFocused ? "#88050D" : "black",
            cursor: "pointer",
          }),
          // Add more styles as needed
        };


        return (
          <FormControl isInvalid={true}>
            <FormLabel
              mb={1}
              mt={4}
              // color={"#333"}
              fontWeight={400}
              htmlFor={name}
              color={"primary"}
            >
              {item?.[`name${lang}`]}
            </FormLabel>
            <Select
              name={name}
              isLoading={loading}
              placeholder={item?.[`description${lang}`]}
              defaultValue={item?.value}
              onChange={(value: any) => {
                onChange({
                  isEnum: true,
                  fields: form?.values?.form?.fields,
                  index: index,
                  item: item,
                  value: value,
                });
              }}
              isMulti={isMulti}
              options={options}
              onMenuScrollToBottom={handleMenuScroll}
              styles={customStyles}
              theme={customTheme}
              onInputChange={() => {
      
              }}
              getOptionLabel={(option: any) => option[optionLabel]}
              getOptionValue={(option: any) => option[optionValue]}
            />
            <Text color={"error"} fontSize={"sm"}>
              {error}
            </Text>
          </FormControl>
        );
      }}
    </Field>
  );
}
