import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { GoPerson } from "react-icons/go";
import { SlLogout } from "react-icons/sl";
import { FaBars } from "react-icons/fa"; // Menu icon for mobile
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/reducerSlices/Auth/authSlice";
import useLoginAsGuest from "../../../../hooks/useLoginAsGuest";
import { user, bag, settings } from "./../../../../assets/images/index";
import { useTranslation } from "react-i18next";
import style from "./style.module.css";
import useLang from "../../../../hooks/useLang";

import { RiCoupon4Line } from "react-icons/ri";

export default function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginAsGuest } = useLoginAsGuest();
  const { t } = useTranslation();
  const { dir, isAr } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure(); // For controlling drawer state

  const activeClassName = (pathname: string): string =>
    location.pathname === pathname ? style.li_active : style.li;

  return (
    <>
      {/* Floating IconButton for Mobile to open Drawer */}
      <Flex
        display={{ base: "flex", md: "none" }}
        gap={2}
        alignItems={"center"}
      >
        <IconButton
          icon={<FaBars />}
          aria-label="Open Menu"
          bgColor={"primary"}
          onClick={onOpen}
        />
        <Text fontSize={"lg"} color={"primary"}>
          {t("My Account")}
        </Text>
      </Flex>

      {/* Drawer Component for Mobile View */}
      <Drawer
        isOpen={isOpen}
        placement={isAr ? "right" : "left"}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent dir={dir}>
          <DrawerCloseButton />
          <DrawerHeader>{t("Menu")}</DrawerHeader>
          <DrawerBody>
            {/* Side menu content goes inside the DrawerBody */}
            <Box borderRadius={20} bg={"#EFEBE3"} px={7} py={8}>
              <Flex
                onClick={() => {
                  navigate("/profile");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile")}
              >
                <GoPerson style={{ fontSize: "1.5rem" }} />
                <Text>{t("OverView")}</Text>
              </Flex>

              <Flex
                onClick={() => {
                  navigate("/profile/orders");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile/orders")}
              >
                <Image width={{ base: 4, md: 5 }} src={bag} />
                <Text>{t("Orders")}</Text>
              </Flex>

              <Flex
                onClick={() => {
                  navigate("/profile/coupons");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile/coupons")}
              >
                <RiCoupon4Line style={{ fontSize: "1.8rem" }} />

                <Image width={{ base: 4, md: 5 }} src={bag}/>
                <Text>{t("Coupons")}</Text>
              </Flex>

              <Flex
                onClick={() => {
                  navigate("/profile/service-request");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile/service-request")}
              >
                <Image width={{ base: 4, md: 5 }} src={bag} />
                <Text>{t("Requested Services")}</Text>
              </Flex>

              <Flex
                onClick={() => {
                  navigate("/profile/security");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile/security")}
              >
                <Image width={{ base: 4, md: 5 }} src={settings} />
                <Text>{t("Account Setting")}</Text>
              </Flex>

              <Flex
                onClick={() => {
                  dispatch(logout());
                  loginAsGuest();
                  onClose(); // Close drawer after logout
                }}
                alignItems={"center"}
                gap={"5px"}
                className={style.LogOut_li}
              >
                <SlLogout style={{ fontSize: "1rem" }} />
                <Text>{t("Log Out")}</Text>
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Side menu for larger screens */}
      <Box
        borderRadius={20}
        bg={"#EFEBE3"}
        px={7}
        py={8}
        display={{ base: "none", md: "block" }} // Only show on larger screens
      >
        <Flex
          onClick={() => {
            navigate("/profile");
          }}
          alignItems={"center"}
          gap={"5px"}
          pb={2}
          mb={5}
          borderBottom={".5px solid #886969"}
          className={activeClassName("/profile")}
        >
          <GoPerson style={{ fontSize: "1.5rem" }} />
          <Text>{t("OverView")}</Text>
        </Flex>

        <Flex
          onClick={() => {
            navigate("/profile/orders");
          }}
          alignItems={"center"}
          gap={"5px"}
          pb={2}
          mb={5}
          borderBottom={".5px solid #886969"}
          className={activeClassName("/profile/orders")}
        >
          <Image width={{ base: 4, md: 5 }} src={bag} />
          <Text>{t("Orders")}</Text>
        </Flex>

        <Flex
                onClick={() => {
                  navigate("/profile/coupons");
                  onClose(); // Close drawer after navigation
                }}
                alignItems={"center"}
                gap={"5px"}
                pb={2}
                mb={5}
                borderBottom={".5px solid #886969"}
                className={activeClassName("/profile/coupons")}
              >
                <RiCoupon4Line style={{ fontSize: "1.2rem" }} />

                <Text>{t("Coupons")}</Text>
              </Flex>

        <Flex
          onClick={() => {
            navigate("/profile/service-request");
          }}
          alignItems={"center"}
          gap={"5px"}
          pb={2}
          mb={5}
          borderBottom={".5px solid #886969"}
          className={activeClassName("/profile/service-request")}
        >
          <Image width={{ base: 4, md: 5 }} src={bag} />
          <Text>{t("Requested Services")}</Text>
        </Flex>

        <Flex
          onClick={() => {
            navigate("/profile/security");
          }}
          alignItems={"center"}
          gap={"5px"}
          pb={2}
          mb={5}
          borderBottom={".5px solid #886969"}
          className={activeClassName("/profile/security")}
        >
          <Image width={{ base: 4, md: 5 }} src={settings} />
          <Text>{t("Account Setting")}</Text>
        </Flex>

        <Flex
          onClick={() => {
            dispatch(logout());
            loginAsGuest();
          }}
          alignItems={"center"}
          gap={"5px"}
          className={style.LogOut_li}
        >
          <SlLogout style={{ fontSize: "1rem" }} />
          <Text>{t("Log Out")}</Text>
        </Flex>
      </Box>
    </>
  );
}
