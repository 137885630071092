import { useAppSelector } from "../../../redux/store";
import { Flex, Text } from "@chakra-ui/react";
import ShowMore from "../../../common/ShowMore";
import EmptyData from "../../../components/EmptyData";
import ApiRoutes from "../../../api/services/api-routes";
import useGetData from "../../../api/useGetData";
import ServiceInCart from "../../../components/ServiceInCart";
import { useTranslation } from "react-i18next";
export const ServiceCart = () => {
  const userData = useAppSelector((state) => state?.auth?.authData);
  const {t} = useTranslation()
  const { loading, data, getData, loadMoreData, loadingMoreData } = useGetData({
    route:'requests/listCart',
    params: { 
      customer: userData?._id,
      "sortByDate": 'issueDate', 
      "sortOrder": -1,
     },
     
  });

  

  const services = data?.result;
  if (loading) {
    return (
      <Text color={"primary"} fontSize={20} textAlign={"center"}>
       {t('Loading...')} 
      </Text>
    );
  }
  return (
    <Flex justify={"center"}>
      {services?.length > 0 ? (
        <Flex direction={"column"} align={"center"} width={"75%"}>
          <Flex w={"100%"} direction={"column"}>
            {services?.map((service: any) => {
              // if(!service?.status) {
                return <ServiceInCart key={service?._id} service={service} />;  
              // }
            })}
          </Flex>

          <ShowMore
            style={{ width: "100%" }}
            data={services}
            count={data?.count}
            loadMoreData={loadMoreData}
            loading={loadingMoreData}
          />
        </Flex>
      ) : (
        <EmptyData  link={'/all-services'}/>
      )}
    </Flex>
  );
};
