import React from 'react';
import { Box, Button, Text, VStack, Icon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import useGetData from '../../api/useGetData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { console_log } from '../../utils/utilsFunctions';
import { useNavigate } from 'react-router-dom';
import { setAuthInfo } from '../../redux/reducerSlices/Auth/authSlice';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const SuccessPaymentScreen = ({mobile}:{mobile?:boolean}) => {
    const userId:any =   useSelector((state:any)=>state?.authData?.authData?._id)
    const navigate = useNavigate()
    const {t} = useTranslation()
    // const dispatch = useDispatch()

    // const {} =  useGetData({route:`get?_id=${userId}` ,  notLoadData : !userId,onSuccess:(res) =>{
    //     if(res?.data?.result){
    //         localStorage?.setItem('authInfo', JSON.stringify(res?.data?.result));
    //         dispatch(setAuthInfo(res?.data?.result))
    //     }
    // } })

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#eee00"
      p={4}
    >
      <VStack
        spacing={6}
        p={8}
        bg="white"
        rounded="lg"
        // shadow="sm"
        maxW="md"
        textAlign="center"
      >
        <Icon as={CheckCircleIcon} w={'10vh'} h={'10vh'} color="green.400" />
        <Text fontSize="2xl" fontWeight="bold">
          {t('Payment Successful!')}
        </Text>
        <Text fontSize="md" color="gray.500">
          {t('Your payment has been processed successfully. You can now continue using the services.')}
        </Text>
        {!mobile &&<Button 
        onClick={()=>{navigate('/') ;    window.location.reload()}}
        colorScheme="green" size="lg">
          {t('Continue')}

        </Button>}
      </VStack>
    </Box>
  );
};

export default SuccessPaymentScreen;
