import React from "react";
import { Button } from "@chakra-ui/react";


interface Props {
  onClick?:any;
  loading: boolean;
  title: string;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties | undefined;
  isDisabled?: boolean;
}
export default function AppButton({ onClick,loading, title, type, style ,isDisabled}: Props) {
  return (
    <>
      <Button
      isDisabled={isDisabled}
        type={type || "button"}
        isLoading={loading}
        loadingText={title}
        style={style}
        onClick={onClick}
      >
        {title}
      </Button>
    </>
  );
}
