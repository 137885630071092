import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";
import usePostData from "../../../api/usePostData";
import useDeleteData from "../../../api/useDeleteData";
import { lang, showToastMessage } from "../../../utils/utilsFunctions";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import Checkout from "../../../components/CartButtons";
import StoreInCart from "../../../components/StoreInCart";
import ProductInCart from "../../../components/ProductInCart";
import EmptyData from "../../../components/EmptyData";
import { useDispatch } from "react-redux";
import { updateCartTotal } from "../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";

export const FoodCart = () => {
  const [collapsedShops, setCollapsedShops] = useState<string[]>([]);
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toggleShopCollapse = (e: any, shopId: string) => {
    setCollapsedShops((prevCollapsedShops) => {
      if (prevCollapsedShops.includes(shopId)) {
        return prevCollapsedShops.filter((id) => id !== shopId);
      } else {
        return [...prevCollapsedShops, shopId];
      }
    });
  };

  const {
    data,
    loading: getCartLoading,
    setData,
    getData: fetchCartData,
    onRefresh
  } = useGetData({
    route: ApiRoutes?.getBasket,
    params: { customer: customerId },
  });
  const {
    data: postData,
    loading: postCartLoading,
    postData: postCartData,
  } = usePostData();
  const {
    data: postCheckout,
    loading: postCheckoutLoading,
    postData: postCheckoutData,
  } = usePostData();
  const {
    data: deleteData,
    loading: deleteCartLoading,
    deleteData: deleteCartData,
  } = useDeleteData();

  const totalItems = useMemo(() => {
    let total = 0;
    const totalQuantities = data?.result?.subCarts?.map((subCart: any) => {
      total += subCart?.items.reduce(
        (total: any, item: any) => total + item?.quantity,
        0
      );
    });
    return total;
  }, [data]);

  const addToCart = async (customerId: string, variationId: string) => {
    const response = await postCartData({
      route: ApiRoutes?.addToBasket,
      params: { customer: customerId, item: variationId, quantity: 1 },
      successMsg: t("Added to basket successfully"),
    });

    if (response?.result) {
      fetchCartData({
        route: ApiRoutes?.getBasket,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(1));
      showToastMessage(t("Added Successfully"));
    }
  };

  const removeFromCart = async (
    customerId: string,
    variationId: string,
    shopId: string,
    quantity: number
  ) => {
    const response = await deleteCartData({
      route: ApiRoutes?.removeFromBasket,
      params: {
        customer: customerId,
        item: variationId,
        quantity: quantity,
        shop: shopId,
      },
    });
    if (!response?.error) {
      fetchCartData({
        route: ApiRoutes?.getBasket,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(-1));
    }
  };

  const emptyCart = async (customerId: string) => {
    const response = await deleteCartData({
      route: ApiRoutes?.flushBasket,
      params: { customer: customerId },
    });
    if (!response?.error) {
      fetchCartData({
        route: ApiRoutes?.getBasket,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(-totalItems));
    }
  };

  const checkoutCartFn = async (cartId: string) => {
    const currentDate = new Date();
    const isoFormattedDate = currentDate.toISOString();
    const response = await postCheckoutData({
      route: ApiRoutes?.basketOrder,
      params: { cart: cartId },
      data: { customer: customerId, issueDate: isoFormattedDate },
      successMsg: t("Checkout Successfully"),
    });

    if (!response?.error) {
      fetchCartData({
        route: ApiRoutes?.getBasket,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(-totalItems));
    }
  };

  return (
    <Flex direction={"column"} mb={40}>
      {data?.result?.subCarts ? (
        data?.result?.subCarts.length > 0 ? (
          <>
            <Flex
              direction={"column"}
              // justify={"center"}
              align={"center"}
            >
              <Stack width={"80%"} spacing={4} paddingTop={10} flex={1}>
                <Checkout
                  cartId={data?.result?._id}
                  isFood={true}
                  checkoutCartFn={checkoutCartFn}
                  cartTotal={data?.result?.cartTotal}
                  customerId={customerId}
                  emptyCartFn={() => emptyCart(customerId)}
                  cartOriginalTotal={data?.result?.cartOriginalTotal}
                  cashback={data?.result?.customer?.cashback}
                />
                {data?.result?.subCarts?.map((subCart: any, index: any) => (
                  <Box
                    width={"100%"}
                    // height={300}
                    key={subCart?.shop?._id}
                    // onClick={() => toggleShopCollapse(subCart?.shop?._id)}
                  >
                    <StoreInCart
                      onRefresh={onRefresh}
                      usedCashback={subCart?.usedCashback}
                      storeId={subCart?.shop?._id}
                      width={"100%"}
                      collapsedShops={collapsedShops}
                      storeName={subCart?.shop?.[`name${lang}`]}
                      storeImage={subCart?.shop?.image?.Location}
                      price={subCart?.shopTotal}
                      onToggleCollapse={(e: any) =>
                        toggleShopCollapse(e, subCart?.shop?._id)
                      }
                      index={index}
                      data={data}
                      setData={setData}
                      food={true}
                      coupon={subCart?.coupon}
                      originalPrice={subCart?.shopOriginalTotal}
                    />
                    {!collapsedShops.includes(subCart?.shop?._id) && (
                      <Stack spacing={4} paddingLeft={5}>
                        {subCart?.items?.map((item: any) => (
                          <ProductInCart
                            key={item?.variation?._id}
                            productName={item?.product?.[`name${lang}`]}
                            itemTotal={item?.itemTotal}
                            variationPrice={
                              item?.variation?.minPackage?.price ||
                              item?.variation?.minPackage?.originalPrice
                            }
                            variationDesc={
                              item?.variation?.[`description${lang}`]
                            }
                            variationImage={
                              item?.variation?.images?.[0]?.Location
                            }
                            quantity={item?.quantity}
                            fields={item?.variation?.fields}
                            onDeleteProduct={() =>
                              removeFromCart(
                                customerId,
                                item?.variation?._id,
                                subCart?.shop?._id,
                                1
                              )
                            }
                            onDeleteAll={() => {
                              removeFromCart(
                                customerId,
                                item?.variation?._id,
                                subCart?.shop?._id,
                                item?.quantity
                              );
                              dispatch(updateCartTotal(item?.quantity));
                            }}
                            onAddToCart={() =>
                              addToCart(customerId, item?.variation?._id)
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  </Box>
                ))}
              </Stack>
            </Flex>
          </>
        ) : (
          <EmptyData />
        )
      ) : (
        <Text color={"primary"} fontSize={20} textAlign={"center"}>
          {t('Loading...')}
        </Text>
      )}
    </Flex>
  );
};
