import {
  Image,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  InputLeftElement,
  Card,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Flex,
  Text,
  useHighlight,
  Mark,
  Select,
} from "@chakra-ui/react";
import { filterIcon, searchIcon } from "../assets/images";
import { useEffect, useRef, useState } from "react";

import useGetData from "../api/useGetData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import useLang from "../hooks/useLang";

const SelectMenu = ({
  value,
  options,
  onChange,
}: {
  onChange: (value: { label: string; value: any }) => void;
  value: { label: string; value: any };
  options: { label: string; value: any }[];
}) => {
  return (
    <Select
      _focus={{
        borderColor: "#886969",
        boxShadow: "0px 0px 0px 0px #000000",
      }}
      borderColor={"secondary"}
      borderRightRadius={0}
      onChange={async (e) => {
        const value = await JSON.parse(e?.target?.value);
        onChange(value);
      }}
      textAlign={"left"}
      mr={2}
      defaultValue={"option1"}
      width={{base:'7rem' , md:"8rem"}}
      size={{ base: "sm", sm: "sm", md: "md" }}
      bgColor={"#E1E1E1"}
      fontSize={{base:'12px' , md:'15px'}}
    >
      {options?.map((item: any) => (
        <>
          <option  value={JSON.stringify(item)}>{item?.label}</option>
        </>
      ))}
    </Select>
  );
};

const SearchItem = ({
  item,
  image,
  search,
  onClick,
}: {
  item: any;
  image: any;
  search: string;
  onClick: () => void;
}) => {
  const { lang } = useLang();

  const chunks = useHighlight({
    text: item?.[`name${lang}`],
    query: [search],
  });


  return (
    <Flex
      key={item?._id}
      zIndex={20}
      onClick={onClick}
      cursor={"pointer"}
      fontWeight={"normal"}
      alignItems={"center"}
      _hover={{ fontWeight: "bold" }}
      px={5}
      py={3}
      borderBottomWidth={1}
    >
      <Image
        borderRadius={2}
        height={"3vh"}
        width={"3vh"}
        src={image?.Location}
      />
      {/* <Text px={3}>{item?.[`name${lang}`]}</Text> */}
      <Box mx={3}>
        {chunks.map(({ match, text }) => {
          if (!match) return text;
          return text === "instantly" ? (
            <Box as="u" fontFamily="NewYork">
              {text}
            </Box>
          ) : (
            <Mark color={"primary"} fontWeight={"bold"}>
              {text}
            </Mark>
          );
        })}
      </Box>
    </Flex>
  );
};

export function SearchInput() {
  const {
    data,
    loading: loadingSearchResult,
    getData,
    setData,
  } = useGetData({ notLoadData: true, route: "", params: {} });
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [searchAutoComplete, setSearchAtoComplete] = useState<string>("");
  const user: any = useSelector((state: RootState) => state?.auth?.authData);
  const { lang, isAr } = useLang();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const filterOptions = [
    { label: t("Product"), value: { route: "products/list" } },
    { label: t("Shop"), value: { route: "shops/list" } },
    { label: t("Service"), value: { route: "services/list" } },
  ];
  const [filterType, setFilterType] = useState(filterOptions?.[0]);

  //==============
  //AutoComplet
  //==============
  useEffect(() => {
    let myTimeout: number;
    if (search !== searchAutoComplete) {
      let myTimeout = setTimeout(() => {
        setSearchAtoComplete(search?.trim());
      }, 800);
    }
    return () => {
      clearTimeout(myTimeout);
    };
  }, [search]);

  useEffect(() => {
    if (
      search === searchAutoComplete &&
      !loadingSearchResult &&
      search?.length > 0
    ) {
      loadSearchResult(search, filterType);

    }
  }, [searchAutoComplete]);

  const loadSearchResult = async (
    search: string,
    value: { label: string; value: any }
  ) => {
    if (search) {
      const res = await getData({
        route: value?.value?.route,
        params: {
          nameEn: search,
          nameAr: search,
          descriptionAr: search,
        },
      });
    }
  };

  const selectMenu = (
    <>
      <SelectMenu
        options={filterOptions}
        onChange={(value) => {
          setFilterType(value);
          if (search) {
            setData(null);
            loadSearchResult(search, value);
            setIsFocus(true);
          }
        }}
        value={filterType}
      />
      {<Image h={{base:4 , md:5}} src={searchIcon} />}
    </>
  );

  const searchButton = (
    <>
      <Button
        bgColor={"primary"}
        borderLeftRadius={0}
        left={4}
        paddingY={"1rem"}
        paddingX={{ md: "2.8rem" }}
        size={{ base: "xs", sm: "sm", md: "md" }}
        onClick={() => { }}
      >
        {t("Search")}
      </Button>
    </>
  );

  return (
    <>
      {/* == */}

      <Box  width={"100%"}>
        <InputGroup
          zIndex={2}
          py={{ base: 1, md: 0 }}
          alignItems={"center"}
          size="lg"
          width={{ base: "100%", md: "100%" }}
        >
          {!isAr ? (
            <InputLeftElement
              h={"100%"}
              minWidth={{ base: "7rem", md: "8.5rem" }}
            >
              {selectMenu}
            </InputLeftElement>
          ) : (
            <>
              <InputRightElement h={"100%"} width="4.5rem">
                {searchButton}
              </InputRightElement>
            </>
          )}
          

          <Input
            _focus={{
              borderColor: "secondary",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              background: "#fff",
            }}
            borderColor={"secondary"}
            value={search}
            background={"white"}
            pr={
              isAr
                ? { base: "4.5rem", md: "6.5rem" }
                : { base: "4.5rem", md: "4.5rem" }
            }
            pl={{ base: "7rem", md: "9rem" }}
            type={"text"}
            placeholder={t("Search...")}
            borderEndRadius={isAr ? 5 : 0}
            size={{ base: "xs", sm: "sm", md: "md" }}
            onFocus={(state) => {
              setIsFocus(true);
            }}
            onChange={(e) => {
              let trimmedText = e.target.value === "  " ? "" : e.target.value;
              trimmedText = trimmedText.replace(/\s+/g, " ");
              trimmedText = trimmedText.replace(/[+*]+/g, "");
              setSearch(trimmedText);
              if (!trimmedText) {
                setData(null);
              }            }}

          />

          {!isAr ? (
            <InputRightElement h={"100%"}   width="4.5rem">
              {searchButton}
            </InputRightElement>
          ) : (
            <>
              <InputLeftElement
                h={"100%"}
                minWidth={{ base: "7rem", md: "8.5rem" }}
              >
                {selectMenu}
              </InputLeftElement>
            </>
          )}
        </InputGroup>
        {isFocus && (
          <Card
            backgroundColor={"#fff"}
            zIndex={2}
            overflowY={"scroll"}
            mx={"7%"}
            mt={1}
            position={"absolute"}
            maxHeight={"30vh"}
            width={{ base: "60%", md: "34%" }}
          >
            {loadingSearchResult && (
              <Flex m={2} justifyContent={"flex-end"}>
                {" "}
                <Spinner size={"sm"} color="primary" />{" "}
              </Flex>
            )}
            {data?.result?.map((item: any) => {
              const type = filterType?.label;
              const image =
                type === "Product"
                  ? item?.defaultVariation?.images?.[0] || item?.variation?.[0]
                  : type === "Service"
                    ? item?.images?.[0]
                    : item?.image;

              return (
                <SearchItem
                  onClick={() => {


                    setIsFocus(false);
                    setSearchAtoComplete(item?.[`name${lang}`]);
                    if (type === t("Shop")) {
                      navigate(


                        `/store-details/${item?._id}`
                      );
                    }
                    if (type === t("Product")) {
                      navigate(
                        `/product-details/${item?._id}`
                      );
                    }

                    if (type === t("Service")) {
                      navigate(
                        `/service-details/${item?._id}`
                      );
                    }
                  }}
                  search={search}
                  item={item}
                  image={image}
                />
              );
            })}
          </Card>
        )}
      </Box>
      {isFocus && (
        <Box
          onClick={() => {
            setIsFocus(false);
          }}
          top={0}
          left={0}
          position={"absolute"}
          width={"100%"}
          height={"100vh"}
          bgColor={"#00000050"}

          zIndex={1}
        >
          {" "}
        </Box>
      )}
    </>
  );
}
