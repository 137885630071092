import { Box, Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { showMSG } from "../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import usePostData from "../api/usePostData";
import ApiRoutes from "../api/services/api-routes";
import useDeleteData from "../api/useDeleteData";
import { useAppSelector } from "../redux/store";

const UseCashback = ({
    usedCashback,
  data,
  setData,
  storeId,
  food,
  userCashback,
  index,
  onRefresh
}: {
  data: any;
  setData: any;
  storeId: any;
  food?: boolean;
  userCashback: number;
  index: any;
  usedCashback:number;
  onRefresh:()=>void
}) => {
  const customerId = useAppSelector((state: any) => state?.auth?.authData?._id);
  const [cashback, setCashback] = useState<any>(usedCashback? usedCashback?.toString():"");
  const { t } = useTranslation();
  const toast = useToast();
  const { postData, loading } = usePostData();
  const { deleteData } = useDeleteData();

  //----- apply the cashback------
  const applyCashback = async (value: string) => {
    if (!value?.trim()) {
      showMSG(
        toast,
        "error",
        t("Please enter a cashback value you want to use!")
      );
      return;
    }
    const response = await postData({
      route:food?`baskets/cashback`: `carts/cashback`,
      params: { customer: customerId, cashback: +value, shop: storeId },
    });
    console.log({response})
    if (response?.success) {
        onRefresh()
    }
  };

  

 

  return (
    <Flex>
      <>
        <Input
          disabled={!!usedCashback}
          borderColor={"#000"}
          h={10}
          mr={2}
          flex={1}
          placeholder={t("Cashback you want to use")}
          value={cashback}
          onChange={(e) => {
            setCashback(e?.target?.value);
          }}
          required
          type="number"
          onClick={(e) => e.stopPropagation()} 
        />
      </>

      {<Button
        onClick={() => applyCashback(cashback)}
        isLoading={loading}
        h={10}
        isDisabled={!!usedCashback}
      >
       {usedCashback? t('Cashback'):t('Apply Cashback')}
      </Button>}

      {/* <Text>Cashback</Text> */}
    </Flex>
  );
};

export default UseCashback;
