import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, FormLabel, Select, Button, Box, Flex, useToast } from '@chakra-ui/react';
import { useOrder } from './Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { showMSG } from '../../../utils/utilsFunctions';

const options = ['cashOnDelivery', 'visa'];
interface FormValues {
  paymentMethod?: any;
}
const Payment = () => {
  const toast = useToast()
  const { order, setOrder, handleNext, handlePrevious } = useOrder();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialValues: FormValues = { paymentMethod:  params.get("paymentMethod") ? params.get("paymentMethod") : ""};
  return (
   <Flex flexDirection={'column'} alignItems={'center'} >
    <Box width={'50%'}>
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
          if(!values?.paymentMethod){
            showMSG( toast,'error', t('Please select payment method') )
            return
          }
        const updatedOrder = {
          ...order,
          paymentMethod: values.paymentMethod
        };
        setOrder(updatedOrder)
        params.set("paymentMethod",values.paymentMethod );
        params.set("step","2");
        handleNext()
        navigate({ search: params.toString() }, { replace: true });
      }}
      
    >
      {({ values, handleChange, handleBlur }) => (
        <Form   >
          <Box p={4} w="100%" mx="auto">
            <FormControl id="paymentMethod" mb={4}>
              <FormLabel>{t('Payment Method')}</FormLabel>
              <Field
                name="paymentMethod"
                as={Select}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentMethod}
              >
                <option value="" label={t("Select payment method")} />
                {options.map((option) => (
                  <option key={option} value={option}>
                    {t(option)}
                  </option>
                ))}
              </Field>
            </FormControl>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box textAlign={"start"} marginTop={"50px"}>
                <Button onClick={()=>{handlePrevious()}}> {t('Go Previous')} </Button>
              </Box>
              <Box textAlign={"end"} marginTop={"50px"}>
                <Button type="submit" >{t('Go Next')}</Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
    </Box>
   </Flex>
  );
};

export default Payment;
