import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Tab,
  TabList,
  Tabs,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import { lang } from "../../utils/utilsFunctions";
import ServiceCard from "../../components/ServiceCard";
import { useLocation, useParams } from "react-router-dom";

import CategoriesSidebar from "../../components/CategoriesSidebar";
import ViewCards from "../../components/ViewCards";
import { useTranslation } from "react-i18next";
import FilterDrawer from "../../components/FilterDrawer";

interface Service {
  id: number;
  name: string;
  // Add more properties as needed
}

const AllServices: React.FC = () => {
  const {t} = useTranslation()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const shopId = queryParams.get("shopId");
  const [categoryId, setCategoryId] = useState<any>(category);
  const [storeId, setStoreId] = useState<any>(shopId);
  const [tagId, setTagId] = useState();

  const categoryName = location.state?.categoryName;
  const categoryDescription = location.state?.categoryDescription;

  // useEffect(() => {

  //   shopId&& setStoreId(shopId)
  // }, [])

  return (
    <Flex py={16} justify={"center"}>
      <Flex justifyContent={"center"} direction={"row"} width={"75%"}>
      <FilterDrawer>
        <Flex direction={"column"}>
          <Box mb={4}>
            <CategoriesSidebar
              route={ApiRoutes?.listCategories}
              params={{ type: "service" }}
              setItemId={setCategoryId}
              title="Categories"
              customHeight={364}
              incomingSelectedId={categoryId || ""}
            />
          </Box>
          <Box mb={4}>
            <CategoriesSidebar
              route={ApiRoutes?.listShops}
              // params={{ type: "product" }}
              setItemId={setStoreId}
              title="Shops"
              customHeight={294}
            //  incomingSelectedId={shopId || ""}
            />
          </Box>
          <Box mb={4}>
            <CategoriesSidebar
              route={ApiRoutes?.listTags}
              // params={{ type: "product" }}
              setItemId={setTagId}
              title="Tags"
              customHeight={294}
              incomingSelectedId={""}
            />
          </Box>
        </Flex>
    </FilterDrawer>
        <Flex minWidth={{base:'100%' , md:"75%"}} ml={{base:0 , md:8}} direction={"column"}>
          <Text
            mt={4}
            mb={4}
            fontSize={30}
            fontWeight={"600"}
            color={"primary"}
            align="center"
          >
            {categoryName
              ? `${categoryName}  Services`.replace(/\b\w/g, (char) =>
                  char.toUpperCase()
                )
              : t("All Services")}
          </Text>
          <Text mt={4} mb={4}>
          {categoryDescription}
        </Text>
          <ViewCards
            route={ApiRoutes?.listServices}
            categoryId={categoryId}
            storeId={storeId}
            tagId={tagId}
            renderItem={(item) => <ServiceCard width={{base:'100%' , md:'90%'}} service={item} />}
            // shopId={shopId}
            // params={ shopId?{shop:shopId}: { categoryId: categoryId === "All" ? {} : categoryId }}
          />
        </Flex>
      </Flex>
    </Flex>
    // <Box>
    //   <Text fontSize="xl" fontWeight="bold" mb={4}>
    //     All Services
    //   </Text>
    //   <Tabs variant="enclosed" size={tabSize}>
    //     <TabList>
    //       {ServicesCategoryData?.result?.map((item: any) => (
    //         <Tab
    //           key={item._id}
    //           onClick={() => {
    //             setCategoryServiceId(item?._id);
    //             allServicesInCategory(item?._id);
    //           }}
    //           color={categoryServiceId === item._id ? "primary" : "gray"}
    //         >
    //           {item?.[`name${lang}`]}
    //         </Tab>
    //       ))}
    //     </TabList>
    //   </Tabs>

    //   <Flex wrap={"wrap"} justifyContent={"flex-start"} px={"16px"} py={"40px"}>
    //     {allServicesData?.result?.map((service: any,index:number) => {
    //       return (
    //         <Box style={{ marginLeft: index === 0 ? 0 : 10 }} mb={"16px"}>
    //           <ServiceCard service={service} />
    //         </Box>
    //       );
    //     })}
    //   </Flex>
    // </Box>
  );
};

export default AllServices;
