import { Box, Flex, Heading, Text , } from "@chakra-ui/layout";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import usePostData from "../../../../../api/usePostData";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../../../redux/reducerSlices/Auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { FormLabel, InputGroup, InputRightElement } from "@chakra-ui/react";


interface LoginFormValues {
  email: string;
  password: string;
}

const initialValues: LoginFormValues = {
  email: "",
  password: "",
};
interface loginFormProps {
  switchToSignUp:any
}
const LoginForm: React.FC<loginFormProps> = ({switchToSignUp}) => {
  const { t } = useTranslation();
  const [mobile] = useIsMobile();
  const { postData, loading } = usePostData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const primary = "#88050D";
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Required*")),
      password: Yup.mixed().required(t("Required*")),
    }),

    onSubmit: async (values: LoginFormValues) => {
      const res = await postData({
        route: "login",
        data: values,
        successMsg: t("you successfully logged in"),
      });

      if (res?.error) {
        if (res?.data) {
          const user = res?.data?.result;
          dispatch(
            setAuth({
              token: res?.data?.token,
              authInfo: res?.data?.result,
              authData: jwtDecode(res?.data?.token),
            })
          );
          if (!user?.isEmailVerified && user) {
            navigate("/email-verification" , {state:{type:'login'}});
          }else if (!user?.isPhoneVerified && user) {
            navigate("/phone-verification");
          }

        }
      } else {
        const user = { ...res?.result, token: res?.token };
        if (user?.isPhoneVerified && user?.isActive) {
          localStorage.setItem("authInfo", JSON.stringify(res?.result));
          localStorage.setItem("token", res?.token);
          dispatch(
            setAuth({
              token: res?.token,
              authInfo: res?.result,
              authData: jwtDecode(res?.token),
            })
          );
          navigate("/");
        }
      }
    },
  });

  return (
    <>
      <Flex
        direction={"column"}
        // pr="4"
         w={mobile ? "100%" : "100%"}
        
      >

        <form onSubmit={formik.handleSubmit}>
          <Flex w={"100%"} direction="column" gap=".9rem">
            <Flex direction="column" gap=".3rem" >
              <FormLabel
                htmlFor="email"
                style={{ color: "#333333", fontSize: "14px"}}
              >
                {t("Email")}
              </FormLabel>
              <Input
                focusBorderColor={primary}
                placeholder={t("Enter your Email here")}
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.email}
                bg="#FDF8E9"
              />
              {formik.touched.email && formik.errors.email ? (
                <Text color="#88060D" textAlign="left">
                  {formik.errors.email}</Text>
              ) : null}
            </Flex>

            <Flex direction="column" gap=".3rem">
              <FormLabel
                htmlFor="speed"
                style={{ color: "#333333", fontSize: "14px" }}
              >
                {t("Password")}
              </FormLabel>
              <InputGroup>
                <Input
                  focusBorderColor={primary}
                  placeholder={t("Enter your Password here")}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.password}
                  bg="#FDF8E9"
                />
                <InputRightElement width="3rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                    bgColor={"#88060D"}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formik.touched.password && formik.errors.password ? (
                                <Text color="#88060D" textAlign="left">
                  {formik.errors.password}</Text>
              ) : null}
            </Flex>
            <Flex justify="" mt=".3rem">
              <Link to="/forget-password">
                <Text fontSize={14} color="primary" textDecorationLine="underline">
                  {t("forgot password?")}
                </Text>
              </Link>
            </Flex>
          </Flex>
          <Flex gap="sm" mt="1rem" mb={2} justify="">
            <Button fontWeight={"bold"} variant="secondary" type="submit" w="100%">
              {t("Sign In")}
            </Button>
          </Flex>
        </form>

        <Text textAlign={"center"} color="#333333">
         {' '} {t('Don’t have an account?')}{' '}
        
            <Text cursor={"pointer"} onClick={switchToSignUp} ml={2} display="inline" color={"primary"} fontWeight="bold">
              {t("Sign Up")}
            </Text>
        
        </Text>
      </Flex>
    </>
  );
};
export default LoginForm;
