import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { logoAuth } from "../../../assets/images/index";
import useIsMobile from "../../../hooks/use-is-mobile";

import { Img } from "@chakra-ui/react";
import SocialLogin from "../Login/components/SocialLogin";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import { useTranslation } from "react-i18next";


const Register = ({switchToLogin}:{switchToLogin:any}) => {
  const [mobile] = useIsMobile();
  const {t} = useTranslation()

  return (
    <Box   >
      <Flex justify="center" align="center" my="1rem">
        <Box   w="100%">
          <Flex
            w={"100%"}
            justify="space-between"
            align="flex-start"
            direction={mobile ? "column" : "row"}
            gap="md"        
          >
            <RegisterForm switchToLogin={switchToLogin} />
            <Box mt={"4.4rem"} w={mobile ? "100%" : "35%"}>
              {/* <Text color='#586870' mb={2}>{t("or Sign In with")}</Text> */}
              {/* <SocialLogin /> */}
              <Img src={logoAuth} alt="logo" />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Register;
