import React from 'react';
import { Box, Flex ,Text} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

interface RatingProps {
  rating: number;
  maxRating?: number;
  reviewCount?: number;
  color?: string;
}

const Rating: React.FC<RatingProps> = ({ color,rating, maxRating = 5,reviewCount }) => {
  return (
    <Flex align="center" m={"4px"}>
        <StarIcon  color={'yellow.400'} />

      {/* {Array.from({ length: maxRating }).map((_, index) => (
        <StarIcon key={index} color={index < Math.round(rating) ? 'yellow.400' : 'gray.300'} />
      ))} */}
      {/* <Box ml="2" fontSize={"x-small"} color={color}>{rating }</Box> */}
      <Text color={color||'balck'} ml={1} fontSize={"2xs"}>{rating }{reviewCount !== undefined && `(${reviewCount})`}</Text>

    </Flex>
  );
};

export default Rating;
