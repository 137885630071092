import { ResponsiveValue, Skeleton } from '@chakra-ui/react';

const AppSkeleton = ({height , mt , width , borderRadius}:{borderRadius?:string | number,height: ResponsiveValue<number | (string & {})>  , mt?:number|string , width?: ResponsiveValue<number | (string & {})>}) => {
    return (
        <Skeleton
            startColor='rgba(255, 253, 239, 1)' endColor='#e5dfd5'
            height={height}
            mt={mt}
            width={width}
            borderRadius={borderRadius}
        />
    );
}

export default AppSkeleton;