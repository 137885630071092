import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import { lang } from "../../utils/utilsFunctions";
import StoreCard from "../../components/StoreCard";
import CategoriesSidebar from "../../components/CategoriesSidebar";
import ViewCards from "../../components/ViewCards";
import Banners from "../../components/Banners";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterDrawer from "../../components/FilterDrawer";

interface Store {
  id: number;
  name: string;
  // Add more properties as needed
}

const AllStores: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const {t} = useTranslation()
  const categoryName = location.state?.[`categoryName${lang}`];
  const categoryDescription = location.state?.[`categoryDescription${lang}`];
  const [categoryId, setCategoryId] = useState<any>(category);
  return (
    <Flex justify={"center"} py={16}>
      <Flex justifyContent={"center"} direction={"column"} width={"75%"}>
        <Text
          mt={4}
          mb={4}
          fontSize={30}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {categoryName
            ?  `${t('Shop')}: ${categoryName} `.replace(/\b\w/g, (char) =>
              char.toUpperCase()
            )
            :t( "All Shops")}
        </Text>

        <Flex alignItems={"center"} width={"100%"}>
          <FilterDrawer>
          <CategoriesSidebar
            title="Categories"
            customHeight={330}
            route={ApiRoutes?.listCategories}
            params={{ type: "shop" }}
            setItemId={setCategoryId}
            incomingSelectedId={category || ""}
          />
          </FilterDrawer>
          <Box zIndex={0} width={{base:'100%' , md:"76%"}}  >
            {categoryDescription && <Text mt={4} mb={4}>
              {categoryDescription}
            </Text>}
            <Box bgColor={'#eee'}>
              <Banners height={"30vh"} route={ApiRoutes?.listFeaturedShops} />
            </Box>
          </Box>
        </Flex>

        <ViewCards
          route={ApiRoutes?.listShops}
          categoryId={categoryId}
          renderItem={(item) => <StoreCard store={item} />}
        />
      </Flex>
    </Flex>
  );
};

export default AllStores;
