import React from "react";
import { Field } from "formik";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { console_log, lang } from "../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import { MdArrowDropDown } from "react-icons/md";

interface Props {
  name: string;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  description?: boolean;
  errorMsg?: string;
  touched?: boolean;
  textarea?: boolean;
  data?: any;
  style?: any;
  value?: any;
  setFieldValue?: any;
  bgColor?: string;
  visibility?: boolean;
  onchange?: (event: any) => void;
  icon?:JSX.Element
}

export default function AppField({
  bgColor,
  textarea,
  touched,
  errorMsg,
  description,
  disabled,
  name,
  title,
  placeholder,
  type,
  required,
  data,
  style,
  value,
  setFieldValue,
  visibility,
  icon,
  onchange,
}: Props) {
  const { t } = useTranslation();

  return (
    <Field name={name}>
      {({ field, form }: any) => {
        const error = form.errors[name] || errorMsg;
        const isTouched = form.touched[name] || touched;
        if (name === "packages.0.originalPrice") {
          // console_log(form.errors?.['packages']?.[0]?.['originalPrice'] )
          console_log(form.errors?.[`packages.${0}.quantity`], "field error");
        }
        if (textarea) {
          return (
            <FormControl isInvalid={!!isTouched && !!error}>
              <FormLabel
                mb={1}
                mt={4}
                color={"#333"}
                fontWeight={400}
                htmlFor={name}
                style={style}
              >
                {t(`${title}`)}
              </FormLabel>
              <Textarea
                {...field}
                id={name}
                placeholder={t(`${placeholder || title}`)}
                {...field}
                autoComplete={`no-${name}-${Math.random()}`}
                variant="outline" // Apply the 'outline' variant here
                borderColor="#8A7475"
                boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                // backgroundColor={'#F9FCF5'}
                _focus={{
                  borderColor: "#F9FCF5",
                  // boxShadow
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                h={"50px"}
                disabled={disabled}
                type={type}
                required={required}
                bgColor={bgColor || "#F9FCF5"}
                height={"15vh"}
              />
              <FormErrorMessage>
                {isTouched && error ? (error as React.ReactChild) : null}
              </FormErrorMessage>
            </FormControl>
          );
        }

        return (
          <FormControl isInvalid={!!isTouched && !!error}>
            <FormLabel
              mb={1}
              mt={2}
              color={"#4C5975"}
              fontWeight={500}
              fontSize={"16px"}
              htmlFor={name}
            >
              {t(`${title || ""}`)}
            </FormLabel>
          
            {type !== "select" ? (
                <InputGroup>
            {icon}
              <Input
                visibility={visibility || true}
                {...field}
                id={name}
                placeholder={t(`${placeholder || title}`)}
                {...field}
                autoComplete={`no-${name}-${Math.random()}`}
                variant="outline" // Apply the 'outline' variant here
                boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                backgroundColor={"rgb(229 229 229 / 41%)"}
                _focus={{
                  borderColor: "#F9FCF5",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                h={"50px"}
                disabled={disabled}
                type={type}
                required={required}
                bgColor={bgColor || "#F9FCF5"}
                onChange={(event) => {
                  let trimmedText = event.target.value.trimStart(); // Trim leading and trailing spaces
                  trimmedText = trimmedText.replace(/\s+/g, " "); // Replace multiple spaces with single space
                  trimmedText = trimmedText.replace(/[+*]+/g, ""); // Remove special characters
                  if (trimmedText.startsWith(" ")) {
                    trimmedText = trimmedText.slice(1);
                  }
                  form.setFieldValue(name, trimmedText);
                }}
              />
            </InputGroup>
            ) : (
              <Select
                id={name}
                {...field}
                // placeholder={t(`${placeholder || title}`)}
                icon={
                  <Flex>
                    <MdArrowDropDown style={{ float: "left" }} />
                  </Flex>
                }
                // value={value}
                boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                backgroundColor={"rgb(229 229 229 / 41%)"}
                _focus={{
                  borderColor: "#F9FCF5",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                px={"10px"}
                mx={"-10px"}
                width={"calc(100% + 20px )"}
                h={"50px"}
                variant="outline"
                // onChange={(e) => {
                //   setFieldValue("city", e.target.value);
                // }}
                placeholder={t('Select an emirate')}
                onChange={onchange}
              >
                {data?.map((emirate: any, index: any) => (
                  <option key={index} value={emirate?._id || emirate}>
                    {emirate?.[`name${lang}`] || emirate}
                  </option>
                ))}
              </Select>
            )}

            <FormErrorMessage>
              {isTouched && error ? (error as React.ReactChild) : null}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
}
