import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from "@chakra-ui/react"; // Import Chakra UI components as needed
import { useLocation } from "react-router-dom"; // Import useParams to access route parameters

import ApiRoutes from "../../api/services/api-routes"; // Import API routes
import TrendingProductCard from "../../components/TrendingProductCard";
import CategoriesSidebar from "../../components/CategoriesSidebar";
import PriceRangeSlider from "../../components/PriceRangeSlider";
import ViewCards from "../../components/ViewCards";
import { useTranslation } from "react-i18next";
import FilterDrawer from "../../components/FilterDrawer";
const AllProducts: React.FC = () => {
  // const  { shopId } = useParams<{ shopId: string }>(); // Access the 'category' parameter from the URL
  const location = useLocation();
  const { pathname } = location;
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get("shopId");
  const { t } = useTranslation();
  const rankParam = new URLSearchParams(location.search).get("rank");
  const sortOrder = new URLSearchParams(location.search).get("sortOrder");
  const discountValue = new URLSearchParams(location.search).get(
    "discountValue"
  );

  const sortByDate = new URLSearchParams(location.search).get("sortByDate");
  const title = queryParams.get("title") || "All Products";

  const category = queryParams.get("category");

  const categoryName = location.state?.categoryName;
  const categoryDescription = location.state?.categoryDescription;

  const [categoryId, setCategoryId] = useState<any>(category);
  const [storeId, setStoreId] = useState<any>(shopId);
  const [priceFrom, setPriceFrom] = useState(0);
  const [priceTo, setPriceTo] = useState(100);
  const [rangeValue, setRangeValue] = useState([0, priceTo]);

  // useEffect(() => {
  //   // Update rangeValue to display `[0, priceTo]` when `priceTo` changes
  //   setRangeValue([0, priceTo]);
  // }, [priceTo]);

  const [tagId, setTagId] = useState();

  return (
    <Flex justify={"center"} py={16}>
      <Flex justifyContent={"center"} direction={"row"} width={"75%"}>
        <FilterDrawer>
          <Flex direction={"column"}>
            {pathname !== "/all-variations" && (
              <Box mb={4}>
                <CategoriesSidebar
                  route={ApiRoutes?.listCategories}
                  params={{ type: "product" }}
                  setItemId={setCategoryId}
                  title="Categories"
                  customHeight={364}
                  incomingSelectedId={category || ""}
                />
              </Box>
            )}
            <Box mb={4}>
              <CategoriesSidebar
                route={ApiRoutes?.listShops}
                // params={{ type: "product" }}
                setItemId={setStoreId}
                title="Shops"
                customHeight={294}
                incomingSelectedId={storeId}
              />
            </Box>
            <Box mb={4}>
              <PriceRangeSlider
                priceFrom={priceFrom}
                priceTo={priceTo}
                setPriceFrom={setPriceFrom}
                setPriceTo={setPriceTo}
                rangeValue={rangeValue}
                setRangeValue={setRangeValue}
              />
            </Box>
            {pathname !== "/all-variations" && (
              <Box mb={4}>
                <CategoriesSidebar
                  route={ApiRoutes?.listTags}
                  // params={{ type: "product" }}
                  setItemId={setTagId}
                  title="Tags"
                  customHeight={294}
                />
              </Box>
            )}
          </Flex>
        </FilterDrawer>

        <Flex minWidth={"75%"} direction={"column"}>
          <Text
            mt={4}
            mb={4}
            fontSize={30}
            fontWeight={"600"}
            color={"primary"}
            align="center"
          >
            {categoryName
              ? `${categoryName} Products`.replace(/\b\w/g, (char) =>
                  char.toUpperCase()
                )
              : rankParam
              ? t("Trending Products")
              : sortOrder
              ? t("Recently Added")
              : discountValue
              ? t("Top Deals")
              : t("All Products")}
          </Text>
          <Text mt={4} mb={4}>
            {categoryDescription}
          </Text>
          <ViewCards
            route={
              rankParam ||
              priceFrom > 0 ||
              priceFrom < 0 ||
              rangeValue[0] > 0 ||
              rangeValue[1] < 100 ||
              priceTo < 100 ||
              priceTo > 100
                ? ApiRoutes?.listVariations
                : ApiRoutes?.listProducts
            }
            categoryId={categoryId}
            storeId={storeId}
            priceFrom={priceFrom}
            priceTo={priceTo}
            rankParam={rankParam}
            sortOrder={sortOrder}
            discountValue={discountValue}
            tagId={tagId}
            renderItem={
              rankParam
                ? (item) =>
                    item?.product?.isVerified &&
                    !item?.product?.isDeleted &&
                    item?.product?.isActive && (
                      <TrendingProductCard
                        product={item}
                        cardType="variation"
                      />
                    )
                : (item) => (
                    <TrendingProductCard
                      product={item}
                      // cardType={rankParam ? "variation" : "product"}
                    />
                  )
            }
            shopId={shopId}
            sortByDate={sortByDate}
            // params={ shopId?{shop:shopId}: { categoryId: categoryId === "All" ? {} : categoryId }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AllProducts;
