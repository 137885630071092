import React from "react";
import { Flex, Box, Avatar, Text, Image, Button } from "@chakra-ui/react";

import { arrowDown, userIcon } from "../../../../assets/images";
import { useSelector } from "react-redux";

import { CiLogin } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";

import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { user } from "../../../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/reducerSlices/Auth/authSlice";
import useLoginAsGuest from "../../../../hooks/useLoginAsGuest";
import { useTranslation } from "react-i18next";

export default function Badge() {
  const { t } = useTranslation();
  const { authInfo } = useSelector<RootState, any | undefined>(
    (state) => state?.auth
  );

  const { loginAsGuest } = useLoginAsGuest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btn_style = {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    color: "black",
    padding: "3px",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "-4px",
    height: "10px",
    "&:hover": {
      backgroundColor: "#58585826",
      color: "black",
    },
  };
  return (
    <Flex alignItems={"center"}>
      <Image
        h={{ base: 5, md: 7 }}
        width={{ base: 5, md: 7 }}
        src={authInfo?.image?.Location || user}
        borderRadius={"30px"}
      />
      <Box ml="2">
        <Text
          maxWidth={"100%"}
          noOfLines={1}
          fontSize="2xs"
          color={"rgba(0, 0, 0, 0.50)"}
        >
          {t("Ahlan")}
          <span style={{ fontFamily: "JosefinSansRegular" }}>
            {authInfo?.name}
          </span>
        </Text>

        <Flex>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  css={btn_style}
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text fontSize={"xs"}>{t("My Account")}</Text>
                </MenuButton>
                <MenuList zIndex={3} paddingTop={"0"} paddingBottom={"0"}>
                  {authInfo?._id !== "guest" && (
                    <MenuItem
                      padding={"10px"}
                      onClick={() => {
                        navigate("/profile");
                      }}
                      _hover={{
                        color: "primary",
                      }}
                    >
                      <Flex alignItems={"center"} gap={"5px"}>
                        <Text>{t("Settings")}</Text>
                        <IoSettingsOutline />
                      </Flex>
                    </MenuItem>
                  )}
                  <MenuItem
                    // className={style.menu_item_logout}
                    padding={"10px"}
                    _hover={{
                      // backgroundColor: "primary",
                      color: "primary",
                    }}
                    // color={"primary"}
                    // backgroundColor={"#fff"}
                    onClick={() => {
                      // logout(navigate);
                      dispatch(logout());
                      //next line cause double welcome message
                      // loginAsGuest();
                    }}
                  >
                    <Flex
                      w={"100%"}
                      // color={"primary"}
                      // backgroundColor={"#fff"}
                      // _hover={{ color: "#FFF", backgroundColor: "primary" }}
                      alignItems={"center"}
                      gap={"5px"}
                    >
                      <Text
                        // color={"primary"}
                        // backgroundColor={"transparent"}
                        // _hover={{ color: "#FFF", backgroundColor: "transparent" }}
                      >
                        {t("LogOut")}
                      </Text>
                      <CiLogin />
                    </Flex>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Box>
    </Flex>
  );
}
