import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Toast,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import WishlistCard from "../../components/WishlistCard";

import { useTranslation } from "react-i18next";
import { removeFromWishlistAsync } from "../../redux/reducerSlices/Wishlist/wishlistSlice";
import { lang, showToastMessage } from "../../utils/utilsFunctions";
import EmptyData from "../../components/EmptyData";
import { expand } from "../../assets/images";
import Rating from "../../components/Rating";
import { clearCompareList } from "../../redux/reducerSlices/CompareList/compareListSlice";
import NoDataFound from "../../components/NoDataFound";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../../i18n";

interface CompareListItem {
  id: string;
}

interface CompareListProps {
  items?: CompareListItem[];
}

const CompareList: React.FC<CompareListProps> = ({ items }) => {
  const compareListItems = useAppSelector(
    (state) => state?.compareList?.productIds
  );

  const { firstProductId, secondProductId } = useParams<{
    firstProductId: string;
    secondProductId: string;
  }>();

  const { t } = useTranslation();

  if (compareListItems?.length === 0) {
    return (
      <Flex
        mt={24}
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
      >
        <Text fontWeight={"600"} fontSize={20} color={"primary"}>
          {t("Compare Products")}
        </Text>
        <NoDataFound />
      </Flex>
    );
  }

  if (compareListItems?.length < 1 && !firstProductId) {
    return (
      <Flex
        mt={24}
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
      >
        <Text fontWeight={"600"} fontSize={20} color={"primary"}>
          {t("Compare Products")}
        </Text>
        <NoDataFound />
      </Flex>
    );
  }

  return (
    <CompareListContent
      compareListItems={
        compareListItems.length === 2
          ? compareListItems
          : [firstProductId, secondProductId]
      }
      firstProductId={firstProductId}
      secondProductId={secondProductId}
    />
  );
};

const CompareListContent: React.FC<{
  compareListItems: any[];
  firstProductId: any;
  secondProductId: any;
}> = ({ compareListItems, firstProductId, secondProductId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    data: firstProductData,
    loading,
    setData,
    getData: getFirstProductData,
  } = useGetData({
    route: ApiRoutes?.getVariationById,
    params: {
      _id: compareListItems.length === 2 ? compareListItems[0] : firstProductId,
    },
  });

  const { data: secondProductData, getData: getSecondProductData } = useGetData(
    {
      route: ApiRoutes?.getVariationById,
      params: {
        _id:
          compareListItems.length === 2 ? compareListItems[1] : secondProductId,
      },
    }
  );

  const firstProduct = firstProductData?.result;
  const secondProduct = secondProductData?.result;

  if (compareListItems?.length === 1) {
    return (
      <>
        <Flex alignItems={"center"} direction={"column"}>
          <Text
            mt={20}
            mb={8}
            fontSize={"4xl"}
            fontWeight={"600"}
            color={"primary"}
            align="center"
          >
            {t("Compare")}
          </Text>
          <Flex direction={"column"} width={"80%"} alignItems={"center"}>
            <Flex justify={"center"}>
              <Image width={250} src={firstProduct?.images?.[0]?.Location} />
            </Flex>
            <Flex justify={"center"} width={"100%"}>
              <Table variant="simple" mb={40} style={{ width: "80%" }}>
                <br />
                <br />
                <Flex width={"100%"} justify={"center"}>
                  <Tbody>
                    <Tr>
                      <Td color={"primary"} textAlign={"center"}>
                        {t("Product")}
                      </Td>
                      <Td textAlign={"center"}>
                        {firstProduct?.product?.[`name${lang}`]}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td color={"primary"} textAlign={"center"}>
                        {t("Variation")}
                      </Td>
                      <Td textAlign={"center"}>
                        {firstProduct?.[`description${lang}`]}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td color={"primary"} textAlign={"center"}>
                        {t("Price")}
                      </Td>
                      <Td textAlign={"center"}>
                        {firstProduct?.defaultPackage?.price}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td color={"primary"} textAlign={"center"}>
                        {t("Description")}
                      </Td>
                      <Td textAlign={"center"}>
                        {firstProduct?.product?.[`description${lang}`]}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td color={"primary"} textAlign={"center"}>
                        {t("Rate")}
                      </Td>
                      <Td>
                        <Flex justify={"center"}>
                          <Rating rating={firstProduct?.product?.rating} />
                        </Flex>
                      </Td>
                    </Tr>

                    <Flex m={3} justify={"center"}>
                      <Button
                        onClick={() => {
                          dispatch(clearCompareList());
                          navigate("/all-products");
                          toast({
                            position: "top-right",
                            description: t("Compare List Cleared"),
                            status: "success",
                            isClosable: true,
                          });
                        }}
                        bg={"primary"}
                        px={12}
                        py={6}
                        fontSize={"xl"}
                      >
                        {t("Clear")}
                      </Button>
                    </Flex>
                  </Tbody>
                </Flex>
              </Table>
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  }
  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
      >
        <Text
          mt={20}
          mb={8}
          fontSize={"4xl"}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {t("Compare")}
        </Text>
        <Flex direction={"row"} width={"75%"} justifyContent={"space-around"}>
          <Table variant="simple" mb={40} style={{ width: "75%" }}>
            <Thead style={{ marginBottom: 20 }}>
              <Tr>
                <Th>
                  <Flex justify={"center"}>
                    <Image
                      width={250}
                      src={firstProduct?.images?.[0]?.Location}
                    />
                  </Flex>
                </Th>
                <Th
                  fontFamily={
                    i18n.language === "en" ? "JosefinSansRegular" : "ArabicFont"
                  }
                  fontSize={"6xl"}
                  color={"primary"}
                  textAlign={"center"}
                  letterSpacing={0}
                >
                  {t("VS")}
                </Th>
                <Th>
                  <Flex justify={"center"}>
                    <Image
                      width={250}
                      src={secondProduct?.images?.[0]?.Location}
                    />
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <br />
            <br />
            <Tbody>
              <Tr>
                <Td width={500} textAlign={"center"}>
                  {firstProduct?.product?.[`name${lang}`]}
                </Td>
                <Td color={"primary"} textAlign={"center"}>
                  {t("Product")}
                </Td>
                <Td width={500} textAlign={"center"}>
                  {secondProduct?.product?.[`name${lang}`]}
                </Td>
              </Tr>
              <Tr>
                <Td textAlign={"center"}>
                  {firstProduct?.[`description${lang}`]}
                </Td>
                <Td color={"primary"} textAlign={"center"}>
                  {t("Variation")}
                </Td>
                <Td textAlign={"center"}>
                  {secondProduct?.[`description${lang}`]}
                </Td>
              </Tr>
              <Tr>
                <Td textAlign={"center"}>
                  {firstProduct?.defaultPackage?.price} {t("AED")}
                </Td>
                <Td color={"primary"} textAlign={"center"}>
                  {t("Price")}
                </Td>
                <Td textAlign={"center"}>
                  {secondProduct?.defaultPackage?.price} {t("AED")}
                </Td>
              </Tr>
              <Tr>
                <Td textAlign={"center"}>
                  {firstProduct?.product?.[`description${lang}`]}
                </Td>
                <Td color={"primary"} textAlign={"center"}>
                  {t("Description")}
                </Td>
                <Td textAlign={"center"}>
                  {secondProduct?.product?.[`description${lang}`]}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Flex justify={"center"}>
                    <Rating rating={firstProduct?.product?.rating} />
                  </Flex>
                </Td>
                <Td color={"primary"} textAlign={"center"}>
                  {t("Rate")}
                </Td>
                <Td>
                  <Flex justify={"center"}>
                    <Rating rating={secondProduct?.product?.rating} />
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td></Td>
                <Td>
                  <Button
                    onClick={() => {
                      dispatch(clearCompareList());
                      navigate("/all-products");
                      toast({
                        position: "top-right",
                        description: t("Compare List Cleared"),
                        status: "success",
                        isClosable: true,
                      });
                    }}
                    bg={"primary"}
                    px={12}
                    py={6}
                    fontSize={"xl"}
                  >
                    {t("Clear")}
                  </Button>
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </>
  );
};

export default CompareList;
