import  React from 'react';
import { useDispatch } from 'react-redux';
import { setAuth } from '../redux/reducerSlices/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import usePostData from '../api/usePostData';

const useLogout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch(setAuth({ token: null, authInfo: {}, authData:{} }))
        localStorage.removeItem("token")
        localStorage.removeItem("authInfo")
        navigate("/auth-tabs")
    }
    return (
       {
        handleLogout
       }
    );
}

export default useLogout;