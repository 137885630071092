import React from "react";
import Steps from "./Steps";
import { OrderProvider } from "./Context";
const CheckoutProcessRoot = () => {
  return (
    <OrderProvider>
        <Steps/>
    </OrderProvider>
  );
};
export default CheckoutProcessRoot;
