import { useDispatch, useSelector } from "react-redux";
import { Box, Img, Text, Flex, Spinner, Image, IconButton } from "@chakra-ui/react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import style from "./style.module.css";
import ChatBottom from "./ChatBottom";

import moment from "moment";

import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import useGetData from "../../../api/useGetData";
import usePostData from "../../../api/usePostData";
import SocketClass from "../../../utils/Socket";
import { setChatMenuData } from "../../../redux/reducerSlices/ChatMenu/chatMenu";
import { console_log } from "../../../utils/utilsFunctions";
import RipplesButton from "../../RipplesButton";
import AppSkeleton from "../../../common/AppSkeleton";
import { person2 } from "../../../assets/images/index";
import { useSocket } from "../../../Socket/SocketProvider";
import photo from "../../../assets/images/imgs/Photo.svg";
import { colors } from "react-select/dist/declarations/src/theme";
import { CloseIcon } from "@chakra-ui/icons";
import useLang from "../../../hooks/useLang";
export default function ChatBox() {
  const {isAr} = useLang()
  const {
    chatMenu,
    list,
    chatType,
    chatBox,
    roomId,
    withAdmin,
    sellerId,
    fastBox,
  } = useSelector<RootState, any | undefined>(
    (state) => state?.chatMenu?.chatMenuData
  );

  const { _id } = useSelector<RootState, any | undefined>(
    (state) => state?.auth?.authData
  );
  const { data, setData, getData, loading } = useGetData({
    route: `rooms/get`,
    notLoadData: !roomId,
    params: { customer: _id, _id: roomId },
  });
  const { postData, loading: loadingSendMessage } = usePostData();
  const [loadingSocketImage, setLoadingSocketImage] = useState(false);
  const [loadingSocketText, setLoadingSocketText] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>();
  const dispatch = useDispatch();
  // const socket = SocketClass.getInstance({ userId: _id });
  const socket = useSocket();
  const joinRoom = async () => {
    console.log(socket, "===joinRoom====", socket?.connected, "===socket====");
    if (!roomId || fastBox === true) {

      if (withAdmin && chatType !== "customers") {
        socket?.emit(
          "joinRoom",
          { customer: _id, withAdmin: true },
          (res: any) => {
            setData(res);
            getData({
              route: `rooms/get`,
              params: { _id: res?.result?._id, customer: _id },
            });

            dispatch(
              setChatMenuData({
                chatMenuData: {
                  chatMenu: true,
                  chatBox: true,
                  chatType: "support",
                  list: true,
                  roomId: res?.result?._id,
                },
              })
            );

            console.log("Acknowledgment from server:with Admin", res);
          }
        );
      } else {
        console_log("joinRoom with seller");
        socket?.emit(
          "joinRoom",
          { customer: _id, seller: sellerId },
          (res: any) => {
            setData(res);
            getData({
              route: `rooms/get`,
              params: { _id: res?.result?._id, customer: _id },
            });
            dispatch(
              setChatMenuData({
                chatMenuData: {
                  roomId: res?.result?._id,
                },
              })
            );
            console_log({ res });
         
          }
        );
        // dispatch(
        //   setChatMenuData({
        //     chatMenuData: {
        //       chatMenu: true,
        //       chatBox: true,
        //       chatType: "customers",
        //       list: true,
        //       sellerId: sellerId,
        //     },
        //   })
        // );
      }
    }
  };

  useEffect(() => {
    joinRoom();
  }, [sellerId, roomId, fastBox]);

  const sendImage = async ({ image, text }: { image: any; text?: string }) => {
    setLoadingSocketText(true);

    const formData = new FormData();
    formData.append("file", image);
    let res = await postData({
      route: `rooms/file?_id=${roomId}`,
      data: formData,
    });
    const fileData = res?.result;
    if (fileData) {
      const message = text
        ? {
            roomId: roomId,
            message: {
              customer: _id,
              timestamp: new Date().toISOString(),
              file: fileData,
              text: text,
            },
          }
        : {
            roomId: roomId,
            message: {
              customer: _id,
              timestamp: new Date().toISOString(),
              file: fileData,
              //text: text,
            },
          };

      socket?.emit("sendMessage", message, (res: any) => {
        setLoadingSocketImage(false);
        // data?.result?.seller?.image?.Location
        setData({
          ...res,
          result: { ...res?.result, seller: data?.result?.seller },
        });
        console_log({ res }, "sendMessage");
        setLoadingSocketText(false);
      });
    }
    console_log(res, "sendImage");
  };

  const onSendMsg = (msg: any) => {
    if (imagePreview) {
      sendImage({ image: imagePreview, text: msg });
      setImagePreview("");
      return;
    }
    if (!msg) {
      return;
    }
    setLoadingSocketText(true);

    try {
      const message = {
        roomId: roomId,
        message: {
          customer: _id,
          timestamp: new Date().toISOString(),
          text: msg,
        },
      };
      socket?.emit("sendMessage", message, (res: any) => {
        setLoadingSocketText(false);
        // setData(res)
        console_log({ res }, "sendMessage");
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    socket?.on("newMessage", (res: any) => {

      setData((prev: any) => ({
        ...prev,
        result: {
          ...prev?.result,
          messages: [res.result, ...prev?.result?.messages],
        },
      }));
    });
  }, []);

  const onUploadImage = async (e: any) => {
    const image = e.target.files?.[0];
    setImagePreview(image);
  };


  return (
    <Box className={style.Chat_Box}>
      {chatType !== "support" && (
        <Flex className={style.Chat_Box_header}>
          {/* <RipplesButton text={<IoChevronBackCircleOutline/>} onClick={()=>{setList(chatType === "support" ? false : true); setChatBox(false); setChatMenu(chatType === "support" ? false : true)}} className={`${style.back_arrow}`}/> */}
          <RipplesButton
            text={<IoChevronBackCircleOutline style={{transform:!isAr ? '': 'rotate(180deg)'}} />}
            onClick={() => {
              dispatch(
                setChatMenuData({
                  chatMenuData: {
                    chatBox: false,
                    chatMenu: chatType === "support" ? false : true,
                    list: chatType === "support" ? false : true,
                  },
                })
              );
            }}
            className={`${style.back_arrow}`}
          />
          {loading ? (
            <AppSkeleton height={"35px"} width={"35px"} borderRadius={"50px"} />
          ) : (
            <Img
              width={"35px"}
              src={
                data?.result?.seller?.image
                  ? data?.result?.seller?.image?.Location
                  : person2
              }
              className={style.Chat_Box_header_image}
            />
          )}
          {loading ? (
            <AppSkeleton
              height={"25px"}
              mt={"6px"}
              width={"200px"}
              borderRadius={"50px"}
            />
          ) : (
            <Text className={style.Chat_Box_header_name}>
              {data?.result?.seller?.userName}
            </Text>
          )}
        </Flex>
      )}
      <Box
        pb={17}
        className={style.Chat_Body}
        maxHeight={chatType === "customers" ? "382px" : "435px"}
        minHeight={chatType === "customers" ? "382px" : "435px"}
      >
        {imagePreview && (
          <Box
            position={"fixed"}
            top={"18%"}
            zIndex={3}
            flex={1}
            height={"73%"}
            width={"100%"}
            bgColor="rgba(0,0,0,0.8)"
          >
            <Flex position={"fixed"} right={"2%"} top={"20%"}>
            <IconButton color={"#FFF"} variant={"outline"} aria-label="image-preview" onClick={()=>setImagePreview(null)} icon={<CloseIcon/>} />

            </Flex>
            <Image
              src={imagePreview ? URL.createObjectURL(imagePreview) : ""}
              height={"100%"}
              width={"100%"}
            />
          </Box>
        )}

        {loading ? (
          <Spinner margin={"50% auto"} size="xl" />
        ) : (
          <>
            {data?.result?.messages.map((item: any, index: any) => (
              <Box width={"100%"}>
                <Box
                  className={`${style.message_li} ${
                    item.customer
                      ? style.message_li_right
                      : style.message_li_left
                  }`}
                >
                  <Box className={style.message_li_content}>
                    {item?.file && (
                      <Flex className={style.Text}>
                        {" "}
                        <Image
                          width={"100%"}
                          height={"100%"}
                          src={item.file.Location}
                        />
                      </Flex>
                    )}
                    {item?.text && (
                      <Flex className={style.Text}> {item.text} </Flex>
                    )}
                    <Text className={style.date}>
                      {moment(item?.timestamp).fromNow()}
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        )}
      </Box>
      <ChatBottom
        loading={loadingSocketImage || loadingSendMessage}
        loadingSendText={loadingSocketText}
        onUploadImage={onUploadImage}
        onSendMsg={onSendMsg}
      />
    </Box>
  );
}
