
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Select, Spinner, Text, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOrder } from "./Context";
import { ErrorMessage, Field, Formik, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import AppField from "../../../common/AppField";
import Axios, { END_POINT } from "../../../api/services/axios";
import { showMSG } from "../../../utils/utilsFunctions";
import MapView from "../../../components/MapView";
import { useTranslation } from "react-i18next";
import { addressDetailsSchema } from "../../../utils/ValiidationSchemas";

interface FormValues {
  country?: string;
  city?: any;
  street?: string;
  remarks?: string;
  cityCode?: any;
  location?:{lng:number , lat:number},
  cityData?:any
}



const   MyDetails = () => {
  const {t} = useTranslation()
  const { authInfo } = useSelector<RootState, any | undefined>((state) => state?.auth);
  const [citiesData, setCitiesData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { order, setOrder, handleNext } = useOrder();
  const formikRef = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const toast = useToast()
  const initialValues: FormValues = {
    country: "UAE",
    city: params.get("city") || "",
    street: params.get("street") || "",
    remarks: params.get("remarks") || "",
    cityCode: params.get("cityCode") || "",
    location:undefined,
    cityData:undefined
  };

  const handleChange =  async(event: React.ChangeEvent<HTMLSelectElement>, setFieldValue: (field: string, value: any) => void) => {
    const { name, value } = event.target;
    // return 
    const city = await JSON?.parse(value);
    const CityName = city?.CityName ||city?.name
    const CityCode = city?.CityCode ||  city?.code
    // const {CityName, CityCode} = city
    // return;
    setFieldValue('cityData', city)
    setFieldValue("city", CityName);
    setFieldValue("cityCode", CityCode);
    const updatedOrder = {
      ...order,
      details: {
        ...order.details,
        shippingAddress: {
          ...order.details.shippingAddress,
          address: params.get("isFood") && params.get("isFood") === "true"
            ? { ...order.details.shippingAddress.address, city: city }
            : { ...order.details.shippingAddress.address, city: city, cityCode: CityCode },
        },
      },
    };
    console.log({updatedOrder})
    // return;
    setOrder(updatedOrder);
  };

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (!values.city) {
      showMSG(toast, 'error', t("Please select an emirate"),);
      return
    }
    if (!values.location) {
      showMSG(toast, 'error', t("Please select a your location from the map"),);
      return
    }

    console.log({order})

    // return;
    const updatedOrder = {
      details: {
        customer: authInfo._id,
        shippingAddress: {
          location: {
            type: "Point",
            coordinates: [values?.location?.lng, values?.location?.lat] as [number, number],
          },
          address: params.get("isFood") && params.get("isFood") === "true"
            ? {
              country: "UAE",
              city: values.cityData || "",
              street: values.street || "",
              remarks: values.remarks || "",
            }
            : {
              country: "UAE",
              city: values?.cityData || "",
              street: values.street || "",
              remarks: values.remarks || "",
              cityCode: values.cityCode || "",
            },
        },
      },
      cost: {
        cartTotal: 0,
        taxesTotal: 0,
        shippingFeesTotal: 0,
      },
      paymentMethod: "",
    };

    setOrder(updatedOrder);
    params.set("city", values.city);
    params.set("street", updatedOrder.details.shippingAddress.address.street);
    params.set("remarks", updatedOrder.details.shippingAddress.address.remarks);
    params.set("cityCode", updatedOrder.details.shippingAddress.address.cityCode ||  updatedOrder.details.shippingAddress.address.city?.code) ;
    params.set("step", "1");
    // return;

    handleNext();
    navigate({ search: params.toString() }, { replace: true });
    actions.setSubmitting(false);
  };

  useEffect(() => {
    const fetchCitiesData = async () => {
      setLoading(true);
      try {
        if (params.get("isFood") && params.get("isFood") === "true") {
          const response = await Axios.get('https://ifast-shipperapi.dispatchex.com/api/CommonAPI/Cities', {
            params: { countryID: 1 },
          });
          setCitiesData(response?.data?.data);
        } else {
          const response = await Axios.get(`${END_POINT}/api/v1/mock/firstFlight/cities`, {
            params: { countryID: 1 },
          });
          setCitiesData(response?.data?.result);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCitiesData();
  }, []);


  return (
   <Flex flexDirection='column' alignItems='center' >
     <Box width={'50%'} >
      <Text
        cursor={"pointer"}
        fontWeight={"900"}
        color={"primary"}
      >
        {t('Add Address')}
      </Text>
      <Formik innerRef={formikRef} validationSchema={addressDetailsSchema(t)} validateOnMount={true} onSubmit={handleSubmit} initialValues={initialValues}>
        {({ setFieldValue, handleSubmit, values  , errors , touched}) => (
          <form noValidate={true} onSubmit={handleSubmit}>
            <Field name={"city"}>
            {({field, form}:any)=><>
            <Box position="relative" width="100%">
              {params.get("isFood") && params.get("isFood") === "true" ? (
                <Select  isInvalid={!!errors?.city && !!touched?.city} name="city" placeholder={t("Select an emirate")} onChange={(e) => handleChange(e, setFieldValue)} value={values?.cityData &&JSON.stringify(values?.cityData)}>
                  {citiesData.filter((item: any) => item?.name !== "Out OF Service Area")
                  .map((item: any, index: any) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {t(item?.name)}
                    </option>
                  ))}
                </Select>
              ) : (
                <>
                  <Select  isInvalid={!!errors?.city && !!touched?.city} name="city" placeholder={t("Select an emirate")} onChange={(e) => {
                    handleChange(e, setFieldValue)
                    // console.log( e.target?.value , 'value')
                  }} value={values?.cityData &&JSON.stringify(values?.cityData)}>
                    {citiesData.map((item: any, index: any) => (
                      <option key={index} value={JSON.stringify(item)}>
                        {t(item?.CityName)}
                      </option>
                    ))}
                  </Select>
                </>
              )}


              <Box color='red'>
                <ErrorMessage name='city'/>
              </Box>

              {loading && (
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="rgba(255, 255, 255, 0.8)" // Optional: semi-transparent background
                  zIndex="1"
                >
                  <Spinner size="sm" />
                </Box>
              )}
            </Box>
            </>}   
           </Field>
          
          
              

            <AppField
              name="street"
              type="text"
              title="Street"
              placeholder="Street"
              required={true}
              value={values.street || ""}
              onchange={(e) => handleChange(e, setFieldValue)}
            />
            <ErrorMessage
            name="street"
            />
            <AppField
              name="remarks"
              type="text"
              title={t("Remarks")}
              placeholder={t("Remarks")}
              required={true}
              value={values.remarks || ""}
              onchange={(e) => handleChange(e, setFieldValue)}
            />
            <MapView
            onChange={(value)=>{
              setFieldValue('location', value)
            }}
            defaultLocation={values?.location}

            />
            <Box textAlign={"end"} marginTop={"50px"}>
              <Button type="submit" >{t('Go Next')}</Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
   </Flex>
  );
};

export default MyDetails;
