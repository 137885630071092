import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)

const resources = {
  ar: {
    translation: {
      "View All": "اظهار الكل",
      "Stores near you": "المتاجر القريبة منك",
      "Sign In": "تسجيل الدخول",
      "Continue Shopping": "استمرار الشراء",
      "User Name": "اسم المستخدم",
      Password: "كلمة المرور",
      "Change Language": " تغيير اللغة",
      "forgot password ?": "نسيت كلمة المرور ؟  ",
      "Forgot password": "نسيت كلمة المرور ",
      "Don’t have account ?": "ليس لديك حساب ؟",
      "don't have an account?": "ليس لديك حساب؟",
      "Sign Up": "انشاء حساب جديد",
      "No Account ?": "ليس لديك حساب؟",
      "Phone Number": "رقم الهاتف",
      "Enter your Full Name here": "ادخل اسمك بالكامل هنا",
      "Enter your Email here": "ادخل بريدك الالكتروني هنا",
      "Enter your Password here": "ادخل الرقم السري هنا",
      Name: "الاسم ",
      "Sign up with Google": "تسجيل باستخدام جوجل ",
      "Sign up with Apple": "تسجيل باستخدام ابل ",
      "Sign up with Facebook": "تسجيل باستخدام فيسبوك ",
      Email: "بريد إلكتروني",
      "Confirm Password": "تأكيد كلمة المرور",
      "Personal information": "المعلومات الشخصية",
      Orders: "الطلبات",
      Wishlist: "المفضلة",
      Payment: "المدفوعات",
      "Language settings": "ضبط اللغة",
      "Login & Security": "تسجيل الدخول والأمن",
      Privacy: "الخصوصية",
      Support: "الدعم",
      Points: "النقاط",

      Street: "الشارع",
      Phone: "رقم الهاتف",
      Address: "العنوان",
      Finish: "ارسال",
      "Required*": "مطلوب*",
      "Invalid Email": "بريد الكتروني غير صالح",
      Login: "تسجيل دخول",
      Home: "الرئيسيه",
      Cart: "السلة",
      Profile: "حسابي",
      Chat: "الدردشة",
      Filter: "فلتر",
      "Language Settings": "اعدادات اللغة",
      "Choose your preferred language": "اختر لغتك المفضلة",
      "Change Photo": "تغيير الصورة",
      "Full Name": "الاسم بالكامل",
      Country: "البلد",
      State: "الولاية",
      City: "المدينة",
      Edit: "تعديل",
      Cancel: "الغاء",
      "Save Changes": "حفط التعديلات",
      "Edit Your Profile": "تعديل ملفك الشخصي",
      "First Name": "الاسم الاول",
      "Last Name": "الاسم الاخير",
      "Deliver to": "التوصيل إلى",
      "Current Location": "الموقع الحالي",
      "Explore Products": "استكشاف المنتجات",
      Review: "تقييم",
      "Trending Products": "المنتجات الرائجة",
      "Trending Services": "الخدمات الرائجة",
      "Request Service": "طلب الخدمة",
      "Forgot password?": "نسيت كلمة المرور؟",
      Register: "إنشاء حساب",
      "Don't have account?": "ليس لديك حساب؟",
      "You have registered successfully": "تم التسجيل بنجاح",
      "your session has expired": "عليك تسجيل الدخول مرة أخرى",
      "Your session is invalid": "عليك تسجيل الدخول مرة أخرى",
      "Welcome back!": "أهلاً بعودتك!",
      "Welcome to": "مرحبا في",
      Sona3: "صناع",
      "Search for homemade products": "البحث عن المنتجات المصنوعة يدوياً",
      "Select Categories": "اختر التصنيف",
      "Select Tags": "اختر الوسم",
      "Have an Account ?": "هل لديك حساب؟",
      Product: "منتج",
      Shop: "متجر",
      Service: "خدمة",
      Search: "البحث",
      Services: "الخدمات الخاصة",
      "Checkout successfully": "تم الدفع بنجاح",
      "Empty Cart": "تفريغ السلة",
      Checkout: "الدفع",
      "Cart is empty": "السلة فارغة",
      "When you add products to cart from different stores , you’ll find it here":
        "عندما تقوم باضافة منتجات إلى السلة من متاجر مختلفة، سوف تجدها هنا",
      Total: "الإجمالي",
      "Your order from": "الطلب من",
      "Add items": "إضافة منتج",
      Quantity: "الكمية",
      Item: "سعر القطعة الواحدة",
      AED: "درهم اماراتي",
      pending: "قيد الانتظار",
      "in progress": "في تقدم",
      delivered: "تم التوصيل",
      canceled: "تم الالغاء",
      cashOnDelivery: "الدفع كاش عند الاستلام",
      visa: "فيزا",
      Visa: "فيزا",
      "My Items": "أغراضي",
      Logout: "تسجيل الخروج",
      Enter: "قم بإدخال",
      "Updated Successfully": "تم التعديل بنجاح",
      "Track order": "تتبع الطلب",
      "Order Details": "تفاصيل الطلب",
      Update: "تعديل",
      "Reset Password": "تغيير كلمة المرور",
      Catagories: "كل التصنيفات",
      "All Services": "كل الخدمات",
      "All Products": "كل المنتجات",
      "Product Details": "تفاصيل المنتج",
      "All Shops": "كل المتاجر",
      "Service Details": "تفاصيل الخدمة",
      "Shop Details": "تفاصيل المتجر",
      "Added to wishlist": "تمت الإضافة إلى المفضلة",
      "Removed from wishlist": "تمت الإزالة من المفضلة",
      "Added to cart": "تمت الإضافة إلى السلة",
      services: "الخدمات",
      Rating: "التقييم",
      shops: "المحلات",
      "Rating is required": "يجب ادخال رقم التقييم",
      products: "المنتجات",
      "Ratings and Reviews": "التقييمات",
      "Enter your review": "قم بكتابة تقييمك",
      "Your Review": "تقييمك",
      "Your Review...": "تقييمك",
      "Add Your Review": "قم بكتابة تقييمك",
      Submit: "تأكيد",

      From: "من",
      To: "الي",
      Packages: "عروض",
      Offers: "عروض",
      "Take ": " اطلب ",
      Price: " السعر ",
      Categories: " التصنيفات ",
      "and pay ": " ادفع ",
      "Sort by": " ترتيب بواسطة ",
      "instead of ": " بدلاً من ",
      Only: "فقط",
      " left in stock - order soon.": "متبقية - اطلب في الحال",
      "Add to my cart": "أضف إلى السلة",
      Variations: "التشكيلات",
      "Starts from": "يبدأ من",
      "Fill Service Form": "ادخال بيانات الخدمة",
      "Send request": "ارسال الطلب",
      "Fill this form to request this service from the seller. note that seller will contact you after you fill this form":
        "قم بملئ البيانات اللازمة لطلب الخدمة من البائع. سيقوم البائع بالتواصل معكم عند ارسال الطلب.",
      "Request Service Successfully": "تم ارسال الطلب بنجاح",
      "Search for categories": "البحث عن تصنيفات",
      All: "الكل",
      "Search for ..": "بحث عن..",
      "Empty Cart Successfully": "تم تفريغ السلة بنجاح",
      'Check out our recently added products to discover the latest arrivals and exclusive items now available in our store!':'تفضلوا بزيارة قسم "المنتجات المضافة حديثًا" لاكتشاف أحدث المنتجات والعروض الحصرية المتوفرة الآن في متجرنا!',
      " Don't miss our Top Deals! Explore unbeatable discounts and special offers on our best-selling products for a limited time only.":' لا تفوتوا صفقاتنا الأفضل! اكتشفوا الخصومات المذهلة والعروض الخاصة على أفضل منتجاتنا لفترة محدودة فقط.',
      "Products": "المنتجات",
      "Shops": "المتاجر",
      "Top Deals": "أفضل العروض",
      "Trending Now": "الأكثر رواجًا",
      "Recently Added": "مضاف مؤخرًا",
      "Featured Shops": "المتاجر المميزة",
      "Don't miss our Top Deals! Explore unbeatable discounts and special offers on our best-selling products for a limited time only.": "لا تفوت أفضل العروض لدينا! استكشف الخصومات غير القابلة للمنافسة والعروض الخاصة على أفضل منتجاتنا لفترة محدودة فقط.",
      "View Store": "عرض المتجر",
      "Sale": "تخفيض",
      "View Product": "عرض المنتج",
      "Got questions? Call us !": "هل لديك أسئلة؟ اتصل بنا!",
      "Contact info": "معلومات الاتصال",
      "FOR CUSTOMERS": "للعملاء",
      "CONTACT US": "اتصل بنا",
      "RETURN POLICY": "سياسة الإرجاع",
      "FAQS": "الأسئلة الشائعة",
      "FOR SELLERS": "للبائعين",
      "WHO WE ARE ?": "من نحن؟",
      "BECOME A SELLER": "كن بائعًا",
      "SERVICES": "الخدمات",
      "PRIVACY POLICY": "سياسة الخصوصية",
      "TERMS & CONDITIONS": "الشروط والأحكام",
      "Don’t have an account?": "لا تملك حسابًا؟",
      "you successfully logged in": "لقد قمت بتسجيل الدخول بنجاح",
      "forgot password?": "نسيت كلمة المرور؟",
  "or Sign In with": "أو تسجيل الدخول باستخدام",
  "Sign in with Google": "تسجيل الدخول باستخدام Google",
  "Sign in with Facebook": "تسجيل الدخول باستخدام Facebook",
    "Search...": "بحث...",
  "My Account": "حسابي",
  "Data Updated Successfully": "تم تحديث البيانات بنجاح",
  "Overview": "نظرة عامة",
  "My orders": "طلباتي",
  "My wishlist": "قائمة أمنياتي",
  "My Address Book": "دفتر العناوين الخاص بي",
  "Account Setting": "إعدادات الحساب",
  "LogOut": "تسجيل الخروج",
  "OverView": "نظرة عامة",
  "Log Out": "تسجيل الخروج",
  "Settings": "إعدادات",
  "No special characters allowed": "لا يُسمح بالرموز الخاصة",
  "Username is required": "اسم المستخدم مطلوب",
  "Phone number must be numeric": "يجب أن يكون رقم الهاتف رقميًا",
  "Phone number is required": "رقم الهاتف مطلوب",
  "City is required": "المدينة مطلوبة",
  "Street is required": "الشارع مطلوب",
   "Select an emirate": "اختر إمارة",
   "My Orders": "طلباتي",
   "Track Order": "تتبع الطلب",
   "New Password": "كلمة مرور جديدة",
   "password is required": "كلمة المرور مطلوبة",
    "Filter By": "فلترة بواسطة",
  "Tags": "العلامات",
  "Load More": "تحميل المزيد",
    "Deleted Successfully": "تم الحذف بنجاح",
    "Product added successfully": "تمت إضافة المنتج بنجاح",
    "Added to cart successfully": "تمت الاضافة إلى السلة بنجاح",
    "Add to cart": "إضافة إلى السلة",
    "Description": "الوصف",
    "No Reviews Yet": "لا توجد مراجعات بعد",
    "Welcome!": "مرحبًا!",
    "Emirate": "إمارة",
    "I agree to the terms and conditions.": "أوافق على الشروط والأحكام.",
    "Enter your full name here": "أدخل اسمك الكامل هنا",
    "Invalid UAE phone number": "رقم هاتف إمارتي غير صالح",
    "Enter your OTP": "أدخل رمز التحقق (OTP)",
  "Didn’t receive the OTP?": "لم تستلم رمز التحقق؟",
  "Back to log in": "العودة إلى تسجيل الدخول",
  "Click to resend": "انقر لإعادة الإرسال",
  "phone number is verified successfully!": "تم التحقق من رقم الهاتف بنجاح!",
  "OTP sent successfully!": "تم إرسال رمز التحقق بنجاح!",
  "The SMS code has expired. Please re-send the verification code to try again.": 
    "لقد انتهت صلاحية رمز SMS. يرجى إعادة إرسال رمز التحقق للمحاولة مرة أخرى.",
  "The verification code is invalid. Please enter a valid code.": 
    "رمز التحقق غير صالح. يرجى إدخال رمز صالح.",
  "No verification code provided.": 
    "لم يتم تقديم رمز التحقق.",
  "No phone number provided for authentication.": 
    "لم يتم تقديم رقم الهاتف للمصادقة.",
  "Quota exceeded for sending verification codes.": 
    "تم تجاوز الحصة المسموح بها لإرسال رموز التحقق.",
  "The user account has been disabled by an administrator.": 
    "تم تعطيل حساب المستخدم بواسطة مسؤول.",
  "Unauthorized continue URL.": 
    "رابط المتابعة غير مصرح به.",
    "reCAPTCHA verification failed.": 
    "فشل التحقق من reCAPTCHA.",
  "Invalid continue URL.": 
    "رابط المتابعة غير صالح.",
  "The provided phone number is not valid.": 
    "رقم الهاتف المقدم غير صالح.",
  "The requested authentication operation is not allowed.": 
    "العملية المطلوبة للمصادقة غير مسموح بها.",
  "An error occurred during code confirmation.": 
    "حدث خطأ أثناء تأكيد الرمز.",
    "unKnown Error": "خطأ غير معروف",
    "Verify OTP": "تحقق من رمز التحقق (OTP)",
    "All Categories": "جميع الفئات",
    "in stock": "متوفر في المخزون",
    "By": "بواسطة",
    "item": "عنصر",
    "You have to": "عليك ",
    "login": "تسجيل الدخول",
    "first": "أولاً",
    "Reviews": "التعليقات",
    "My Wishlist": "قائمتي المفضلة",
    "Loading...": "جارٍ التحميل...",
    "Fill out the necessary information to request the service from the seller. The seller will contact you when the request is sent": "يرجى ملء المعلومات اللازمة لطلب الخدمة من البائع. سيتواصل معك البائع عند إرسال الطلب",
    "Service requested successfully": "تم طلب الخدمة بنجاح",
  "Ahlan": "أهلاً",
  "My Cart": "سلة التسوق الخاصة بي",
  "Food Products": "منتجات الطعام",
  "Chat with": "الدردشة مع",
  "Base Price": "السعر الأساسي",
  "Service Total": "إجمالي الخدمة",
  "Starts at": "يبدأ من",
  "Total Price": "إجمالي السعر",
  "Your Order From": "طلبك من",
  "You have received a cashback of": "لقد تلقيت استرداد نقدي قدره",
  "Apply now": "قدّم الآن",
  "Apply Cashback": "تطبيق الاسترداد النقدي",
  "Cashback": "استرداد نقدي",
  "Enter Coupon": "إدخال قسيمة",
  "Remove": "إزالة",
  "Please enter a coupon code.": "يرجى إدخال رمز القسيمة.",
  "Please enter a cashback value you want to use!": "يرجى إدخال قيمة الاسترداد النقدي التي تريد استخدامها ",
  "Cashback you want to use": "استرداد نقدي تريد استخدامه", 
"Item Total": "إجمالي العنصر",
 "Compare Products": "مقارنة المنتجات",
  "No Products Found !": "لا توجد منتجات !",
  "No Data Found !": "لا توجد بيانات !",
  "Please select an emirate": "يرجى اختيار إمارة",
  "Please select a your location from the map": "يرجى اختيار موقعك من الخريطة",
  "Add Address": "إضافة عنوان",
  "Remarks": "ملاحظات",
  "Pick up your current location": "اختر موقعك الحالي",
  "Go Next": " التالي",
  "Go Previous": "السابق",
  "Remarks is required": "الملاحظات مطلوبة",
    "Payment Method": "طريقة الدفع",
  "Select payment method": "اختر طريقة الدفع",
  "Please select payment method": "يرجى اختيار طريقة الدفع",
  "Payment details": "تفاصيل الدفع",
  "Cart Total": "إجمالي السلة",
  "Shipping Fees Total": "إجمالي رسوم الشحن",
  "Taxes Total": "إجمالي الضرائب",
  "Order Total": "إجمالي الطلب",
  "Place order": "تقديم الطلب",
  "Payment Successful!": "تمت معالجة الدفع بنجاح!",
  "Your payment has been processed successfully. You can now continue using the services.": "تمت معالجة دفعك بنجاح. يمكنك الآن الاستمرار في استخدام الخدمات.",
  "Continue": "استمر",
  "Connect easily with": "تواصل بسهولة مع",
  "Start Messaging With": "ابدأ المراسلة مع",
  "Sona3 Support": "دعم صناع",
  "Customers": "العملاء",
  "or": "أو",
  "Customer": "العميل",
   "Type a message...": "اكتب رسالة...",
  "Sona3 Admin": "إدارة صناع",
   "No chats from customers": "لا توجد محادثات من العملاء",
  "No Chat Rooms Yet": "لا توجد غرف دردشة بعد",
  "Seller": "البائع",
  "Sellers": "البائعون",
  "Requested Services": "الخدمات المطلوبة",
  "Chat With US": "تواصل معنا",
  "We send you OTP to :": "لقد أرسلنا رمز التحقق إلى:",
  "Forgot Password": "نسيت كلمة المرور",
  "Enter your email address and we'll send you a link to reset your password.": "أدخل عنوان بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور.",
  "Email Address": "عنوان البريد الإلكتروني",
  "Enter your email": "أدخل بريدك الإلكتروني",
  "Send Reset Email OTP": "إرسال رمز التحقق لإعادة تعيين البريد الإلكتروني",
  "Invalid email address": "عنوان البريد الإلكتروني غير صالح",
  "Email is required": "البريد الإلكتروني مطلوب",
  "Sending": "جارٍ الإرسال",
  "Send OTP": "إرسال رمز التحقق",
  "Enter your Email and we will send you an OTP to reset your password.": "أدخل بريدك الإلكتروني وسنرسل لك رمز التحقق لإعادة تعيين كلمة المرور.",
  "Email verified successfully": "تم التحقق من البريد الإلكتروني بنجاح",
  "404 - Page Not Found": "404 - الصفحة غير موجودة",
  "The page you are looking for does not exist.": "الصفحة التي تبحث عنها غير موجودة.",
  "Set New Password": "تعيين كلمة مرور جديدة",
  "Please enter your new password and confirm it.": "يرجى إدخال كلمة مرور جديدة وتأكيدها.",
  "Enter new password": "أدخل كلمة المرور الجديدة",
  "Confirm your password": "تأكيد كلمة مرورك",
  "Password must be at least 8 characters": "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
  "Password is required": "كلمة المرور مطلوبة",
  "Passwords must match": "يجب أن تتطابق كلمتا المرور",
  "Confirm Password is required": "تأكيد كلمة المرور مطلوب",
  "Password updated successfully": "تم تحديث كلمة المرور بنجاح",
  "We send you a confirmation email to": "لقد أرسلنا لك رسالة تأكيد على",
  "Compare": "مقارنة",
  "Variation": "تغير",
  "Rate": "تقييم",
  "Clear": "حذف",
"ACCEPTED": "مقبول",
  "REJECTED": "مرفوض",
   "accepted": "مقبول",
  "rejected": "مرفوض",
  "Review added successfully": "تم إضافة المراجعة بنجاح",
  "No notifications yet": "لا توجد إشعارات حتى الآن",
  "Used Cashback": "الكاش باك المستخدم",
  "Shipping Fees": "رسوم الشحن",
  "Status": "الحالة",
  "Taxes": "الضرائب",
  "purchased": "تم الشراء",


  "United Arab Emirates":"الإمارات العربية المتحدة",
        "Abu Dhabi":"أبو ظبي",
        "Western Abu Dhabi":"أبو ظبي الغربيه",
        "Dubai":"دبي",
        "Al Ain":"العين",
        "Western Al Ain":"العين الغربيه",
        "Sharjah":"الشارقة",
        "Ajman":"عجمان",
        "Fujairah":"فجيرة",
        "Khor Fakkan":"خور فكان",
        "Ras Al Khaimah":"رأس الخيمة",
        "Umm Al Quwain":"أم القوين",
        "Out OF Service Area":"خارج منطقة الخدمة",
        "Emirate is required":"يجب اختيار الإمارة",
        "AL AIN": "العين",
        "DUBAI": "دبي",
        "SHARJAH": "الشارقة",
        "AJMAN": "عجمان",
        "ABU DHABI": "أبو ظبي",
        "FUJAIRAH": "فجيرة",
        "RAS AL KHAIMAH": "رأس الخيمة",
        "UMM AL QUWAIN": "أم القوين",
        "Umm-Al Quwain": "أم القوين",
        "Umm-AL Quwain": "أم القوين",
        "Al Andalus Compound": "الاندلس كمباوند",
    "Al Basra": "البصرة",
    "Al Hili": "الهلي",
    "Al Jahli": "الجهلي",
    "Al Jimi": "الجيمي",
    "Al Khabisi": "الخبيصي",
    "Al Khrair": "الخراير",
    "Al Kuwaitat": "الكويتات",
    "Al Manaseer": "المنصير",
    "Al Masoudi": "المسعودي",
    "Al Misbah": "المصباح",
    "Al Mnaizfah": "المنيزفه",
    "Al Mnaizlah": "المنيزله",
    "Al Murabba": "المربعة",
    "Al Mutawaa": "المطوع",
    "Al Muwaiji": "المويجي",
    "Al Owainah": "العوينة",
    "Al Qattara": "القطارة",
    "Al Rumailah": "الرميليه",
    "Al Ruwaikah": "الرويكه",
    "Al Sanaiya": "الصناعية",
    "Al Sarooj": "السروج",
    "Al Sharej": "الشريج",
    "Al Tawam": "التوام",
    "Al Towayya": "التواية",
    "Asharej": "الشريج",
    "Bawadi Mall": "بوادي مول",
    "Civic Centre": "مركز مدني",
    "Dairy Farm": "مزرعة الألبان",
    "Falaj Hazzaa": "فلاج هزاع",
    "Hili Al Khaleef": "هلي الخليفي",
    "Hili Oasis": "واحة هلي",
    "Hili Sanaiya": "هلي الصناعية",
    "Main Street": "الشارع الرئيسي",
    "Mreifia": "مريفيع",
    "New Al Kuwaitat": "الكويتات الجديدة",
    "New Al Sarooj": "السروج الجديدة",
    "Neyadat": "النيادات",
    "Oud Al Touba": "عود التوبة",
    "Oud Muteena": "عود المطينة",
  "Palm Island": "جزيرة النخلة",
  "Port Saeed": "ميناء سعيد",
  "Q Point Al Mazaya": "نقطة كيو المزايا",
  "Qidfa": "قدفع",
  "Qusaidat": "قصيدات",
  "Rak City": "مدينة رأس الخيمة",
  "Rak Corniche": "كورنيش رأس الخيمة",
  "Ras Al Khaimah Airport": "مطار رأس الخيمة",
  "Ras Al Khaimah City": "مدينة رأس الخيمة",
  "Ras Al Khaimah Maritime City": "مدينة رأس الخيمة البحرية",
  "Ras Al Khor": "رأس الخور",
  "Rashidiya": "الراشدية",
  "Reem Island": "جزيرة الريم",
  "Remraam": "رمثام",
    "Shabiya Khalifa": "شعبية خليفة",
    "Town Centre": "مركز المدينة",
    "Abu Dhabi City": "مدينة أبوظبي",
    "Abu Hail, Dubai": "أبو هيل، دبي",
    "Aflaj": "الأفلاج",
    "Ajman City": "مدينة عجمان",
    "Al Aahad": "العهد",
    "Al Abraq": "الأبرق",
    "Al Ain Airport": "مطار العين",
    "Al Ain City": "مدينة العين",
    "Al Ain Dairy Farm": "مزرعة ألبان العين",
    "Al Aqaa": "العقاع",
    "Al Attain": "العطين",
    "Al Aweer": "العوير",
    "Al Barari": "البراري",
    "Al Barashi": "البرشا",
    "Al Barsha": "البرشاء",
    "Al Barsha South": "البرشاء جنوب",
    "Al Burairat": "البرايرات",
    "Al Dar Al Baida": "الدار البيضاء",
    "Al Dhafra Air Base Ae": "قاعدة الظفرة الجوية",
    "Al Dhaid": "الذيد",
    "Al Dhait": "الظيت",
    "Al Dhait North": "الظيت الشمالية",
    "Al Dhait South": "الظيت الجنوبية",
    "Al Digdaga": "الديقادة",
    "Al Diyafa": "الضيافة",
    "Al Furjan": "الفرجان",
    "Al Garhoud": "القرهود",
    "Al Ghail Industrial Park": "حديقة الغيل الصناعية",
    "Al Ghusais": "القصيص",
    "Al Ghusais Industrial Area": "منطقة القصيص الصناعية",
    "Al Haditha": "الحديثة",
    "Al Hail Indistrial Area": "منطقة هيل الصناعية",
    "Al Hawiyah": "الحوية",
    "Al Hazzan": "الحزان",
    "Al Hudaibah": "الهديبة",
    "Al Humrah": "الحمرا",
    "Al Jaddaf": "الجاف",
    "Al Kharran": "الخيران",
    "Al Khawaneej": "الخوانيج",
    "Al Khor": "الخليج",
    "Al Khubasi": "الخبيصي",
    "Al Khuzam": "الخزام",
    "Al Layan": "الليان",
    "Al Madar": "المدار",
    "Al Maiden": "الميدان",
    "Al Mairid": "الميدريد",
    "Al Mamourah": "المعمورة",
    "Al Markaz Ind. Park Abu Dhabi": "حديقة المركز الصناعي أبوظبي",
    "Al Maryah Island": "جزيرة المارية",
  "Al Nahda 1": "النهضة 1",
  "Al Nakeel": "النخيل",
  "Al Nakheel": "النخيل",
  "Al Quawasim Corniche": "كورنيش القواسم",
  "Al Quoz": "القصيص",
  "Al Raas": "الرأس",
  "Al Raha": "الراحة",
  "Al Raha Beach Area": "منطقة شاطئ الراحة",
  "Al Rahmaniya": "الرحمانية",
  "Al Ramlah": "الرملة",
  "Al Rams Road": "طريق الرمس",
  "Al Ras": "الرأس",
  "Al Raudah": "الروضة",
  "Al Rigga": "الرقة",
  "Al Riqqah": "الرقة",
  "Al Sajja": "الصجعة",
  "Al Salamah": "السلامة",
  "Al Sofouh Gardens": "حدائق الصفوح",
  "Al Thammam": "الثمام",
  "Al Twar": "الطوار",
  "Al Uraibi": "العرابي",
  "Al Waha": "الواحة",
  "Al Warqa": "الورقاء",
  "Al Warsan": "الورسان",
  "Al Zahra": "الزهراء",
  "Arabian Ranches": "المرابع العربية",
  "Ayal Nasir": "عيال ناصر",
  "Bada Zayed, Abu Dhabi": "بدع زايد، أبوظبي",
  "Baniyas": "بني ياس",
  "Baraka, Abu Dhabi": "براكة، أبوظبي",
  "Barsha Heights": "مرتفعات البرشاء",
  "Beda Zayed": "بدا زايد",
  "Bidya": "بديع",
  "Bur Dubai": "بر دبي",
  "Business Bay": "الخليج التجاري",
  "City Of Arabia": "مدينة العرب",
  "Coload Drop Off Deira": "تحميل ديرا",
  "Dahan": "دهان",
  "Damac Hills": "تلال داماك",
  "Deira": "ديرة",
  "Deira D.O, Dubai": "ديرة دبي",
  "Dibba": "دبا",
  "Dibba Al Hosn": "دبا الحصن",
  "Discovery Gardens": "حدائق الديسكفري",
  "Downtown Dubai": "وسط مدينة دبي",
  "Downtown Jebel Ali": "وسط جبل علي",
  "Dubai Academic City": "مدينة دبي الأكاديمية",
  "Dubai Airport Freezone": "منطقة مطار دبي الحرة",
  "Dubai Airport T2": "مطار دبي T2",
  "Dubai Design District": "حي دبي للتصميم",
  "Dubai Health Care City": "مدينة دبي الصحية",
  "Dubai Hills": "تلال دبي",
  "Dubai Humanitarian City": "مدينة دبي الإنسانية",
  "Dubai Industrial City": "مدينة دبي الصناعية",
  "Dubai International Fin. Center": "مركز دبي المالي العالمي",
  "Dubai Internet City": "مدينة دبي للإنترنت",
  "Dubai Investment Park": "مجمع دبي للاستثمار",
  "Dubai Knowledge Park": "مدينة دبي للمعرفة",
  "Dubai Knowledge Village": "قرية دبي للمعرفة",
  "Dubai Land": "دبي لاند",
  "Dubai Logistic City": "مدينة دبي اللوجستية",
  "Dubai Marina": "مرسى دبي",
  "Dubai Maritime City": "مدينة دبي البحرية",
  "Dubai Media City": "مدينة دبي للإعلام",
  "Dubai Motor City": "مدينة دبي للسيارات",
  "Dubai Outsource Zone": "منطقة دبي الخارجية",
  "Dubai Production City": "مدينة دبي للإنتاج",
  "Dubai Silicon Oasis": "واحة دبي للسيليكون",
  "Dubai South": "دبي الجنوب",
  "Dubai Sports City": "مدينة دبي الرياضية",
  "Dubai Studio City": "مدينة دبي للاستوديوهات",
  "Dubai Water Front": "واجهة دبي المائية",
  "Dubai World Central - Logistic": "دبي ورلد سنترال - اللوجستية",
  "Dubai, Jebel Ali Free Zone": "دبي، منطقة جبل علي الحرة",
  "Emirates Hills": "تلال الإمارات",
  "Emirates Lakes": "بحيرات الإمارات",
  "Falcon City Of Wonders": "مدينة الصقور للعجائب",
  "Faseel": "الفصيل",
  "Festival City": "فيستيفال سيتي",
  "Frij Al Murar": "فريج المرر",
  "Rigga Al Buteen": "الرقة البطين",
  "Ruwais": "الرويس",
  "Saadiyath Island": "جزيرة السعديات",
  "Satwa": "السطوة",
  "Shahama": "الشهامة",
  "Shakbout City": "مدينة شخبوط",
  "Shamkha": "الشامخة",
  "Sharjah City": "مدينة الشارقة",
  "Sharjah Free Zone": "المنطقة الحرة في الشارقة",
  "Sharjah International Airport": "مطار الشارقة الدولي",
  "Shawamekh": "الشوامخ",
  "Sonapur": "سونابور",
  "Soowah Island": "جزيرة السُوه",
  "Springs": "الينابيع",
  "Sweihan, Abu Dhabi": "سويحان، أبو ظبي",
  "Tarif, Abu Dhabi": "الطريف، أبو ظبي",
  "Techno Park": "تكنو بارك",
  "Technology Park": "حديقة التكنولوجيا",
  "The Greens": "الجرينز",
  "The Lakes": "البحيرات",
  "The Meadows": "المرابع",
  "The Springs": "الينابيع",
  "The Views": "ذا فيوز",
  "The Villa": "ذا فيلا",
  "Uae Others": "الإمارات الأخرى",
  "Umm Al Nar": "أم النار",
  "Umm Ramool": "أم رمول",
  "Wathaba": "الوثبة",
  "Yas Island East": "جزيرة ياس الشرقية",
        "Hor Al Anz A": "هور العنز أ",
  "Icad-1": "إيكاد-1",
  "Icad-11": "إيكاد-11",
  "Icad-111": "إيكاد-111",
  "Industrial Area": "المنطقة الصناعية",
  "Industrial Park": "الحديقة الصناعية",
  "Inside Meena Port": "داخل ميناء المينا",
  "International City": "المدينة العالمية",
  "International Media Prod. Zone": "منطقة إنتاج الإعلام الدولي",
  "Jafliya": "جافلية",
  "Jafza": "جافزا",
  "Jafza, Dubai": "جافزا، دبي",
  "Jazeerah Al Hamra": "جزيرة الحمراء",
  "Jebel Ali": "جبيل علي",
  "Jebel Ali Free Zone": "منطقة جبل علي الحرة",
  "Jebel Ali Free Zone, Dubai": "منطقة جبل علي الحرة، دبي",
  "Jebel Ali Free Zone, North": "منطقة جبل علي الحرة، شمال",
  "Jebel Ali Free Zone, South": "منطقة جبل علي الحرة، جنوب",
  "Jebel Ali Hills Meraas": "مرسى جبيل علي",
  "Jebel Ali Industrial Area": "المنطقة الصناعية بجبل علي",
  "Jumeira": "جميرا",
  "Jumeirah Beach Residence": "إقامة شاطئ جميرا",
  "Jumeirah Golf Estates": "عقارات جولف جميرا",
  "Jumeirah Heights": "ارتفاعات جميرا",
  "Jumeirah Island": "جزيرة جميرا",
  "Jumeirah Lake Towers": "برج بحيرات جميرا",
  "Jumeirah Village": "قرية جميرا",
  "Jumeirah Village Circle": "دائرة قرية جميرا",
  "Jumeirah Village Triangle": "مثلث قرية جميرا",
  "Kalba": "كلباء",
  "Karama": "الكرامة",
  "Khalifa City": "مدينة خليفة",
  "Khalifa City A": "مدينة خليفة أ",
  "Khalifa City B": "مدينة خليفة ب",
  "Khalifa Park Area": "منطقة حديقة خليفة",
  "Khor Kheir": "خور خير",
  "Khor Khwair": "خور خوير",
  "Khorfakan": "خورفكان",
  "Madam, Dubai": "مدام، دبي",
  "Mafraq": "المفرق",
  "Main Street Al Ain": "الشارع الرئيسي العين",
  "Mamzar": "المزهر",
  "Mankool": "منكول",
  "Masafi": "مصافي",
  "Meadows": "المروج",
  "Meena Bazar": "سوق المينا",
  "Meydan": "ميدان",
  "Mina Zayed": "ميناء زايد",
  "Mirdif": "المردف",
  "Mirfa, Abu Dhabi": "المرفأ، أبوظبي",
  "Mizher": "مزهر",
  "Mohammed Bin Zayed City": "مدينة محمد بن زايد",
  "Mudun": "مدن",
  "Muraqqabat": "مراقبة",
  "Murba": "مربى",
  "Murshid Bazar": "سوق مرشد",
  "Mussafah": "المصفح",
  "Mussafah Shabiya": "المصفح الشبية",
  "Muteena": "معتين",
  "Nad Al Hamar": "ند الحمر",
  "Nad Al Sheba Meydan": "ند الشبا ميدان",
  "Nakheel": "نخيل",
  "Fujairah Airport": "مطار الفجيرة",
  "Fujairah City": "مدينة الفجيرة",
  "Fujairah Free Zone": "المنطقة الحرة بالفجيرة",
  "Fujairah Port": "ميناء الفجيرة",
  "Gardens": "الحدائق",
  "Gate City": "مدينة البوابة",
  "Golf City": "مدينة الغولف",
  "Green Community": "المجتمع الأخضر",
  "Hamriya": "حمرياء",
  "Hamriya Free Zone": "المنطقة الحرة بالحمرياء",
  "Hamriya Port": "ميناء الحمرياء",
  "Hatta, Dubai": "حتا، دبي",
  "Khorfakkan": "خورفكان",
  "Password must be at least 8 characters long": "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
  "Password must contain at least one letter": "يجب أن تحتوي كلمة المرور على حرف واحد على الأقل",
  "Password must contain at least one number": "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
  "You must agree to the terms and conditions": "يجب أن توافق على الشروط والأحكام",
  "Please enter a valid UAE phone number": "يرجى إدخال رقم هاتف صالح في الإمارات العربية المتحدة",
  "Out of Stock": "غير متوفر في المخزون",
  "Added to compare list": "تمت إضافته إلى قائمة المقارنة",
  "Product already in CompareList": "المنتج موجود بالفعل في قائمة المقارنة",
  "Compare Only Two Products":"يمكنك مقارنة منتجين فقط",
  "VS": "مقابل",
  "Payment Failed": "فشل الدفع",
  "Unfortunately, your payment could not be processed. Please try again or contact support.": "للأسف، لم يتمكن النظام من معالجة دفعتك. يرجى المحاولة مرة أخرى أو الاتصال بالدعم.",




  "Coupons": "الكوبونات",
  "Coupon Type": "نوع الكوبون",
  "All Coupons": "كل الكوبونات",
  "Coupons Type: Shop": "نوع الكوبون: متجر",
  "Coupons Type: Sona3": "نوع الكوبون: صناع",
  "Copy": "نسخ",
  "Code copied": "تم نسخ الكود",
  "Coupon code: ": " :كود الكوبون",
  "has been copied to your clipboard": "تم نسخ الكود الى الحافظة",
  "nameEn": "الاسم بالإنجليزية",
  "nameAr": "الاسم بالعربية",
  "descriptionEn": "الوصف بالإنجليزية",
  "descriptionAr": "الوصف بالعربية",
  "Valid": "صالح",
  "Expired": "منتهي الصلاحية",
  "Coupon Used": "تم استخدام الكوبون",
  "Discount": "نوع الخصم",
  "percentage": "نسبة مئوية",
  "The Percentage": "النسبة مئوية",
  "value": "قيمة",
  "The Value": "القيمة",
  "code": "الكود",
  "quantity": "الكمية",
  "Expiration Date": "تاريخ الانتهاء",
  "Active": "نشط",
  "Inactive": "غير نشط",
  "Active Only": "الكوبونات النشطة",
  "Expired Only": "الكوبونات منتهية الصلاحية",
  "Required": "مطلوب",
  "Check Out": "تقديم الطلب",
  'Phone number must start with 5': 'يجب أن يبدأ رقم الهاتف بالرقم 5',
  'Second digit must be 0, 2, 4, 5, 6, or 8': 'يجب أن يكون الرقم الثاني 0 أو 2 أو 4 أو 5 أو 6 أو 8',
  'Please enter a valid UAE phone number, must be 9 digits': 'الرجاء إدخال رقم هاتف إماراتي صحيح، يجب أن يكون مكوناً من 9 أرقام',
  "Compare List Cleared": "تم مسح قائمة المقارنة",
   "Related Services": "خدمات ذات صلة",
  "Related Products": "منتجات ذات صلة",
  "Image Updated Successfully": "تم تحديث الصورة بنجاح",
  "Please connect sona3 support team": "يرجى التواصل مع فريق دعم صناع",



  





 


    },
  },
};


const getLang = ()=>{
  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams?.get('lang')  &&( searchParams?.get('lang')?.includes('ar') ? 'ar':'en')
  if(lang) {
    localStorage.setItem('lang', lang === 'ar'? 'ar' :'en');
  }
  return lang || undefined
}

const storedLanguage = getLang() ||  localStorage.getItem('lang') || 'en';


i18n.use(initReactI18next).init({
  resources,
  fallbackLng: localStorage.getItem("lang") || "en",
  lng: storedLanguage,
  debug: false,
  interpolation: { escapeValue: false },
});
 
export default i18n;
