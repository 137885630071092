import React from "react";
import { Image, Box, IconButton, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "../redux/store";

import saveIcon from "../assets/images/imgs/favIcon.png";
import redHeart from "../assets/images/imgs/heart.png";
import { useTranslation } from "react-i18next";
import {
  addToWishlistAsync,
  removeFromWishlistAsync,
} from "../redux/reducerSlices/Wishlist/wishlistSlice";

interface SavedButtonProps {
  variationId?: any;
}

const SavedButton: React.FC<SavedButtonProps> = ({ variationId  }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  //   const customerId = useAppSelector((state: any) => state?.auth?.authData?._id);

  const wishlist = useAppSelector((state: RootState) => state?.wishlist);
  const isVariationInWishlist = wishlist?.includes(variationId);
  const toast = useToast();

  const handleToggleWishlist = () => {
    if (isVariationInWishlist) {
      dispatch(removeFromWishlistAsync(variationId));
      showToastMessage(t("Removed from wishlist"));
    } else {
      dispatch(addToWishlistAsync(variationId));
      showToastMessage(t("Added to wishlist"));
    }
  };

  const showToastMessage = (message: string) => {
    toast({
      position: 'top-right',
      title: message,
      status: "success",
      duration: 3000,
      isClosable: true,

    });
  };

  return (
    <Box w={12} position="relative">
      <IconButton
      
        aria-label={
          isVariationInWishlist
            ? t("Remove from wishlist")
            : t("Add to wishlist")
        }
        icon={
          <Image
            src={isVariationInWishlist ? redHeart : saveIcon}
            alt={
              isVariationInWishlist ? "Remove from wishlist" : "Add to wishlist"
            }
           width={"22px"}
          />
        }
        variant={isVariationInWishlist ? "ghost" : "ghost"}
        borderRadius="50%"
        bg={"rgba(0,0,0,.5)"}
         size="sm"    
        position="absolute"
        right="2"
        onClick={handleToggleWishlist}
       
      />
    </Box>
  );
};

export default SavedButton;
