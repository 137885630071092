import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface setChatMenuData {
  chatMenuData: {
    active?: Boolean;
    chatMenu?: Boolean;
    list?: Boolean;
    chatBox?: Boolean;
    withAdmin?: Boolean;
    fastBox?: Boolean;
    chatType?: String;
    sellerId?: String;
    roomId?: String | undefined;
  };
}
const initialState: setChatMenuData = {
  chatMenuData: {
    active: false,
    chatMenu: false,
    list: false,
    chatBox: false,
    withAdmin: false,
    fastBox: false,
    chatType: "",
    sellerId: "",
    roomId: "",
  },
};

export const chatMenuSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setChatMenuData: (state, action: PayloadAction<setChatMenuData>) => {
  
      if (action?.payload?.chatMenuData?.active !== undefined) {
        state.chatMenuData.active = action?.payload?.chatMenuData?.active;
      }
      if (action?.payload?.chatMenuData?.chatMenu !== undefined) {
        state.chatMenuData.chatMenu = action?.payload?.chatMenuData?.chatMenu;
      }
      if (action?.payload?.chatMenuData?.list !== undefined) {
        state.chatMenuData.list = action?.payload?.chatMenuData?.list;
      }
      if (action?.payload?.chatMenuData?.chatBox !== undefined) {
        state.chatMenuData.chatBox = action?.payload?.chatMenuData?.chatBox;
      }
      if (action?.payload?.chatMenuData?.withAdmin !== undefined) {
        state.chatMenuData.withAdmin = action?.payload?.chatMenuData?.withAdmin;
      }
      if (action?.payload?.chatMenuData?.chatType !== undefined) {
        state.chatMenuData.chatType = action?.payload?.chatMenuData?.chatType;
      }
      if (action?.payload?.chatMenuData?.sellerId !== undefined) {
        state.chatMenuData.sellerId = action?.payload?.chatMenuData?.sellerId;
      }
      if (action?.payload?.chatMenuData?.roomId !== undefined) {
        state.chatMenuData.roomId = action?.payload?.chatMenuData?.roomId;
      }
      if (action?.payload?.chatMenuData?.fastBox !== undefined) {
        state.chatMenuData.fastBox = action?.payload?.chatMenuData?.fastBox;
      }
    },
  },
});

export const { setChatMenuData } = chatMenuSlice.actions;

export default chatMenuSlice.reducer;
