import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Heading,
    Text,
    Flex,
    useToast,
    InputRightElement,
    InputGroup,
    IconButton,
    InputLeftElement,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { T } from '../../../utils/ValiidationSchemas';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from '../../../api/services/axios';
import i18n from '../../../i18n';
import { showMSG } from '../../../utils/utilsFunctions';
import useLang from '../../../hooks/useLang';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

// Define the form values type
interface ResetPasswordFormValues {
    password: string;
    confirmPassword: string;
}

// Validation schema using Yup
const ResetPasswordSchema = (t: T) => Yup.object().shape({
    password: Yup.string()
        .min(8, t('Password must be at least 8 characters'))
        .required(t('Password is required')),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'),], t('Passwords must match'))
        .required(t('Confirm Password is required')),
});

const ResetPassword: React.FC = () => {
    const initialValues: ResetPasswordFormValues = { password: '', confirmPassword: '' };
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()
    const toast = useToast()
    const state = location?.state


    // State for showing and hiding passwords
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onResetPassword = async ({ newPassword , setSubmitting}: { newPassword: string , setSubmitting:any }) => {
        try {
            const response = await Axios.put(
                "reset-password", 
                {
                "email":  state?.email,
                "newPassword": newPassword
            },
                {
                    params: { _id: state?._id },
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${state?.token}`,
                        "x-app-token": " Sona3-Team",
                        "accept-language": i18n?.language ? 'ar' : 'en',
                    },
                }
            );
            showMSG( toast ,'success' , t('Password updated successfully') )
            navigate('/auth-tabs')
        } catch (error:any) {
            console.log(error)
            error?.response?.data?.error  && showMSG( toast ,'error' , error?.response?.data?.error )

        }
        setSubmitting(false)

    }

    return (
        <Box
        margin={'auto'}
        width={{ base: '90%', md: '50%' }}
        mt={8}
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="lg"
    >
        <Heading as="h2" size="lg" mb={6} textAlign="center">
            {t('Set New Password')}
        </Heading>
        <Text mb={6} textAlign="center">
            {t('Please enter your new password and confirm it.')}
        </Text>

        <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
                onResetPassword({ newPassword: values.password, setSubmitting });
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Field name="password">
                        {({ field, form }: any) => (
                            <FormControl isInvalid={form.errors.password && form.touched.password} mb={4}>
                                <FormLabel htmlFor="password">{t('New Password')}</FormLabel>
                                <InputGroup >
                                    <Input
                                        {...field}
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        placeholder={t('Enter new password')}
                                    />
                                    { <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={!showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            onClick={() => setShowPassword(!showPassword)}
                                            variant="ghost"
                                            _hover={{
                                                backgroundColor:'transparent',
                                            }}
                                        />
                                    </InputRightElement>}
                                   
                                </InputGroup>
                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>

                    <Field name="confirmPassword">
                        {({ field, form }: any) => (
                            <FormControl
                                isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}
                                mb={4}
                            >
                                <FormLabel htmlFor="confirmPassword">{t('Confirm Password')}</FormLabel>
                                <InputGroup>
                                    <Input
                                        {...field}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="confirmPassword"
                                        placeholder={t('Confirm your password')}
                                    />
                                    {<InputRightElement>
                                        <IconButton
                                            aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                                            icon={!showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            variant="ghost"
                                            _hover={{
                                                backgroundColor:'transparent',
                                            }}
                                        />
                                    </InputRightElement>}
                                
                                </InputGroup>
                                <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>

                    <Flex justifyContent={'flex-end'}>
                        <Button
                            type="submit"
                            bgColor={'primary'}
                            isLoading={isSubmitting}
                            loadingText={t('Sending')}
                        >
                            {t('Reset Password')}
                        </Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    </Box>
    );
};

export default ResetPassword;
