import React from "react";
import ApiRoutes from "../../../api/services/api-routes";
import useGetData from "../../../api/useGetData";
import { useAppSelector } from "../../../redux/store";
import OrderCard from "../../../components/OrderCard";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../../components/NoDataFound";

const Orders = () => {
  const userData = useAppSelector((state) => state?.auth?.authData);
  const { t } = useTranslation();
  const { data, loading } = useGetData({
    route: ApiRoutes?.listOrders,
    params: { customer: userData?._id ,  sortByDate: 'issueDate',  sortOrder:-1  },
  });
  const allOrders = data?.result;

  if (allOrders?.length === 0) {
    return (
      <Flex w={"100%"} direction={"column"}>
        <Text
         // mt={4}
          mb={4}
          fontSize={30}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {t("My Orders")}
        </Text>
        <NoDataFound />
      </Flex>
    );
  }
  return (
    <Flex w={"100%"} direction={"column"}  >
      <Text
       // mt={4}
        mb={8}
        fontSize={30}
        fontWeight={"600"}
        color={"primary"}
        align="center"
      >
        {t("My Orders")}
      </Text>
      {allOrders?.map((order: any) => {
        return <OrderCard order={order} />;
      })}
    
    </Flex>
  );
};

export default Orders;
