import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface CompareListState {
  productIds: string[];
}

const initialState: CompareListState = {
  productIds: [],
};

export const compareListSlice = createSlice({
  name: "compareList",
  initialState,
  reducers: {
    addToCompareList: (
      state,
      action: PayloadAction<{ productId: string }>
    ) => {
      const { productId } = action.payload;

      if (
        state.productIds.length < 2 &&
        !state.productIds.includes(productId)
      ) {
        state.productIds.push(productId);
        // state.viewModal = true;
      }
      if (state.productIds.length === 2) {
      //  navigation?.navigate("CompareList");
      // onNavigate();
      }
    },
    removeFromCompareList: (state, action: PayloadAction<string>) => {
      state.productIds = state.productIds.filter(
        (itemId) => itemId !== action.payload
      );
    },

    clearCompareList: (state) => {
      state.productIds = [];
    },
  },
});

export const { addToCompareList, removeFromCompareList, clearCompareList } =
  compareListSlice.actions;

export default compareListSlice.reducer;
