import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setAuth } from "../redux/reducerSlices/Auth/authSlice"; // Adjust the import path as needed
 
import usePostData from "../api/usePostData"; // Adjust the import path as needed
import { jwtDecode } from "jwt-decode";

const useLoginAsGuest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { postData, loading } = usePostData();

  const loginAsGuest = async () => {
   
    const res = await postData({
      route: `guest`,
      successMsg: t("Welcome!"),
    });

    if (res?.error) {
      console.log(res.data?.error, "error");
      dispatch(
        setAuth({
          token: null,
          authInfo: {},
          authData: {},
        })
      );
    } else {
      localStorage.setItem("authInfo", JSON.stringify(res?.result));
      localStorage.setItem("token", res?.token);
      dispatch(
        setAuth({
          token: res?.token,
          authInfo: res?.result,
          authData: jwtDecode(res?.token),
        })
      );
      navigate("/");
    }
  };

  return { loginAsGuest, loading };
};

export default useLoginAsGuest;
