import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import style from "./style.module.css";
import SideMenu from "./side_menu/SideMenu";

interface LayoutProps {
  // children: ReactNode;
}

const ProfileLayout: React.FC<LayoutProps> = ({}) => {
  return (
    <Box className={style.Container} width={{base:'100%' , md:'80%'}}>
      <Box className={style.Content}  >
        <Flex className={style?.sideMenuContainer} >
          <Box className={style?.sideMenu}>
            <SideMenu />
          </Box>
        </Flex>

        <Outlet />
        
      </Box>
    </Box>
  );
};

export default ProfileLayout;
