import React, { useEffect, useState } from "react";
import useGetData from "../api/useGetData";
import ApiRoutes from "../api/services/api-routes";
import { Box, SimpleGrid } from "@chakra-ui/react";
import StoreCard from "./StoreCard";
import AppSkeleton from "../common/AppSkeleton";
import { defaultVariationOfProducts } from "../utils/utilsFunctions";
import EmptyData from "./EmptyData";
import NoDataFound from "./NoDataFound";
import ShowMore from "../common/ShowMore";

interface Card {
  id: number;
  title: string;
  description: string;
}
interface ViewCardsProp {
  route: string;
  categoryId: string | undefined;
  renderItem: (item: any) => JSX.Element;
  shopId?: any;
  storeId?: string | undefined;
  priceFrom?: any;
  priceTo?: any;
  tagId?: string | undefined;
  rankParam?: null | string;
  sortOrder?: null | string;
  discountValue?: null | string;
  sortByDate?: null | string;
}
const ViewCards: React.FC<ViewCardsProp> = ({
  route,
  categoryId,
  renderItem,
  shopId,
  storeId,
  priceFrom,
  priceTo,
  tagId,
  rankParam,
  sortOrder,
  discountValue,
  sortByDate,
}) => {

  const [filterParams, setFilterParams] = useState({
    categories: categoryId,
    shop: storeId,
    priceFrom: priceFrom,
    priceTo: priceTo,
    tags: tagId,
    limit: 8,
  });

  useEffect(() => {
    setFilterParams({
      categories: categoryId,
      shop: storeId,
      priceFrom: priceFrom,
      priceTo: priceTo,
      tags: tagId,
      limit: 8,
    });
  }, [categoryId, storeId,priceFrom,priceTo, tagId]);

  const { data, loading, getData, setData, loadMoreData, loadingMoreData } =
    useGetData({
      notLoadData: true,
      route,
      params:
        route === `${rankParam ? "variations/list":"products/list"}`
          ? {
              ...filterParams,
              rank: rankParam && rankParam,
              sortOrder: sortOrder && parseInt(sortOrder),
              discountValue: discountValue && parseInt(discountValue),
              sortByDate: sortByDate,
            }
          : filterParams,
      // onSuccess:
      //   route === "products/list"
      //     ? (res, setData) => {
      //         res.data.result = defaultVariationOfProducts(res?.data?.result);
      //         setData(res?.data);
      //       }
      //     : undefined,
    });

  useEffect(() => {
    getData({
      route,
      // params: filterParams,
      params:
        route === `${rankParam || priceFrom>0 ? "variations/list":"products/list"}`
          ? {
              ...filterParams,
              rank: rankParam && rankParam,
              sortOrder: sortOrder && parseInt(sortOrder),
              discountValue: discountValue && parseInt(discountValue),
              sortByDate: sortByDate,
            }
          : filterParams,
    });
  }, [filterParams, rankParam, sortOrder, discountValue, sortByDate]);

  return (
    <Box mt={8} width={"100%"}>
      {loading ? (
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 3 }} spacing={10}>
          {Array.from({ length: 8 }).map((_, index) => (
            <AppSkeleton key={index} width="314px" height="405px" />
          ))}
        </SimpleGrid>
      ) : data?.result?.length ? (
        <>
          <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 3 }} spacing={10}>
            {data?.result?.map((item: any) => (
              <React.Fragment key={item._id}>{renderItem(item)}</React.Fragment>
            ))}
          </SimpleGrid>
          <Box  display="flex" justifyContent="center" mt={4}>
            <ShowMore
            style={{width:"100%",marginTop: 60}}
              data={data?.result}
              count={data?.count}
              loading={loadingMoreData}
              loadMoreData={loadMoreData}
            />
          </Box>
        </>
      ) : (
        <NoDataFound />
      )}
    </Box>
  );
};

export default ViewCards;
