import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// import { moderateScale } from '../utils/ResponsiveDimentions';
import stare from '../assets/images/imgs/stare.png';

interface Props {
  rate: number;
  reviewNumber?: number;
  textColor?: string;
  imgColor?: string;
  disappear?: boolean;
}

const Review: React.FC<Props> = ({
  rate,
  reviewNumber,
  textColor,
  imgColor,
  disappear,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Image
        src={stare}
        alt="Star"
        boxSize={"20px"}
        borderRadius={"50%"}
        objectFit="contain"
        //tintColor={imgColor}
      />
      <Text color={textColor}>
        {rate || 0} {!disappear && `(${reviewNumber || 0} ${t('Review')})`}
      </Text>
    </Box>
  );
};

export default Review;
