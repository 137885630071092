import io from 'socket.io-client';
import { END_POINT } from '../api/services/axios';
 
export const initializeSocket = ({userId,userRole,userToken}:{userId:string, userRole:string, userToken:string}) => {
      const extraHeaders = {
        role: userRole,
        _id: userId,
        token:userToken
      }
      // console.log("Extra_Headers",extraHeaders);
      
      const socket =  io(END_POINT, {
        path: '/socket.io',
        transports: ['websocket', 'xhr-polling'],
        auth: extraHeaders,
        reconnection: true,
        autoConnect: true,
        extraHeaders
    });
    const handleConnect = () => {
      console.log("Connected to server");
    };
  socket.on("connect", handleConnect);
  return socket;
};
