import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Img, Spinner, Text } from "@chakra-ui/react";
import style from "./style.module.css";
import moment from "moment";
import ChatBox from "./ChatBox";

import { CloseIcon } from "@chakra-ui/icons";
import { empty_chat } from "../../../assets/images/index";

import { IoChevronBackCircleOutline } from "react-icons/io5";
import { RootState } from "../../../redux/store";
import useGetData from "../../../api/useGetData";
import { setChatMenuData } from "../../../redux/reducerSlices/ChatMenu/chatMenu";
import RipplesButton from "../../RipplesButton";
import { CiCamera } from "react-icons/ci";
import SocketClass from "../../../utils/Socket";
import { useSocket } from "../../../Socket/SocketProvider";
import { useTranslation } from "react-i18next";
import useLang from "../../../hooks/useLang";

export default function Rooms() {
  const {t} = useTranslation()
  const {isAr } = useLang()
   const authInfo = useSelector<RootState, any | undefined>(
    (state) => state?.auth?.authData
  );
  const { list, chatType, chatBox, fastBox } = useSelector<
    RootState,
    any | undefined
  >((state) => state?.chatMenu.chatMenuData);
  // const socket = SocketClass.getInstance({userId:authInfo._id});
  const socket=useSocket()


  const {
    data: rooms,
    loading,
    getData,
    setData
  } = useGetData({
    route: `rooms/list`,
    notLoadData: true,
    params: { customer: authInfo._id, withAdmin: false },
  });
  const dispatch = useDispatch();
  const Data = rooms?.result?.[0];

  useEffect(() => {
    if (list && chatType === "customers") {
      getData({
        route: `rooms/list`,
        params: { customer: authInfo._id, withAdmin: false },
      });
    }
    if (chatType === "support") {
      getData({
        route: `rooms/list`,
        params: { customer: authInfo._id, withAdmin: true },
      });
    }
    if (!fastBox) {
      dispatch(setChatMenuData({ chatMenuData: { chatBox: false } }));
    }
  }, [list, chatType]);

  const markAsRead = (item:any, index:any)=>{
    socket?.emit("markAsRead", { "roomId": item._id } , (res:any) => {
      console.log("Acknowledgment from server:", res);
      setData((prev:any) => ({ ...prev, result: prev.result.map((item:any, idx:any) => { if (idx === index) { return {  ...item,   unreadCount: 0 }; } return item; })}));
    });
  }
  const repeatedData = new Array(10).fill(Data);
  return (
    <>
      <Flex  className={style.chat_nav} >
        {chatType === "support" && (
          <RipplesButton
            text={<IoChevronBackCircleOutline  style={{ transform: isAr? "rotate(180deg)" :''}}/>}
            onClick={() => {
              dispatch(
                setChatMenuData({
                  chatMenuData: {
                    chatMenu: chatType === "support" ? false : true,
                    list: false,
                    chatBox: false,
                  },
                })
              );
            }}
            className={`${style.back_arrow}`}
          />
        )}
        <Text className={style.chat_nav_li}>
          {chatType === "customers" ?t( "Sellers") : t("Sona3 Admin")}
        </Text>


        <RipplesButton    
          onClick={() => {
            dispatch(
              setChatMenuData({
                // chatMenuData: { chatMenu: false, list: false },

                chatMenuData: {
                  active: false,
                  chatMenu: false,
                  list: false,
                  chatBox: false,
                  withAdmin: false,
                  fastBox: false,
                  chatType: "",
                  sellerId: "",
                  roomId: "",
                },
              })
            );
          }}
          text={<CloseIcon  />}
          className={style.close_icon}
        />
      </Flex>

      {!chatBox ? (
        <Box  className={style.List_Container}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {rooms?.count > 0 ? (
                <>
                  {rooms?.result.map((item: any, index: any) => (
                    <Flex
                    
                      className={style.list_li}
                      onClick={() => {
                        markAsRead(item, index)
                        dispatch(
                          setChatMenuData({
                            chatMenuData: {
                              chatBox: true,
                              roomId: item._id,
                              sellerId: item?.seller?._id,
                            },
                          })
                        );
                      }}
                    >
                      <Img
                        width={"35px"}
                        src={item?.seller?.image?.Location}
                        className={style.list_li_img}
                        bgColor={"#eee"}
                      />
                      <Box>
                        <Text className={style.list_li_name}>
                          {item?.seller?.userName}
                        </Text>
                        {item?.lastMessage?.text ? (
                          <Text className={style.list_li_message}>
                            {item?.lastMessage?.text}
                          </Text>
                        ) : (
                          <Flex
                            className={style.list_li_message}
                            alignItems="center"
                          >
                            <CiCamera size={"22px"} />
                            <Text mx={2}> Photo</Text>
                          </Flex>
                        )}
                        <Text className={style.list_li_date}>
                          {moment(item?.lastDate).fromNow()}
                        </Text>
                      </Box>
                { item?.unreadCount > 0 && <Box className={style.list_unreadCount} >{item?.unreadCount}</Box>}

                    </Flex>
                  ))}
                </>
              ) : (
                <>
                  <Img
                    src={empty_chat}
                    className={style.empty_image}
                    width={"100%"}
                  />
                  <Text>
                    {chatType === "support"
                      ? "No Chat Rooms Yet"
                      : "No chats from customers"}
                  </Text>
                  {chatType === "support" && (
                    <RipplesButton
                      text={"Start Chat"}
                      onClick={() => {
                        dispatch(
                          setChatMenuData({
                            chatMenuData: {
                              chatBox: true,
                              chatType: "support",
                            },
                          })
                        );
                      }}
                      className={`${style.start_Btn} ${style.start_Btn_customer}`}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      ) : (
        <ChatBox />
      )}
    </>
  );
}
