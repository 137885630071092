import React from 'react';
import { Box, Button, Text, VStack, Icon } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const FailurePaymentScreen = ({mobile}:{mobile?:boolean}) => {
    const navigate = useNavigate()
  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    //   bg="gray.100"
      p={4}
    >
      <VStack
        spacing={6}
        p={8}
        bg="white"
        rounded="lg"
        // shadow="lg"
        maxW="md"
        textAlign="center"
      >
        <Icon as={WarningIcon} w={'10vh'} h={'10vh'} color="red.400" />
        <Text fontSize="2xl" fontWeight="bold" color="red.500">
          {t('Payment Failed')}
        </Text>
        <Text fontSize="md" color="gray.500">
          {t('Unfortunately, your payment could not be processed. Please try again or contact support.')}
        </Text>
                {/* <Button colorScheme="red" size="lg">
                Retry Payment
                </Button> */}
        {!mobile &&<Button 
        onClick={()=>{
            navigate('/')
            window.location.reload()
        }}
        size="lg" colorScheme="red">
        {t('Continue')}
        </Button>}
      </VStack>
    </Box>
  );
};

export default FailurePaymentScreen;
