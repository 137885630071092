import { initializeApp } from "firebase/app";
import 'firebase/auth';
import {signInWithPhoneNumber, getAuth , RecaptchaVerifier } from "firebase/auth";
import { console_log, showMSG } from "./utilsFunctions";
import i18n from "../i18n";
// const firebaseConfig = {
//   apiKey: "AIzaSyBEqzAE1FPKQU2qysyV4wsGcTJK09W67F0",
//   authDomain: "sona3-79411.firebaseapp.com",
//   projectId: "sona3-79411",
//   storageBucket: "sona3-79411.appspot.com",
//   messagingSenderId: "871550283430",
//   appId: "1:871550283430:web:752fbb3710d8a229fdce67",
//   measurementId: "G-NDS5EL3TTL"
// };
const firebaseConfig = {
  // apiKey: "AIzaSyBIGzuPonOuyUXkBDEowRlkij54Ry9IG-E",
  // authDomain: "sonna3-customer-3ab45.firebaseapp.com",
  // projectId: "sonna3-customer-3ab45",
  // storageBucket: "sonna3-customer-3ab45.appspot.com",
  // messagingSenderId: "949168925515",
  // appId: "1:949168925515:web:d1af66ea225c4f3ec096ce",
  // measurementId: "G-3GM7S09YVT"
  apiKey: "AIzaSyBte4z8mbVa1Ln_TH-aZWZAh0YneMqkvYg",
  authDomain: "sona3-79411.firebaseapp.com",
  projectId: "sona3-79411",
  storageBucket: "sona3-79411.appspot.com",
  messagingSenderId: "871550283430",
  appId: "1:871550283430:web:60dd7e476edfe2aefdce67",
  measurementId: "G-X5HDC3Q3DS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
auth.languageCode = 'ar';



export function onCaptchVerify(phone:any, toast?:any, setLoading?:any ) {
  if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'invisible',
          'callback': (response:any) => {
              //reCAPTCHA solved, allow signInWithPhoneNumber.
              sendOTP(phone, toast, setLoading);
          },

          "expired-callback": () => {
            window.location.reload();

          },

          });
  }
}


export function sendOTP(phone:any, toast?:any, setLoading?:any) {
  setLoading(true);
  onCaptchVerify(phone, toast);
  const appVerifier = window.recaptchaVerifier;
  if (!appVerifier) { /*setLoading(false);*/ return; }
  const formatPh = "+971" + phone;
  console.log({formatPh})

  signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          console_log({confirmationResult})
          // setShowOTP(true);
          showMSG(toast, 'success', i18n.t('OTP sent successfully!'))
      })
      .catch((error) => {
        const errorMSG = firebaseErrorMessage(error)
          console.error( {error});
          // errorMSG&&   showMSG(toast, 'error',errorMSG)
          errorMSG &&   showMSG(toast, 'success',i18n.t(`${errorMSG}`))
          // window.location.reload()
         setLoading(false);
      });
}



export const firebaseErrorMessage = (error: any) => {
  if (error.code === "auth/session-expired") {
    return i18n.t(`The SMS code has expired. Please re-send the verification code to try again.`);
  } else if (error.code === "auth/invalid-verification-code") {
    return i18n.t(`The verification code is invalid. Please enter a valid code.`);
  } else if (error.code === "auth/missing-verification-code") {
    return i18n.t("No verification code provided.");
  } else if (error.code === "auth/missing-phone-number") {
    return i18n.t("No phone number provided for authentication.");
  } else if (error.code === "auth/quota-exceeded") {
    return i18n.t("Quota exceeded for sending verification codes.");
  } else if (error.code === "auth/user-disabled") {
    return i18n.t("The user account has been disabled by an administrator.");
  } else if (error.code === "auth/unauthorized-continue-uri") {
    return i18n.t("Unauthorized continue URL.");
  } else if (error.code === "auth/captcha-check-failed") {
    window.location.reload()
    return  i18n.t("reCAPTCHA verification failed.")
    // "reCAPTCHA verification failed.";
  } else if (error.code === "auth/invalid-continue-uri") {
    return i18n.t("Invalid continue URL.");
  } else if (error.code === "auth/invalid-phone-number") {
    return i18n.t("The provided phone number is not valid.");
  } else if (error.code === "auth/operation-not-allowed") {
    return i18n.t("The requested authentication operation is not allowed.");
  } else {
    console.log(error , 'firebase error')
    return i18n.t("An error occurred during code confirmation.");
  }
  return i18n.t("unKnown Error");
};
