import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useToast,
  Image,
  Textarea,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { StarIcon } from "@chakra-ui/icons";
import Review from "./Review";
import { useAppSelector } from "../redux/store";
import usePostData from "../api/usePostData";
import ApiRoutes from "../api/services/api-routes";
import { Rating } from "react-simple-star-rating";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { userIcon } from "../assets/images";

type AddYourReviewProps = {
  type: string;
  _id: string;
  setIsFound: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddYourReview: React.FC<AddYourReviewProps> = ({
  type,
  _id,
  setIsFound,
}) => {
  const [customerReview, setCustomerReview] = useState("");
  const [rating, setRating] = useState<number>(3);
  const { t } = useTranslation();
  const toast = useToast();
  const userData = useAppSelector((state) => state?.auth?.authInfo);
  const userImg = useAppSelector((state) => state?.auth);


  const {
    data: postReviewData,
    loading: postReviewLoading,
    setData: setPostReviewData,
    postData: postReview,
  } = usePostData();
  const createReview = async () => {
    try {
      if (rating <= 0) {
        toast({
          title: t("Error"),
          description: t("Rating is required"),
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top-right',

        });
        return;
      }
      postReview({
        route: ApiRoutes?.addReview,
        data: {
          customer: userData?._id,
          reviewDate: new Date()?.toISOString(),
          reviewText: customerReview,
          reviewOn: type,
          [type]: _id,
          rating: rating,
        },
        successMsg:t("Review added successfully")
        // params: {customer: customerId, item: variationId, quantity: 1},
      });
      // Your review creation logic here...

      setIsFound(true);
    } catch (error) {
      toast({
        title: t("Error"),
        //  description: error?.response?.data?.error || t('An error occurred'),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const ratingChanged = (newRating: number) => {
    setRating(newRating);
  };
  return (
    <Box>
      <Text fontWeight="bold" paddingBottom="5px">
        {t("Add Your Review")}
      </Text>
      <Flex alignItems="flex-start">
        <Image
          src={userData?.image?.Location || userIcon}
          borderRadius="50%"
          width="55 px"
          height="55px"
          objectFit='contain'
          
          // bgColor={'#ccc'}
          // borderWidth={1}
          // borderColor={'#ddd'}

        />
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          marginLeft="10px"
        >
          <Review rate={5} disappear textColor={"primary"} />
          <Text>{userData?.name}</Text>
        </Flex>
      </Flex>
      <Box paddingY="5px">
        <ReactStars
          count={5}
          value={rating}
          onChange={ratingChanged}
          size={28}
          isHalf={false}
          activeColor="#88050D"
        />
        {rating}
      </Box>
      <Textarea
        value={customerReview}
        onChange={(e) => setCustomerReview(e.target.value)}
        placeholder={t("Your Review...")}
        size="lg"
        borderColor="#4D473E"
      />
      <Button size="md" mt="4" onClick={createReview}>
        {t("Submit")}
      </Button>
    </Box>
  );
};

export default AddYourReview;
