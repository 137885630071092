import React, { useRef, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Spinner,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { IoSendOutline } from "react-icons/io5";
import { IoMdAttach } from "react-icons/io";
// Import your custom button component
import style from "./style.module.css";
import RipplesButton from "../../RipplesButton";
import { useTranslation } from "react-i18next";

function ChatBottom({
  onSendMsg,
  onUploadImage,
  loading,
  loadingSendText,
}: {
  onSendMsg: any;
  onUploadImage: any;
  loading: Boolean;
  loadingSendText: Boolean;
}) {
  const {t} = useTranslation()
  const inputRef: any = useRef();
  const [message, setMessage] = useState("");
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };
  const handleSendMessage = () => {
 
    setMessage("");
    onSendMsg(message.trim());
  };

  return (
    <Box className={style.Chat_Bottom}>
      <Flex borderTop={"solid 1px grey"}>
        <Textarea
          onChange={(e) => handleMessageChange(e)}
          value={message}
          placeholder={t("Type a message...")}
          rows={1}
          height={"38px"}
          resize="none"
          className={style.message_input}
        />

        <Flex className={style.attach_btn_flex}>       
            <IoMdAttach
              onClick={(e) => {
                inputRef.current.click();
              }}
            />     
        </Flex>
        <Input
          type="file"
          accept="image/*"
          hidden
          ref={inputRef}
          onChange={onUploadImage}
        />
        <Flex className={style.send_btn_flex}>
          {loadingSendText ? (
            <Spinner size={"sm"} />
          ) : (
            <RipplesButton
              text={<IoSendOutline />}
              onClick={handleSendMessage}
              className={`${style.Send_Button}`}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

export default ChatBottom;
