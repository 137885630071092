import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { useOrder } from './Context';
import ApiRoutes from '../../../api/services/api-routes';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetData from '../../../api/useGetData';
import usePostData from '../../../api/usePostData';
import { useEffect, useState } from 'react';
import { console_log } from '../../../utils/utilsFunctions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import { getTotalCartLength } from '../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice';

const CheckoutStep = () => {

  const dispatch = useAppDispatch();


  const { postData, loading: loadingPlaceOrder } = usePostData();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { order, handlePrevious } = useOrder();
  const [totalCalculations, setTotalCalculations] = useState<any>({});
  const isFood = params.get("isFood") && params.get("isFood") === "true";
  const {t} = useTranslation()
  const { getData, loading } = useGetData({
    route: ApiRoutes?.calculateTotal,
    params: { customer: order.details.customer, basket: params.get("cart_id") },
    notLoadData: true
  });

  const getTotal = async () => {
    console.log('Getting')
    console.log(order.details.shippingAddress.address.city?.CityCode, params.get("city"))
    try {
      if (order.details.shippingAddress.address.city?.CityCode === params.get("cityCode") || (order.details.shippingAddress.address.city?.code === params.get("cityCode"))) {
        const res = await getData({
          route: ApiRoutes?.calculateTotal,
          params: isFood
            ? { customer: order.details.customer, basket: params.get("cart_id") }
            : { customer: order.details.customer, cart: params.get("cart_id"), CityCode: order.details.shippingAddress.address.city?.CityCode }
        });


        if (isErrorResponse(res)) {
          console.error("Error fetching total:", res.error);
        } else {
          console.log("calculateTotalResponse", res?.data?.result);
          setTotalCalculations(res.data.result);
        }
      }
    } catch (error) {
      console.error("Error fetching total:", error);
    }
  };

  useEffect(() => {
    getTotal();
  },
    [order.details.shippingAddress.address, order.details.shippingAddress.address.cityCode]
  );

  const PlaceOrder = async () => {
    try {
      const checkoutData = isFood ?
        { customer: order.details.customer, shippingAddress: order.details.shippingAddress, paymentMethod: order.paymentMethod  ,  issueDate:new Date().toISOString()}
        :
        { customer: order.details.customer, shippingAddress: order.details.shippingAddress, paymentMethod: order.paymentMethod, shippingCost: totalCalculations.shippingFeesTotal , issueDate:new Date().toISOString() }
          // console.log({checkoutData} , 'checkoutData')
          // return ;
         const res = await postData({ route: isFood ? ApiRoutes?.basketOrder : ApiRoutes?.cartOrder, data: checkoutData });

      if (!res?.error) {
        if (order.paymentMethod === "visa") {
          window.open(res?.result);
        } else {
          navigate('/profile/orders');
        }
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }

    dispatch(getTotalCartLength());

  };

  const renderShippingFees = () => {
    if (isFood) {
      // When isFood is true, shippingFeesTotal is a number
      return typeof totalCalculations.shippingFeesTotal === 'number'
        ? totalCalculations.shippingFeesTotal
        : 'N/A';
    } else {
      // When isFood is false, shippingFeesTotal is an object
      if (totalCalculations.shippingFeesTotal && typeof totalCalculations.shippingFeesTotal === 'object') {
        return Object.entries(totalCalculations.shippingFeesTotal).map(([key, value]) => (
          key === "total" &&
          <div key={key}>
            {typeof value === 'number' ? value : 'N/A'}
          </div>
        ));
      }
      return 'N/A';
    }
  };
  console.log(totalCalculations)
  return (
    <Box width={'30%'} margin='auto' >
      <div>
      <Text
        fontWeight={"900"}
        color={"primary"}
        mb={4}
        fontSize={20}
        textDecorationLine={'underline'}
      >
        {t('Payment details')} {loading && <Spinner />}
      </Text>
        <Flex my={2} ><Text fontWeight={'bold'} >{t('Cart Total')}:</Text> <Text mx={2}> {totalCalculations.cartTotal || ''}</Text> </Flex>
        <Flex my={2}><Text fontWeight={'bold'} >{t('Shipping Fees Total')}:</Text> <Text mx={2}> {renderShippingFees() || ''}</Text> </Flex>
        <Flex my={2}><Text fontWeight={'bold'} >{t('Taxes Total')}:</Text> <Text mx={2}> {totalCalculations.taxesTotal || ''}</Text> </Flex>
        <Flex my={2}><Text fontWeight={'bold'} >{t('Order Total')}:</Text> <Text mx={2}> {totalCalculations.orderTotal || ''}</Text> </Flex>

      </div>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box textAlign={"start"} marginTop={"50px"}>
          <Button onClick={() => { handlePrevious() }}> {t('Go Previous')} </Button>
        </Box>
        <Box textAlign={"end"} marginTop={"50px"}>
          <Button isLoading={loadingPlaceOrder} onClick={PlaceOrder} colorScheme="teal" type="submit">{t('Place order')}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutStep;

function isErrorResponse(response: any): response is { error: any } {
  return (response as { error: any }).error !== undefined;
}
