import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import authReducer from "./reducerSlices/Auth/authSlice";
import wishlistReducer from "./reducerSlices/Wishlist/wishlistSlice"
import compareListReducer from "./reducerSlices/CompareList/compareListSlice"
import lengthOfCartSlice from "./reducerSlices/LengthOfCart/lengthOfCartSlice";
import  chatMenuSlice  from "./reducerSlices/ChatMenu/chatMenu";

const reuducer = combineReducers({
  auth: authReducer,
  wishlist: wishlistReducer,
  compareList: compareListReducer,
  TotalCartLength:lengthOfCartSlice,
  chatMenu:chatMenuSlice

});

export const store = configureStore({
  reducer: reuducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
