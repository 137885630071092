import React from "react";
import AppField from "../common/AppField";
import {
  lang,
  onChangeDynamicFormFieldValueProps,
} from "../utils/utilsFunctions";
import { Box, FormControl, Input, Text } from "@chakra-ui/react";
import SearchSelectInput from "../common/SearchSelectInput";
import DynamicSearchSelectInput from "./DynamicSearchSelectInput";

const DynamicFiled = ({
  filed,
  index,
  onChange,
}: {
  filed: any;
  index: number;
  onChange: (props: onChangeDynamicFormFieldValueProps) => void;
}) => {
  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    // eslint-disable-next-line no-useless-escape
    const value = e.target.value
 const isValidNumber =  /^\d{1,10}$/.test(value); // regex for valid number
// const value = e.target.value.replace(/[^0-9]/g, '');
        if (isValidNumber) {
      onChange({
        isEnum: false,
        fields: [],
        index: index,
        item: filed,
        value: value,
      });
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidChars = ["-", "+", "e", "E"];

    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  if (filed?.type === "enum") {
   
    return (
      <Box my={3}>
        <DynamicSearchSelectInput
          optionLabel={
            filed?.values?.[0]?.en ? lang.toLocaleLowerCase() : `number`
          }
          optionValue={`${lang.toLocaleLowerCase() || "number"}`}
          options={filed?.values}
          loading={false}
          onEndReached={() => {}}
          name={filed?._id}
          placeholder="Select Form"
          title={"Product Form"}
          isMulti={false}
          index={index}
          onChange={onChange}
        />
      </Box>
    );
  }

  if (filed?.value?.en) {
   
    return (
      <>
        <Box my={3}>
          <Text color={"primary"}>{filed?.[`name${lang}`]} En</Text>
          <Input
            placeholder={filed?.[`description${lang}`]}
            variant="outline" // Apply the 'outline' variant here
            borderColor="#8A7475"
            boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
            backgroundColor={"#F0F0F0"}
            _focus={{
              borderColor: "#F0F0F0",
              // boxShadow
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            h="50px"
            value={filed?.value?.en}
            onChange={(e) => {
              onChange({
                isEnum: false,
                fields: [],
                index: index,
                item: filed,
                value: e?.target?.value,
                type: "en",
              });
            }}
            isInvalid={!!filed?.error}
          />
          <Text color={"#e53e3e"}>{filed?.error}</Text>
        </Box>
        <Box my={3}>
          <Text color={"primary"}>{filed?.[`name${lang}`]} Ar</Text>
          <Input
            placeholder={filed?.[`description${lang}`]}
            variant="outline" // Apply the 'outline' variant here
            borderColor="#8A7475"
            boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
            backgroundColor={"#F0F0F0"}
            _focus={{
              borderColor: "#F0F0F0",
              // boxShadow
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            h="50px"
            value={filed?.value?.ar}
            // noOfLines={10}
            onChange={(e) => {
              onChange({
                isEnum: false,
                fields: [],
                index: index,
                item: filed,
                value: e?.target?.value,
                type: "ar",
              });
            }}
            // type={type}
            // required={true}
            // height={'20vh'}
            // noOfLines={10}
            isInvalid={!!filed?.error}
          />
          <Text color={"#e53e3e"}>{filed?.error}</Text>
        </Box>
      </>
    );
  }


  return (
    <Box my={3}>
      <Text color={"primary"}>{filed?.[`name${lang}`]}</Text>
      <Input
        // type="number"
        //   placeholder={filed?.[`description${lang}`]}
        type="number" // Use text type to enforce custom validation
        inputMode="numeric" // Hint for virtual keyboards to use numeric input
        pattern="^\d{1,10}$" // Regex pattern to allow only valid numbers
        placeholder={filed?.[`description${lang}`]}
        variant="outline" // Apply the 'outline' variant here
        // borderColor="#8A7475"
        boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
        backgroundColor={"#F0F0F0"}
        _focus={{
          borderColor: "#F0F0F0",
          // boxShadow
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
        h="50px"
        value={filed?.value?.number}
  
        isInvalid={!!filed?.error}
 
        // onChange={(e) => {
        //   onChange({
        //     isEnum: false,
        //     fields: [],
        //     index: index,
        //     item: filed,
        //     value: e?.target?.value,
        //   });
        // }}
        onChange={(e) => {
          const value = e?.target?.value;
          console.log(
            e?.target?.value,
            "updated filed is number the new value x"
          );
          if (/^-?[0-9]+$/.test(value) && value) {
            onChange({
              isEnum: false,
              fields: [],
              index: index,
              item: filed,
              value: e?.target?.value,
            });
          } else{
            onChange({
              isEnum: false,
              fields: [],
              index: index,
              item: filed,
              value:"",
            });
          }
        }}
       // onChange={handleNumberChange}
        //  onKeyDown={handleKeyDown}
      />
      <Text color={"#e53e3e"}>{filed?.error}</Text>
    </Box>
  );
};

export default DynamicFiled;
