import axios from "axios";
import { store } from "../../redux/store";
import i18n from "../../i18n";
import { logout } from "../../redux/reducerSlices/Auth/authSlice";
import { showToastMessage } from "../../utils/utilsFunctions";

// import useLoginAsGuest from '../../hooks/useLoginAsGuest';

// export const END_POINT = "https://sona3-test-3918329fd6a7.herokuapp.com";
export const  END_POINT= "https://sona3-dev-063c58a7c396.herokuapp.com"
export const BASE_END_POINT = `${END_POINT}/api/v1/customer/`;

const baseURL = BASE_END_POINT;
const Axios = axios.create({ baseURL });
Axios.defaults.baseURL = baseURL;
Axios.defaults.headers["x-app-token"] = "Sona3-Team";
Axios.defaults.headers["accept-language"] = i18n.language;

let cancelTokens: (() => void)[] = [];

const cancelAllRequests = () => {
  cancelTokens.forEach((cancel) => cancel());
  cancelTokens = [];
};

Axios.interceptors.request.use(async (req: any) => {
  const token = store.getState().auth?.token;
  const user = store.getState().auth?.authInfo;

  //don't check the token in login or register
  if (
    req.url === "login" ||
    req.url === "register" ||
    req.url === "social" ||
    req.url === "guest"
  ) {
    return req;
  }

  if (!req?.url.includes("/list")) {
    const updatedParams = { ...req?.params };
    delete updatedParams?.page;
    delete updatedParams?.limit;
    delete updatedParams?.pageNo;
    req.params = updatedParams;
  }

  if (!token) {
    store.dispatch(logout());
    showToastMessage("there is no token");
    // return Promise.reject({
    //   response: {data: {error: ('Your session is invalid')}},
    // });
  }

  // todo : move inside interceptor
  // if(!req.headers.Authorization )
  if (req.url === "reset-password") {
    req.url = "password";
  } else {
    req.headers.Authorization = `Bearer ${token}`;
  }

  ///to handle form data
  if (req.data instanceof FormData) {
    req.headers["Content-Type"] = "multipart/form-data";
  }

  // Add cancel token to the request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  req.cancelToken = source.token;
  cancelTokens.push(source.cancel);

  if (req.method === "get") {
    if (user?.isPhoneVerified === false || user?.isEmailVerified === false) {
      console.log("get Request");
      return Promise.reject({
        response: { data: { error: "canceled" } },
      });
    }
  }

  return req;
});

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      if (
        err?.response?.data?.error === ".رمز التحقق غير صالح للاستخدام" ||
        err?.response?.data?.error === "Invalid Token."
      ) {
        cancelAllRequests();
        store.dispatch(logout());
      }
      // not active customer
      // ".ليس مسموحاً لك باستخدام هذا الخادم"
      // "You are not allowed to use this server."
      
      // cancelAllRequests();

      // store.dispatch(logout());
      // window.location.reload();
    } else {
    }

    return Promise.reject(err);
  }
);

export default Axios;
