import React from "react";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/use-is-mobile";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";

export default function EditProfileForm() {
  const { t } = useTranslation();
  const [mobile] = useIsMobile();
  const primary = "#88050D";

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      city: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string(),
      country: Yup.string(),
      city: Yup.string(),
    }),
    onSubmit: (values) => {},
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Flex direction="column" gap="1rem">
          <Flex
            justify="space-between"
            direction={mobile ? "column" : "row"}
            gap="1rem"
          >
            <Box w={mobile ? "100%" : "45%"}>
              <label htmlFor="firstName" style={{ fontWeight: "bold" }}>
                {t("First Name")}
              </label>
              <Input
                bg="white"
                rounded={0}
                focusBorderColor={primary}
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <Text>{formik.errors.firstName}</Text>
              ) : null}
            </Box>
            <Box w={mobile ? "100%" : "45%"}>
              <label htmlFor="lastName" style={{ fontWeight: "bold" }}>
                {t("Last Name")}
              </label>
              <Input
                bg="white"
                rounded={0}
                focusBorderColor={primary}
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <Text>{formik.errors.lastName}</Text>
              ) : null}
            </Box>
          </Flex>
          <Box>
            <label htmlFor="email" style={{ fontWeight: "bold" }}>
              {t("Email")}
            </label>
            <Input
              bg="white"
              rounded={0}
              focusBorderColor={primary}
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <Text>{formik.errors.email}</Text>
            ) : null}
          </Box>
          <Box>
            <label htmlFor="country" style={{ fontWeight: "bold" }}>
              {t("Country")}
            </label>
            <Input
              bg="white"
              rounded={0}
              focusBorderColor={primary}
              id="country"
              name="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.country}
            />
            {formik.touched.country && formik.errors.country ? (
              <Text>{formik.errors.country}</Text>
            ) : null}
          </Box>
          <Box>
            <label htmlFor="city" style={{ fontWeight: "bold" }}>
              {t("City")}
            </label>
            <Input
              bg="white"
              rounded={0}
              focusBorderColor={primary}
              id="city"
              name="city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.city}
            />
            {formik.touched.city && formik.errors.city ? (
              <Text>{formik.errors.city}</Text>
            ) : null}
          </Box>

          <Flex justify="center" gap="1rem">
            <Button colorScheme="teal" variant="ghost">
              {t("Cancel")}
            </Button>
            <Button type="submit" rounded={3} px="2rem">
              {t("Save Changes")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
