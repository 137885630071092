import React from "react";
import { Box, Flex, Step, StepIndicator, StepNumber, StepStatus, Stepper, StepTitle, StepSeparator, Text, StepIcon, } from "@chakra-ui/react";
import { steps } from "./types";
import { useOrder } from "./Context";
import { useTranslation } from "react-i18next";

export default function Steps() {


  const { activeStep } = useOrder();
  const {t} = useTranslation()
  return (
    <Flex  direction="column" align="center" py={20} w="100%">
      <Box  w="80%" mb={10}>
        <Stepper w="100%" colorScheme="#a4020c" size="md" index={activeStep} orientation="horizontal">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator
                borderColor={activeStep === index ? "primary" : "inherit"}
                bgColor={activeStep === index ? "primary" : "inherit"}
                color={activeStep === index ? "#fff" : "inherit"}
              >
                <StepStatus
                  complete={<StepIcon borderRadius="50%" w={7} h={7} bgColor="primary" />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <Text as={StepTitle} color={activeStep === index ? "primary" : "inherit"}>
                  {t(step.title)}
                </Text>
              </Box>
              {index < steps.length - 1 && (
                <StepSeparator
                  style={
                    activeStep === index + 1
                      ? { borderColor: "#a4020c", color: "#a4020c", backgroundColor: "#a4020c" }
                      : { borderColor: "gray", color: "gray", backgroundColor: "gray" }
                  }
                />
              )}
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box w="80%" mb={10}>
        {steps[activeStep].component}
      </Box>
    </Flex>
  )
}
