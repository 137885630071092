import React, { ReactNode } from "react";
import { store, useAppSelector } from "./../../redux/store";
import { Outlet } from "react-router-dom";

import SearchHeader from "./Navbar/components/SearchHeader";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import { Box, Flex, Text } from "@chakra-ui/react";
import { mainBackground } from "../../assets/images";
import CategoriesNav from "./CategoriesNav";
import CategoriesHeader from "../CategoriesHeader";
import ChatSpace from "../Chat";
import CategoriesDrawer from "./CategoriesDrawer";

interface LayoutProps {
  // children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ }) => {
  const token = store.getState().auth.token;
  const customerId = useAppSelector(state => state?.auth?.authData?._id)

  return (
    <Box>
      <Box
        position={"sticky"}
        top={0}
        width={"100%"}
        zIndex={1001}
        bgSize={"contain"}
      >
        <Navbar />

        <Flex display={{base:"flex" , md:'block'}}>
          <CategoriesDrawer />
                    <SearchHeader />

        </Flex>

        <CategoriesNav />
      </Box>
      <Box pt={{}}>
        <Outlet />
      </Box>
      <Footer />
      {token && customerId !== "guest" && <ChatSpace />}
    </Box>
  );
};

export default Layout;
