// NotFound.tsx
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Box textAlign="center" py={10} px={6}>
      <Text fontSize="xl" fontWeight="bold">
        {t('404 - Page Not Found')}
      </Text>
      <Text mt={4}>
        {t('The page you are looking for does not exist.')}
      </Text>
    </Box>
  );
};

export default NotFound;
