// OrderContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Order, OrderContextProps, steps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

// Create context
const OrderContext = createContext<OrderContextProps | undefined>(undefined);

interface OrderProviderProps {
  children: ReactNode;
}

// Provider component
export const OrderProvider: React.FC<OrderProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { authInfo } = useSelector<RootState, any | undefined>(state => state?.auth);
  const isFood = params.get("isFood") && params.get("isFood") === "true";

  const [order, setOrder] = useState<Order>({
    details: {
      customer: authInfo?._id,
      shippingAddress: {
        location: {
          type: 'Point',
          coordinates: [0, 0], // Default to [0, 0] until location is fetched
        },
        address: isFood
          ? {
              country: params.get("country") || '',
              city: params.get("city") || '',
              street: params.get("street") || '',
              remarks: params.get("remarks") || '',
            }
          : {
              country: params.get("country") || '',
              city: params.get("city") || '',
              street: params.get("street") || '',
              cityCode: params.get("cityCode") || '',
              remarks: params.get("remarks") || '',
            },
      },
    },
    cost: {
      cartTotal: 0,
      taxesTotal: 0,
      shippingFeesTotal: 0,
    },
    paymentMethod: params.get("paymentMethod") || '',
  });

  const [activeStep, setActiveStep] = useState(params.get("step") ? Number(params.get("step")) : 0);

  // useEffect(() => {
  //   const fetchLocation = () => {
  //     if ('geolocation' in navigator) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //             console.log("[longitude, latitude]",[longitude, latitude])
  //           setOrder((prevOrder) => ({
  //             ...prevOrder,
  //             details: {
  //               ...prevOrder.details,
  //               shippingAddress: {
  //                 ...prevOrder.details.shippingAddress,
  //                 location: {
  //                   type: 'Point',
  //                   coordinates: [longitude, latitude],
  //                 },
  //               },
  //             },
  //           }));
  //         },
  //         (error) => {
  //           console.error('Error getting location', error);
  //         }
  //       );
  //     } else {
  //       console.error('Geolocation is not supported by this browser');
  //     }
  //   };

  //   fetchLocation();
  // }, []);


  console.log({order}, 'prderDetails');

  const handleNext = () => {
    if (activeStep < steps.length - 1) setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
    const currentStep = params.get("step") ? true : false;
    params.set("step", currentStep ? `${Number(params.get("step")) - 1}` : "0");
    // Navigate to the updated query string
    navigate({ search: params.toString() }, { replace: true });
  };

  const updateOrder = (updatedFields: Partial<Order>) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      ...updatedFields,
    }));
  };

  return (
    <OrderContext.Provider value={{ order, setOrder, updateOrder, activeStep, handleNext, handlePrevious }}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook to use the OrderContext
export const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('useOrder must be used within an OrderProvider');
  }
  return context;
};
