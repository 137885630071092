import React from "react";
import { Box, Image, Flex, Button } from "@chakra-ui/react";
import style from "./style.module.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Badge from "./components/Badge";
import { logo } from "../../../assets/images";
import SelectLanguageButton from "./components/SelectLanguageButton";
import DeliveryLocation from "../../DeliveryLocation";
import { useAppSelector } from "../../../redux/store";
import { useTranslation } from "react-i18next";

interface StyleLinkProps {
  pathname: string;
}

export default function Navbar() {
  const location = useLocation();

  const userData = useAppSelector((state) => state?.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styleLink = ({ pathname }: StyleLinkProps) =>
    location.pathname === pathname ? style.navbar_li_active : style.navbar_li;
  return (
    <Box className={style.navbar} padding={{base:'0.4% 5%' , md:'0.4% 10.9%'}} alignItems={"center"}>
      <Link to="/">
        <Image src={logo}  width={{base:'70%' , md:'100%'}}  />
      </Link>

      <Flex
      //  w={"25%"}
        justifyContent={"space-between"}>
        <Box mr={2}>
          <SelectLanguageButton />
        </Box>
        <Box 
        // w={"60%"}
        >
          {userData?.token && userData?.authInfo?._id !== "guest" && userData?.authInfo?.isPhoneVerified &&   userData?.authInfo?.isEmailVerified &&  <Badge />}
          {userData?.authInfo?._id === "guest" && (
            <Button bgColor={"primary"}   onClick={() => navigate("/auth-tabs")}>
              {t("Login")}
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
