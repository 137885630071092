import { Box, Flex, Img, Text } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { chat_empty } from "../../../assets/images/index";
import Rooms from "./Rooms";
import style from "./style.module.css";
import { RootState } from "../../../redux/store";
import { setChatMenuData } from "../../../redux/reducerSlices/ChatMenu/chatMenu";
import RipplesButton from "../../RipplesButton";
import { useTranslation } from "react-i18next";

export default function Menu({ active }: { active: boolean }) {
  const {t} = useTranslation()
  const { chatMenu } = useSelector<RootState, any | undefined>(
    (state) => state?.chatMenu.chatMenuData
  );
  const { chatMenuData } = useSelector<RootState, any | undefined>(
    (state) => state?.chatMenu
  );

  const dispatch = useDispatch();
  return (
    <>
      <Box
      
        className={`${style.Chat_Container} ${
          active ? style.Chat_Container_active : style.Chat_Container_unactive
        }`}
      >
        <Box
          className={`${style.Welcome_Content} ${
            chatMenu ? style.Welcome_Content_hide : ""
          }`}
        >
          <Box className={style.welcome_header}>
            <Text fontWeight={"bold"}>{t( "Connect easily with")}</Text>
            <Flex justifyContent={"center"} gap={"5px"} fontWeight={"bold"}>
              <Text color={"#88050D"}>{t('Support')}</Text>
              <Text color={"#0A806A"}>{t('or')}</Text>
              <Text color={"#88050D"}>{t('Seller')}</Text>
            </Flex>
          </Box>
          <Img className={style.empty_image} width={"100%"} src={chat_empty} />
          <Text>{t('Start Messaging With')}</Text>
          <Box className={style.start_buttons_box}>
            <RipplesButton
              text={t("Sona3 Support")}
              onClick={() => {
                // dispatch(
                //   setChatMenuData({
                //     chatMenuData: {
                //       chatMenu: true,
                //       list: false,
                //       chatType: "support",
                //     },
                //   })
                // );
                dispatch(
                    setChatMenuData({ chatMenuData: { 

                      active: true,
                      chatMenu: true,
                      list: false,
                      chatBox: true,
                      withAdmin: true,
                      fastBox: true,
                      chatType: "support",
                      sellerId: "",
                      roomId: "",
                    } })
                  );
              }}
              className={`${style.start_Btn} ${style.start_Btn_support}`}
            />
            <RipplesButton
              text={t("Sellers")}
              onClick={() => {
                dispatch(
                  setChatMenuData({
                    chatMenuData: {
                      chatMenu: true,
                      list: true,
                      chatType: "customers",
                      withAdmin:false,
                    },
                  })
                );
              }}
              className={`${style.start_Btn} ${style.start_Btn_customer}`}
            />
          </Box>
        </Box>

        <Box
          className={`${style.Chat_Wrapper} ${
            !chatMenu ? style.Chat_Wrapper_hide : ""
          }`}
        >
          <Rooms />
        </Box>
      </Box>
    </>
  );
}
