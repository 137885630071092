import React from "react";
import { compareIconWhite } from "../assets/images";
import { Image, Text, Toast, calc } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { addToCompareList } from "../redux/reducerSlices/CompareList/compareListSlice";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { showMSG } from "../utils/utilsFunctions";
interface CompareButtonProps {
  _id?: any;
}
const CompareButton: React.FC<CompareButtonProps> = ({ _id }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const compareList = useAppSelector((state) => state?.compareList?.productIds);
  // const compareList: any = [];
  // const compareList: any = [];
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleCompareClick = (e: any) => {
    e?.preventDefault();

    if (compareList?.length === 2 && !compareList?.includes(_id)) {
      toast({
        position: "top-right",
        description: t("Compare Only Two Products"),
        // description: <Text fontFamily={i18n.language === "ar" ? "ArabicFont" : "JosefinSansRegular"}>{t("Compare Only Two Products")}</Text>,
        status: "error",
        isClosable: true,
      });
      // showMSG(
      //   toast,
      //   "error",
      //   <Text
      //     fontFamily={
      //       i18n.language === "ar" ? "ArabicFont" : "JosefinSansRegular"
      //     }
      //   >
      //     {t("Compare Only Two Products")}
      //   </Text>
      // );
      navigate(`/compareList/${compareList[0]}/${compareList[1]}`);
    }
    if (compareList?.length === 2 && compareList?.includes(_id)) {
      toast({
        position: "top-right",
        description: t("Product already in CompareList"),
        status: "warning",
        isClosable: true,
      });
      // showMSG(
      //   toast,
      //   "warning",
      //   <Text
      //     fontFamily={
      //       i18n.language === "ar" ? "ArabicFont" : "JosefinSansRegular"
      //     }
      //   >
      //     {t("Product already in CompareList")}
      //   </Text>
      // );

      navigate(`/compareList/${compareList[0]}/${compareList[1]}`);
    }
    if (compareList?.includes(_id) && compareList?.length < 2) {
      toast({
        position: "top-right",
        description: t("Product already in CompareList"),
        status: "warning",

        isClosable: true,
      });

      // navigate("/compareList");
    }

    if (compareList?.length < 2 && !compareList?.includes(_id)) {
      dispatch(
        addToCompareList({
          productId: _id,
        })
      );

      if (compareList?.length === 1) {
        navigate(`/compareList/${compareList[0]}/${_id}`);
      }
      toast({
        position: "top-right",
        description: t("Added to compare list"),
        status: "success",

        isClosable: true,
      });
    }

    // if (compareList?.length === 0 || !compareList.includes(_id)) {
    //   dispatch(addToCompareList(_id));

    // toast({
    //   position: "top-right",
    //   description: t("Added to compare list."),
    //   status: "success",

    //   isClosable: true,
    // });
    // } else if (compareList.length === 2) {
    //   // Perform some action when the compare list already has one item
    //   // For example, show a message or navigate to the compare page
    //   console.log("You can only compare two items at a time.");
    // }
  };

  return (
    <>
      <Image
        borderRadius="50%"
        bg={"rgba(0,0,0,.5)"}
        p={1}
        cursor={"pointer"}
        src={compareIconWhite}
        onClick={handleCompareClick}
      />
    </>
  );
};

export default CompareButton;
