import { useState } from "react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  Text,
  Input,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const PriceRangeSlider = ({
  priceFrom,
  priceTo,
  setPriceFrom,
  setPriceTo,
  rangeValue,
  setRangeValue,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;


  // Adjust for RTL without altering core state
  const displayedRange =
    language === "ar"
      ? [priceTo - rangeValue[1], priceTo - rangeValue[0]]
      : rangeValue;

  const handleChange = (value) => {
    // Handle RTL and LTR values based on language
    if (language === "ar") {
      setRangeValue([priceTo - value[1], priceTo - value[0]]);
      setPriceFrom(priceTo - value[1]);
      setPriceTo(priceTo - value[0]);
    } else {
      setRangeValue(value);
      setPriceFrom(value[0]);
      setPriceTo(value[1]);
    }
  };

  const handleChangeEnd = (value) => {
    // Handle RTL and LTR values based on language
    if (language === "ar") {
      setRangeValue([priceTo - value[1], priceTo - value[0]]);
      setPriceFrom(priceTo - value[1]);
      setPriceTo(priceTo - value[0]);
    } else {
      setRangeValue(value);
      setPriceFrom(value[0]);
      setPriceTo(value[1]);
    }
  };

 
  const handleInputChange = (from, to) => {
    // setRangeValue([from, to]);
    if (language === "ar") {
      setRangeValue([from, to]);
      setPriceFrom(from);
      setPriceTo(to);
    } else {
      setRangeValue([from, to]);
      setPriceFrom(from);
      setPriceTo(to);
    }
  };

  return (
    <Box borderRadius={8} backgroundColor={"secondary"} mr={8}>
      <Text
        mb={"8px"}
        fontWeight={"bold"}
        color={"rgba(29, 35, 44, 1)"}
        paddingX={5}
        paddingY={4}
        boxShadow={"0px 6px 8px -9px grey"}
        borderTopRadius={8}
        fontSize={"1rem"}
        backgroundColor={"#fff9e6"}
      >
        {t("Filter By")} {t("Price")}
      </Text>
      <Box px={5} width={'15.2rem'} pt={6} pb={6}>
        <RangeSlider
          aria-label={["min", "max"]}
          min={0}
          max={priceTo} // Adjust if 100 isn't your max limit
          value={displayedRange}
          onChange={handleChange} // Handle change for RTL effect
          onChangeEnd={handleChangeEnd} // Log actual values on change end
          // isDisabled={true}
        >
          <RangeSliderTrack bg="#EAC07F">
            <RangeSliderFilledTrack bg="#a4020c" />
          </RangeSliderTrack>
          <RangeSliderThumb
            boxSize={3}
            index={0}
            bg="#a4020c"
            borderRadius="50%"
          />
          <RangeSliderThumb
            boxSize={3}
            index={1}
            bg="#a4020c"
            borderRadius="50%"
          />
        </RangeSlider>
        <Box mt={4} display={"flex"} gap={5}>
          <Box>
            <Text>{t("From")} :</Text>
            <Input
              bg={"white"}
              type="number"
              placeholder="price from 0"
              value={priceFrom}
              onChange={(e) =>
                handleInputChange(Number(e.target.value), priceTo)
              }
            />
          </Box>
          <Box>
            <Text>{t("To")} :</Text>
            <Input
              bg={"white"}
              type="number"
              placeholder="price to ..."
              value={priceTo}
              onChange={(e) =>
                handleInputChange(priceFrom, Number(e.target.value))
              }
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Text>
            {t("Price")}: {priceFrom} {t("AED")} - {priceTo} {t("AED")}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PriceRangeSlider;
