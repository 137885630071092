import React, { useState } from "react";
import { Box, Text, Image, useDisclosure, Flex } from "@chakra-ui/react";
// import ShowSliderButton from './ShowSliderButton';
import SavedButton from "./SaveButton";
import { useTranslation } from "react-i18next";
import { lang } from "../utils/utilsFunctions";
import { useAppSelector } from "../redux/store";
import { emptyImage } from "../assets/images";

// import { Product } from '../types'; // Define your Product type if not already defined

// interface VariationCardProps {
//   product: {

//   };
// }

const VariationCard: React.FC<any> = ({
  variationId,
  price,
  description,
  productImageSrc,
  productName,
  activeVariation,
}) => {
  //const { productName, description, price, productImageSrc, variationId } = product;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);

  return (
    <>
      <Box
        borderWidth="0.2px"
        borderColor="#4D473E"
        // backgroundColor={"#F9EFDC"}
        py={2}
        px={2}
        borderRadius={10}
        mr={7}
        // width={"29.2em"}
        height={"10em"}
        cursor={"pointer"}
        _hover={{ border: "3px solid #a4020c", boxShadow: "5px 0px 5px #ccc" }}
        style={
          activeVariation === variationId
            ? { border: "3px solid #a4020c", boxShadow: "5px 0px 5px #ccc" }
            : {}
        }
      >
        <Box display="flex" justifyContent="flex-end">
          {/* <ShowSliderButton onClick={onOpen} /> */}
          {customerId!=="guest"&&<SavedButton variationId={variationId} />}
        </Box>
        <Flex h={"100%"} flexDirection="row">
          <Image
            width={"30%"}
            height={"100%"}
            objectFit='contain'
            borderRadius={2}
            src={productImageSrc || emptyImage}
            alt={description}
          />
          <Flex
            display="flex"
            flexDirection="column"
            // width="55%"
            // h="6rem"
            px={4}
            justifyContent={"space-around"}
          >
            <Text fontWeight="bold" noOfLines={1}>
              {description}
            </Text>
            <Text color="primary" fontWeight="bold">
              {price} {t("AED")}
            </Text>
            {/* <Text color="SubheadColor" noOfLines={2}>{description}</Text> */}
          </Flex>
        </Flex>
        <Box display="flex" justifyContent="flex-end" mt={3}></Box>
      </Box>
      {/* ShowImgSliderModal component */}
    </>
  );
};

export default VariationCard;
