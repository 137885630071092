import React from "react";
import {
  Box,
  Flex,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import style from "./Navbar/style.module.css";
import { useTranslation } from "react-i18next";

const CategoriesNav: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const isActive = (pathname: string, search?: string): boolean => {
    return location.pathname === pathname && location.search === search;
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-around"
      wrap="wrap"
      padding=".2rem"
      bg="primary"
      color="white"
      fontSize={17}
      fontWeight={"500"}
    >
      <Box
        display={{ base: "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex direction="row" flexGrow={1}>
          <UnorderedList display="flex" listStyleType="none">
            <ListItem 
              className={isActive("/", "") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "/", search: "" }}>
                <Text>{t('Home')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-products", "") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "all-products", search: "" }}>
                <Text>{t('Products')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-categories", "") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "all-categories", hash: "all-categories" }}>
                <Text>{t('Categories')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-services", "") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={"all-services"}>
                <Text>{t('Services')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-stores", "") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={"all-stores"}>
                <Text>{t('Shops')}</Text>
              </Link>
            </ListItem>
            <ListItem
              className={isActive("/all-products", "?discountValue=-1") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "/all-products", search: "?discountValue=-1" }}>
                <Text>{t('Top Deals')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-variations", "?rank=-1") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "/all-variations", search: "?rank=-1" }}>
                <Text>{t('Trending Now')}</Text>
              </Link>
            </ListItem>
            <ListItem 
              className={isActive("/all-products", "?sortOrder=-1&sortByDate=creationDate") ? style.navbar_li_active : style.navbar_li}
              mr={10}
            >
              <Link to={{ pathname: "/all-products", search: "?sortOrder=-1&sortByDate=creationDate" }}>
                <Text>{t('Recently Added')}</Text>
              </Link>
            </ListItem>
          </UnorderedList>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CategoriesNav;
