import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  DeleteService,
  GetService,
  PostService,
} from "../../../api/services/requests-service";
import ApiRoutes from "../../../api/services/api-routes";
// import { WishListItem } from '../../types'; // Import your WishListItem type if defined

type WishListItem = any;
// Async action to fetch the wishlist
export const getWishlistAsync = createAsyncThunk(
  "wishlist/getWishlistAsync",
  async (_, { getState }) => {
    const state = getState() as RootState; // Access the RootState
    const customerId = state?.auth?.authData?._id;
    const response = await GetService({
      route: ApiRoutes?.getWishlist,
      params: { customer: customerId },
    });
    return response.data?.result?.items; // Adjust this based on your API response structure
  }
);

// Async action to add an item to the wishlist
export const addToWishlistAsync = createAsyncThunk(
  "wishlist/addToWishlistAsync",
  async (variationId: any, { getState }) => {
    const state = getState() as RootState; // Access the RootState
    const customerId = state?.auth?.authData?._id;
    const response = await PostService({
      route: ApiRoutes?.addToWishlist,
      params: { item: variationId, customer: customerId },
    });
    return response.data?.result?.items;  // Adjust this based on your API response structure
  }
);

// Async action to remove an item from the wishlist
export const removeFromWishlistAsync = createAsyncThunk(
  "wishlist/removeFromWishlistAsync",
  async (variationId: any, { getState }) => {
    const state = getState() as RootState; // Access the RootState
    const customerId = state?.auth?.authData?._id;
    const response = await DeleteService({
      route: "wishlists/item",
      params: { item: variationId, customer: customerId },
    });
   
    return response.data?.result?.items; // Adjust this based on your API response structure
  }
);

// Define the initial state
interface WishlistState extends Array<string> {} // Adjust this based on your data structure
const initialState: WishlistState = [];

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    // Add your custom reducers here if needed
  },
  extraReducers: (builder) => {
    // Add extraReducers for async actions
    builder
      .addCase(
        getWishlistAsync.fulfilled,
        (state, action: PayloadAction<WishListItem[]>) => {
          return action.payload?.map((item) => item._id) || []; // Assuming _id is the unique identifier of an item
        }
      )
      .addCase(
        addToWishlistAsync.fulfilled,
        (state, action: PayloadAction<WishListItem[]>) => {
          return action.payload;
          // ?.map((item) => item._id) || [];
        }
      )
      .addCase(
        removeFromWishlistAsync.fulfilled,
        (state, action: PayloadAction<WishListItem[]>) => {
          return action.payload || [];
        }
      );
  },
});

export default wishlistSlice.reducer;
