import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, FormLabel, Select, Button, Box, Spinner } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiRoutes from '../../../api/services/api-routes';
import usePostData from '../../../api/usePostData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { t } from 'i18next';

const options = ['cashOnDelivery', 'visa'];
interface FormValues {
  paymentMethod?: any;
}
const CheckoutService = () => {
  const { authInfo } = useSelector<RootState, any | undefined>(state => state?.auth);
  const { postData, loading } = usePostData()
  const [requestTotal, setRequestTotal] = useState(0)
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>, handleChange: any) => {
    const value = event.target.value;
    params.set('paymentMethod', value);
    if(value === ""){ params.delete("paymentMethod") }
    navigate({ search: params.toString() }, { replace: true });
    handleChange(event);
  };

  const CheckOut = async(values:any)=>{
     const res = await postData({route:ApiRoutes?.servicePurchase, params:{_id:params.get("_id")}, data:{customer:authInfo._id, paymentMethod:values.paymentMethod}})
     if(!res?.error){
        console.log("purchaseResponse",res);
        if (values.paymentMethod === "visa") {
            window.open(res?.result);
        } else {
        navigate('/profile/orders');
        }
     }
  }

  useEffect(() => {
    const getRequestTotal = async()=>{
        const res = await postData({route:ApiRoutes?.calculateRequstTotal, params:{customer:authInfo._id ,_id:params.get("_id")}})
        if(!res?.error){
           console.log("requestTotalResponse",res);
           setRequestTotal(res?.result?.total)
        }
    }
    getRequestTotal()
  }, [])
  

  const initialValues: FormValues = { paymentMethod:params.get("paymentMethod") ? params.get("paymentMethod") : ""};
  return (
    <Box width={"50%"} margin={"auto"} textAlign={"center"} justifyContent={"center"}>
    <Formik
      initialValues={initialValues}
      onSubmit={CheckOut}
    >
      {({ values, handleChange, handleBlur, isSubmitting }) => (
        <Form>
          <Box p={4} w="100%" mx="auto">
            <FormControl id="paymentMethod" mb={4}>
              <FormLabel style={{textAlign:"center", fontSize:"1.2rem"}}>{t('Payment Method')}</FormLabel>
              <Field
                name="paymentMethod"
                as={Select}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => 
                    handlePaymentMethodChange(event, handleChange)
                }
                onBlur={handleBlur}
                value={values.paymentMethod}
                style={{textAlign:"center"}}
                required
              >
                <option value="" label={t("Select payment method")} />
                {options.map((option) => (
                  <option key={option} value={option} style={{textAlign:"center", padding:"10px"}}>
                    {option}
                  </option>
                ))}
              </Field>
            </FormControl>
            {/* <Box margin={"20px auto"}>
                <h1 style={{fontSize:"1rem"}}>Service Fees</h1>
                {loading ? <Spinner/> : <div style={{fontSize:"1.5rem"}}>{requestTotal}</div>}
            </Box> */}
            <Button disabled={loading || isSubmitting} type="submit" width={"100%"}>{t("Check Out")}</Button>
          </Box>
        </Form>
      )}
    </Formik>
    </Box>
  );
};

export default CheckoutService;
