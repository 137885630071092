import { Flex, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
type Props = {
  count: number;
  data: any[];
  loadMoreData: () => void;
  loading: boolean;
  title?: string;
  style?: {};
};
export default function ShowMore({
  data,
  count,
  loadMoreData,
  loading,
  title,
  style,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      {data && data.length !== count && (
        <Flex>
          <Button
            variant={"outline"}
            color={"black"}
            fontWeight={"500"}
            w={"30%"}
            px={10}
            _hover={{
              color: "gray",
              bgColor: "#EAC07F",
            }}
            isLoading={loading}
            onClick={() => {
              loadMoreData();
            }}
            style={style}
          >
            {t(title || "Load More")}
          </Button>
        </Flex>
      )}
    </>
  );
}
