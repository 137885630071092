import { Box, Flex, Img, Text } from "@chakra-ui/react";
import React from "react";
import { backImg, emptyBag } from "../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EmptyData({link}:{link?:string}) {
  const { t } = useTranslation();

  return (
    <Flex justify="center">
      <Flex direction="column" justify="center" my="5rem" mx={"10em"}>
        <Box>
          <Img src={emptyBag} alt="empty bag" />
        </Box>
        <Flex align="center" gap="3rem" justify="center" mt="3rem">
          <Text fontWeight="bold" color="primary">
            {t("Continue Shopping")}{" "}
          </Text>
          <Link to={link||"/all-products"}>
            <Img src={backImg} alt="back" w="70px" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
