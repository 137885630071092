import {
  AbsoluteCenter,
  Box,
  Divider,
  Stack,
  Image,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { hrImg, arrowRight } from "../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLang from "../hooks/useLang";

interface SectionTitleProps {
  title?: string;
  route?: string;
  sectionSearch?: string | undefined;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  route,
  sectionSearch,
}) => {
  const { t } = useTranslation();
  const { isAr } = useLang();

  return (
    <Box paddingBottom={8}>
      <Flex m={2} justifyContent={"space-between"} alignItems={"flex-end"}>
        <Text fontWeight={"700"} color={"primary"} fontSize={{base:'2xl'  ,md:"3xl"}} >
          {t(title || '')}
        </Text>
        <Box>
          <Link to={`${route}${sectionSearch ? "?" + sectionSearch : ""}`}>
            <Flex
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
              }}
              cursor={"pointer"}
              alignItems={"center"}
            >
              <Button
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-around"}
                size={{base:'sm' ,md:'lg'}}
              >
                <Text mr={1}>{t('View All')}</Text>
                <Image 
                  src={arrowRight} 
                  width={5} 
                  transform={isAr ?"rotate(180deg)" :''} // Rotate the image by 90 degrees
                />
              </Button>
            </Flex>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default SectionTitle;
