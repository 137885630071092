import MyDetails from "./MyDetails";
import Payment from "./Payment";
import CheckoutStep from "./CheckoutStep";
// types.ts
export interface Location {
    type: string;
    coordinates: [number, number];
  }
  
  export interface Address {
    country: string;
    city: any;
    street: string;
    cityCode?: string;
    remarks: string;
  }
  
  export interface ShippingAddress {
    location: Location;
    address: Address;
  }
  
  export interface Details {
    customer: string;
    shippingAddress: ShippingAddress;
  }
  
  export interface Cost {
    cartTotal: number;
    taxesTotal: number;
    shippingFeesTotal: number;
  }
  
  export interface Order {
    details: Details;
    cost: Cost;
    paymentMethod:string;
  }
 
  
  export interface OrderContextProps {
    order: Order;
    setOrder: (order: Order) => void;
    updateOrder: (updatedFields: Partial<Order>) => void;
    activeStep:number;
    handlePrevious:() => void;
    handleNext:() => void;
  }
  
 interface StepInfo {
    title: string;
    component: React.ReactNode;
}
  
export const steps: StepInfo[] = [
    { title: "Address", component: <MyDetails /> },
    { title: "Payment", component: <Payment /> },
    { title: "Checkout", component: <CheckoutStep /> },
  ];