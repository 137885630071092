import { Box } from "@chakra-ui/react";
import * as React from "react";
import { useSelector } from "react-redux";
import {useLocation} from "react-router-dom";

function RouterWrapper({children}) {
  const location = useLocation();

  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location]);

  return children;
}

export default RouterWrapper;
