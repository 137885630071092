import { useState } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { ChevronDownIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

type DropdownListItem = {
  label: string;
  value: any;
};

type OnSelectType = (value: any) => void;

type ValueItemProps = {
  isChecked?: boolean;
  onSelect: () => void;
  item: any;
  isLast?: boolean;
  isActive?: boolean;
};

const ValueItem = ({
  isChecked,
  onSelect,
  item,
  isLast,
  isActive,
}: ValueItemProps) => {
  const { t } = useTranslation();
  return (
    <Box
      onClick={onSelect}
      borderBottomWidth={isLast ? "0px" : "0.3px"}
      borderColor="gray.300"
      // marginX="6px"
      marginBottom={isLast ? "0px" : "3px"}
      paddingY="13px"
      cursor="pointer"
      _hover={{ backgroundColor: "gray.100" }}
      backgroundColor={isActive ? "gray.100" : "transparent"}
      style={{
        width: "100%",
        padding: "10px",
        justifyContent: "space-between",
      }}
    >
      <Flex justifyContent="space-between">
        <Text>{t(item.label)}</Text>
        {isChecked && <CheckIcon color="primary" />}
      </Flex>
    </Box>
  );
};

const DropdownList = ({
  item,
  onChange,
  value,
  style,
}: {
  value?: any;
  item: DropdownListItem[];
  onChange: OnSelectType;
  style?: any;
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            rightIcon={<ChevronDownIcon />}
            variant={"outline"}
            borderRadius={20}
            px={10}
            w={220}
            height={45}
            onClick={() => setModalVisible(true)}
          >
            <Text>{t(value?.label)}</Text>
          </MenuButton>
          <MenuList w={"200px"} zIndex={3} paddingTop={"0"} paddingBottom={"0"}>
            {item.map((item: any, index) => {
              const isChecked = item?.label === value?.label;
              const isActive = isChecked;
              const isLast = index === 3;
              return (
                <MenuItem
                  key={index}
                  onSelect={() => {
                    onChange(item);
                    setModalVisible(false);
                  }}
                  style={{padding:0}}
                >
                  <ValueItem
                    isChecked={isChecked}
                    onSelect={() => {
                      onChange(item);
                      setModalVisible(false);
                    }}
                    item={item}
                    isLast={isLast}
                    isActive={isActive}
                  />
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
    // <Box textAlign={'center'} >
    //   <Button
    //     variant={"outline"}
    //     borderRadius={20}
    //     px={10}
    //     w={200}
    //     height={45}
    //     onClick={() => setModalVisible(true)}
    //   >
    //     {t(value?.label)}
    //     <ChevronDownIcon />
    //   </Button>

    //   <Modal isOpen={isModalVisible} onClose={() => setModalVisible(false)}>
    //     <ModalOverlay />
    //     <ModalContent>
    //       <ModalHeader>
    //         <Flex justifyContent="flex-end">
    //           <Button onClick={() => setModalVisible(false)} variant="ghost">
    //             <CloseIcon />
    //           </Button>
    //         </Flex>
    //       </ModalHeader>
    //       {/* <ModalCloseButton /> */}
    //       <ModalBody>
    //         {item.map((item: any, index) => {

    //           const isChecked = item?.label === value?.label;
    //           const isActive = isChecked;
    //           const isLast = index === 3;
    //           return (
    //             <ValueItem
    //               key={index}
    //               isChecked={isChecked}
    //               onSelect={() => {
    //                 onChange(item);
    //                 setModalVisible(false);
    //               }}
    //               item={item}
    //               isLast={isLast}
    //               isActive={isActive}
    //             />
    //           );
    //         })}
    //       </ModalBody>
    //     </ModalContent>
    //   </Modal>
    // </Box>
  );
};

export default DropdownList;
