// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import useGetData from "../api/useGetData";
// import ApiRoutes from "../api/services/api-routes";
// import { Box, Img } from "@chakra-ui/react";
// import { items } from "../assets/dummyData";
// import AppSkeleton from "../common/AppSkeleton";

// interface BannerProps {
//   route: string;
//   height?: number | string;
// }

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// const Banners: React.FC<BannerProps> = ({ route, height }) => {
//   const { data, loading } = useGetData({
//     route: route,
//     params: { page: "", limit: "" },
//   });

//   const banners = data?.result;
//   console.log(banners, route, "banners");
//   if (loading) {
//     return (
//       <Box zIndex={0} width="100%">
//         <AppSkeleton height={height || "40vh"} width={"100%"} />;
//       </Box>
//     );
//   }
//   return (
//     <>
//       <Box zIndex={0} width="100%">
//         {banners?.length > 0 && (
//           <>
//             {route === ApiRoutes?.listSonnaBanners && (
//               <Carousel responsive={responsive}>
//                 {banners?.map((banner: any) => {
//                   return (
//                     <Box key={banner?._id}>
//                       <Img
//                         bgColor={"#F9F0E3"}
//                         height={height || "40vh"}
//                         width={"100%"}
//                         src={banner?.image?.Location}
//                       />
//                     </Box>
//                   );
//                 })}
//               </Carousel>
//             )}

//             {route === ApiRoutes?.listShopsBanners &&
//               banners?.forEach((shop: any) => {
//                 console.log(`Shop: ${shop?.banners?.[0]?.Location}`);
//                 // if (shop?.banners?.length > 0) {
//                   shop?.banners?.forEach((banner: any, index: number) => {
//                     return (
//                       <Carousel responsive={responsive}>
//                         <Box key={banner?.key}>
//                           <Img
//                             bgColor={"#F9F0E3"}
//                             height={height || "40vh"}
//                             width={"100%"}
//                             src={banner?.Location}
//                           />
//                         </Box>
//                       </Carousel>
//                     );
//                   });
//                 // } else {
//                 //   console.log(`  No banners available.`);
//                 // }
//               })}
//           </>
//         )}
//       </Box>
//     </>
//   );
// };

// export default Banners;

import React, { useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useGetData from "../api/useGetData";
import ApiRoutes from "../api/services/api-routes";
import { Box, Img } from "@chakra-ui/react";
import AppSkeleton from "../common/AppSkeleton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BannerProps {
  route: string;
  height?: number | string;
  width?: number | string;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Banners: React.FC<BannerProps> = ({ route, height, width }) => {
  const { data, loading } = useGetData({
    route: route,
    params: { page: "", limit: "" },
  });

  // const updatedbanners = useMemo(() => {
  //   return data?.result?.filter((shop: any) => {
  //     const banner = shop?.defaultBanner || shop?.banners?.[0];
  //     if (banner) return banner;
  //   });
  // }, [data?.result]);

  const updatedbanners = route==="banners/list" ? data?.result : data?.result?.filter((shop: any) => {
      const banner = shop?.defaultBanner || shop?.banners?.[0];
      if (banner) return banner;
    });


  const banners = updatedbanners;

  // const banners = data?.result;

  const { i18n } = useTranslation();

  if (loading) {
    return (
      <Box zIndex={0} width="100%">
        <AppSkeleton height={height || "40vh"} width={"100%"} />
      </Box>
    );
  }

  // if(route === ApiRoutes?.listSonnaBanners) {
  //   return (
  //     <Carousel
  //     rtl={i18n.language === "en" ? false : true}
  //     responsive={responsive}
  //   >
  //     {banners?.map((banner: any) => (
  //       <Box key={banner?._id}>
  //         <Img
  //           bgColor={"#F9F0E3"}
  //           height={height || "40vh"}
  //           width={"100%"}
  //           src={banner?.image?.Location}
  //           objectFit={"cover"}
  //           objectPosition={"center"}
  //         />
  //       </Box>
  //     ))}
  //   </Carousel>
  //   )
  // }

  // if(route === ApiRoutes?.listFeaturedShops) {
  //   return (
  //     <Carousel
  //     rtl={i18n.language === "en" ? false : true}
  //     responsive={responsive}
  //   >
  //     {banners?.map((banner: any) => (
  //       <Box key={banner?._id}>
  //         <Img
  //           bgColor={"#F9F0E3"}
  //           height={height || "40vh"}
  //           width={"100%"}
  //           src={banner?.image?.Location}
  //           objectFit={"cover"}
  //           objectPosition={"center"}
  //         />
  //       </Box>
  //     ))}
  //   </Carousel>
  //   )
  // }

  // if(route === ApiRoutes?.listShopsBanners) {
  //   return (
  //     <Carousel
  //     rtl={i18n.language === "en" ? false : true}
  //     responsive={responsive}
  //   >
  //     {banners?.map((banner: any) => (
  //       <Box key={banner?._id}>
  //         <Img
  //           bgColor={"#F9F0E3"}
  //           height={height || "40vh"}
  //           width={"100%"}
  //           src={banner?.image?.Location}
  //           objectFit={"cover"}
  //           objectPosition={"center"}
  //         />
  //       </Box>
  //     ))}
  //   </Carousel>
  //   )
  // }

  return (
    <Box zIndex={0} width="100%">
      {!loading && banners?.length > 0 && (
        <>
          {route === ApiRoutes?.listSonnaBanners && (
            <Carousel
              rtl={i18n.language === "en" ? false : true}
              responsive={responsive}
            >
              {banners?.map((banner: any) => (
                <Box key={banner?._id}>
                  <Img
                    bgColor={"#F9F0E3"}
                    maxHeight={height || "40vh"}
                    width={"100%"}
                    src={banner?.image?.Location}
                    objectFit={"cover"}
                    objectPosition={"center"}
                  />
                </Box>
              ))}
            </Carousel>
          )}

          {route === ApiRoutes?.listFeaturedShops && (
            <Carousel
              rtl={i18n.language === "en" ? false : true}
              responsive={responsive}
            >
              {banners?.map((shop: any) => {
                const banner = shop?.defaultBanner || shop?.banners?.[0];

                if (!banner) return null;
                return (
                  <Link to={`/store-details/${shop?._id}`}>
                    <Box key={banner?.key}>
                      <Img
                        bgColor={"#F9F0E3"}
                        height={height || "40vh"}
                        width={"100%"}
                        src={banner?.Location}
                        objectFit={"cover"}
                        objectPosition={"center"}
                      />
                    </Box>
                  </Link>
                );
              })}
            </Carousel>
          )}

          {route === ApiRoutes?.listShopsBanners &&
            banners?.forEach((shop: any) => {
              console.log(`Shop: ${shop?.banners?.[0]?.Location}`);
              // if (shop?.banners?.length > 0) {
              shop?.banners?.forEach((banner: any, index: number) => {
                return (
                  <Carousel responsive={responsive}>
                    <Box key={banner?.key}>
                      <Img
                        bgColor={"#F9F0E3"}
                        height={height || "40vh"}
                        width={"100%"}
                        src={banner?.Location}
                      />
                    </Box>
                  </Carousel>
                );
              });
              // } else {
              //   console.log(`  No banners available.`);
              // }
            })}
        </>
      )}
    </Box>
  );
};

export default Banners;
