import React, { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RootState, useAppSelector } from '../redux/store';
import { Box, Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import useLoginAsGuest from '../hooks/useLoginAsGuest';


const PrivateRoute: React.FC = () => {
  // const check: boolean =  localStorage.getItem('token') ? true : false;
  // const token = useAppSelector((state)=>state?.auth?.token);
  const user = useAppSelector((state) => state.auth?.authInfo);
  const paymentsRouts = ['/payment_succeed' ,'/payment_failed','/mobile_payment_succeed' , '/mobile_payment_failed' , ]
  const location = useLocation()
  const pathname = location?.pathname
  const { loginAsGuest, loading } = useLoginAsGuest();

  const handleAuth = async () => {
    if (!user && !(paymentsRouts.includes(pathname))) {
    }
    if(user){
      if(user?.isPhoneVerified === false || user?.isEmailVerified === false ){
        loginAsGuest()
        
      }

    }
  };


  useEffect(() => {
    handleAuth();
  }, [user ,pathname]);
  // return token ? <Outlet /> : <Navigate to="/auth-tabs" />;
  return   <Outlet />  ;

};

export default PrivateRoute;
