import { Box, Flex, Heading, Img } from "@chakra-ui/react";
import React from "react";

import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/use-is-mobile";
import { profileImg } from "../../assets/images";
import EditProfileForm from "../../components/EditProfile/EditProfileForm";

export default function EditProfile() {
  const { t } = useTranslation();
  const [mobile] = useIsMobile();

  return (
    <>
      <Box>
        <Box w="90%" mx="auto" my="2rem" bg="secondary">
          <Box w={mobile ? "90%" : "70%"} mx="auto" py="1rem">
            <Heading size="md" color="primary" textAlign="center">
              {t("Edit Your Profile")}
            </Heading>
            <Flex justify="center" textAlign="center" my="1rem">
              <Img src={profileImg} alt="profile image" />
            </Flex>
            <EditProfileForm />
          </Box>
        </Box>
      </Box>
    </>
  );
}
