import  React from 'react';
import { useTranslation } from 'react-i18next';

const useLang = () => {
    const { i18n , t} = useTranslation()

    return ({
        lang:i18n.language  ===  'ar' ? "Ar" : "En",
        isAr:i18n.language  ===  'ar',
        t,
        // font:i18n?.language === 'en'?'JosefinSansRegular': 'cairo-sans-serif',
        dir:i18n.language  ===  'ar' ? "rtl" : "ltr",
   
    });
}

export default useLang;