// SocketProvider.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { initializeSocket } from './socket';
type SocketType = ReturnType<typeof initializeSocket>;
const SocketContext = createContext<SocketType | null>(null);
// Custom hook to access the socket instance
export const useSocket = () => useContext(SocketContext);
// Props for SocketProvider component
interface SocketProviderProps {
    userId: string;
    userRole: string;
    userToken: string;
    children: ReactNode;
}
// SocketProvider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ userId, userRole, userToken, children }) => {
    // Initialize socket
    const socket = initializeSocket({ userId, userRole, userToken });
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
