import React, { useState } from "react";
import { lang } from "../utils/utilsFunctions";
import { Box, Button, Text ,Flex } from "@chakra-ui/react";
import useLang from "../hooks/useLang";

const VariationFields = ({
  variations = [],
  selectedValue,
  onChange,
}: {
  variations: any[];
  selectedValue: any;
  onChange: (value: any) => void;
}) => {
  // const [selectedValue, setSelectedValue] = useState(variations[2])
  const {lang} = useLang()
  const selectedFields = selectedValue?.fields;

  const valuesData = variations?.map((item: any) => {
    const variationFields = item?.fields;
    const values = variationFields?.map((field: any) => field?.value);
    return values;
  });

  const variationFields = ({ variations }: { variations: any[] }) => {
    let updatedFields: any = {};

    variations?.map((item: any) => {
      //1
      const variationFields = item?.fields;
      variationFields?.map((item: any) => {
        //2
        const key = item?.field?.[`name${lang}`];

        if (updatedFields?.[key]) {
          const isValueExisted = updatedFields[key].some((value: any) => {
            //3
            if (value?.number) {
              return item?.value?.number === value?.number;
            }
            if (value?.en) {
              return (
                value?.en === item?.value?.en && value?.ar === item?.value?.ar
              );
            }
            
            return false;
          });
          if (!isValueExisted) {
            updatedFields[key] = [item?.value, ...updatedFields[key]];
          }
        } else {
          updatedFields[key] = [item?.value];
        }
      });
    });
    return updatedFields;
  };

  const updatedFields: any = variationFields({ variations });
  const fieldKeysArray = Object?.keys(updatedFields);
  // const mainTab = updatedFields?.[fieldKeysArray?.[0]]

  const onSelectValue = ({
    index,
    value: newValue,
    key,
  }: {
    index: number;
    value: string | number;
    key: string;
  }) => {
   
    const valueIndex = index;
    const selectedItemFields = selectedValue?.fields;
    const variationNewValueIndex = valuesData.findIndex((value) => {
      const isSelectedVariation = value?.every((item: any, index: number) => {
    
        if (valueIndex === index) {
          const isSelected = value?.[valueIndex]?.en
            ? value?.[valueIndex]?.en === newValue
            : value?.[valueIndex]?.number === newValue;
          return isSelected;
        } else {
          const isSelected = value?.[index]?.en
            ? selectedItemFields?.[index]?.value?.en === item.en
            : selectedItemFields?.[index]?.value?.number === item?.number;
          return isSelected;
        }
      });
      // const isSelected = value?.[index]?.en ? value?.[index]?.en === newValue : value?.[index]?.number === newValue

      return isSelectedVariation;
    });

    if (variationNewValueIndex !== -1) {
      onChange(variations[variationNewValueIndex]);
    } else {
     
      const variationNewValueIndex = valuesData.findIndex((value) => {
        const isSelected = value?.[valueIndex]?.en
          ? value?.[valueIndex]?.en === newValue
          : value?.[valueIndex]?.number === newValue;
        return isSelected;
      });

      onChange(variations[variationNewValueIndex]);
    }

  };



  return (
    <Flex style={{ padding: "2px" }} wrap={"wrap"}>
      {fieldKeysArray?.map((key: string, index: number) => {
      
        return (
       <>
          <Box width={"20em"}>
            <Text>{key}</Text>
            <Box>
              {updatedFields?.[key]?.map((item: any) => {
                const selectedField = selectedFields?.[index]?.value;


                const isSelected = selectedField?.en
                  ? selectedField?.en === item?.en
                  : selectedField?.number === item?.number;

                const isDisabled = valuesData?.some((variationVal) => {
                  const selectedVariationVal =
                    selectedValue?.fields?.[0]?.value?.en;
                  const is = variationVal?.[index]?.number
                    ? variationVal?.[0]?.en === selectedVariationVal &&
                      variationVal?.[index]?.number === item?.number
                    : variationVal?.[0]?.en === selectedVariationVal &&
                      variationVal?.[index]?.en === item?.en;
                  return is;
                });

                return (
                  <Button
                    onClick={() => {
                      !isDisabled &&
                        onSelectValue({
                          index,
                          value: item?.en || item?.number,
                          key: key,
                        });
                    }}
                    backgroundColor={"transparent"}
                    color={"primary"}
                    borderColor={"primary"}
                    borderWidth={"1px"}
                    m={2}
                    p={1}
                  >
                    <Box>
                      <Text
                        style={{
                          padding: 10,
                          //color: isSelected ? colors?.PrimaryColor : "#000"
                        }}
                      >
                        {item?.[lang?.toLocaleLowerCase()] || item?.number}
                      </Text>
                    </Box>
                  </Button>
                );

                return <Text>{item?.en || item?.number?.toString()}</Text>;
              })}
            </Box>
          
          </Box>
       </>
        );
      })}
    </Flex>
  );
};

export default VariationFields;
